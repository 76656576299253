import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Role } from 'src/app/models/role';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { MustMatchValidator } from 'src/app/helpers/must-match.validator';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  dataSource = new MatTableDataSource<Role>();
  form: UntypedFormGroup;
  displayedColumns: string[] = ['select', 'description'];
  panelOpenState = false;

  constructor(private dialogRef: MatDialogRef<AddUserComponent>) { }

  ngOnInit() {

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      type: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: MustMatchValidator("password", "confirmPassword") });
  }

  applyFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  get UserTypes() {
    return Object.keys(UserTypes).map(key => UserTypes[key]);
  }
}

export enum UserTypes {
  Employee = 'F',
  Salesman = 'R'
}