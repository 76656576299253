<div class="container-content">
    <h1 class="mat-headline">
      <span>
        Regras
        <mat-chip>{{getTotalEntries()}}</mat-chip>
      </span>
    </h1>
    <div class="mat-elevation-z8">
      <mat-card>
        <mat-card-title>Registros
          <button mat-flat-button color="primary" (click)="showAddDialog()">Adicionar
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-card-title>
        <mat-card-content>
          <h6 class="mat-small">Abaixo estão listadas as regras.</h6>

          <div fxLayout="row" fxLayoutAlign="space-between center" >
            <mat-form-field style="width: 300px;">
              <mat-label>Pesquisa</mat-label>
              <input matInput placeholder="Pesquise na tabela" (keyup)="applyFilter($event.target.value)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
            </mat-paginator>
          </div> 
  
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
  
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row" align="right">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="showEditDialog(row)">
                    <mat-icon>create</mat-icon>
                    <span>Editar</span>
                  </button>
                  <button mat-menu-item (click)="showDeleteDialog(row)">
                    <mat-icon>delete</mat-icon>
                    <span>Excluir</span>
                  </button>                  
                </mat-menu>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <app-empty-results *ngIf="dataSource.data?.length <= 0 || dataSource.filteredData?.length <= 0"></app-empty-results>
  </div>