<body>
    <img src="/assets/img/brand-logo.png" width="280">
    <label class="description" *ngIf="type == 0">
        Seu currículo foi enviado com sucesso!<br>
        Em breve você receberá um e-mail confirmando o cadastro.
    </label>
    <label class="description" *ngIf="type == 1">
        Seu currículo foi atualizado com sucesso!<br>
        Caso queira atualizá-lo novamente, faça o mesmo procedimento.
    </label>
    <label class="description" *ngIf="type == 2">
        Sua solicitação foi registrada!<br>
        Em breve você receberá um e-mail com um link para atualização do seu currículo.
    </label>
</body>