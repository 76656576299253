import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import Translations from 'src/assets/json/EnumTranslations.json';
import { Observable } from 'rxjs';
import { DashboardItem } from 'src/app/view-models/dashboard-item';
import { HumanResourcesService } from 'src/app/services/human-resources.service';
import { EChartsOption } from 'echarts';
import { JobApplicationsPerEducationLevel } from 'src/app/models/job-applications-per-education-level';
import { JobApplicationsPerLanguageLevel } from 'src/app/models/job-applications-per-language-level';
import { Extensions } from 'src/app/helpers/extensions';
import { JobApplicationsPerInterestArea } from 'src/app/models/job-applications-per-interest-area';
import { JobApplicationsPerGender } from 'src/app/models/job-applications-per-gender';

@Component({
    selector: 'app-dashboard-hr',
    templateUrl: './dashboard-hr.component.html',
    styleUrls: ['./dashboard-hr.component.scss']
})

export class DashboardHRComponent {
    /** Based on the screen size, switch from standard to one column per row */
    cards: Observable<DashboardItem[]>;
    department = Translations.Departments.HumanResources.Description;
    departmentIcon = 'groups';
    HRData: HRData = new HRData();
    jobApplicationsPerEducationLevel: EChartsOption;
    jobApplicationsPerLanguageLevel: EChartsOption;
    jobApplicationsPerInterestArea: EChartsOption;
    jobApplicationsPerGender: EChartsOption;
    educationLevels: any[] = [
        { description: 'Ensino Fundamental Incompleto', code: 'EFI' },
        { description: 'Ensino Fundamental Completo', code: 'EFC' },
        { description: 'Ensino Médio Incompleto', code: 'EMI' },
        { description: 'Ensino Médio Completo', code: 'EMC' },
        { description: 'Graduação Incompleta', code: 'GI' },
        { description: 'Graduação Completa', code: 'GC' },
        { description: 'Pós-Graduação (MBA) Incompleta', code: 'PGI' },
        { description: 'Pós-Graduação (MBA) Completa', code: 'PGC' },
        { description: 'Mestrado Incompleto', code: 'MI' },
        { description: 'Mestrado Completo', code: 'MC' },
        { description: 'Doutorado Incompleto', code: 'DI' },
        { description: 'Doutorado Completo', code: 'DC' },
    ];
    languageLevels: any[] = [
        { description: 'Iniciante', code: 'C' },
        { description: 'Intermediário', code: 'B' },
        { description: 'Fluente', code: 'A' },
    ];    
    genders: any[] = [
        { description: 'Feminino', code: 'F' },
        { description: 'Masculino', code: 'M' },
        { description: 'Não respondeu', code: '-' }
    ];

    constructor(private breakpointObserver: BreakpointObserver, private settingsService: SettingsService, private humanResourcesService: HumanResourcesService,
        private notificationService: NotificationService, private router: Router) { }

    ngOnInit() {
        this.humanResourcesService.getDashboardData().subscribe({
            next: data => {
                this.HRData.jobApplicationCount = data.jobApplicationCount;
                this.HRData.jobApplicationsExpiringSoonCount = data.jobApplicationsExpiringSoonCount;
                this.HRData.jobApplicationsPerEducationLevel = data.jobApplicationsPerEducationLevel;
                this.HRData.jobApplicationsPerLanguageLevel = data.jobApplicationsPerLanguageLevel;
                this.HRData.jobApplicationsPerInterestArea = data.jobApplicationsPerInterestArea;
                this.HRData.jobApplicationsPerGender = data.jobApplicationsPerGender;
                this.getCardsData();
                this.getChartsData();
            },
            error: error => {
                this.HRData = new HRData();
                this.getCardsData();
                this.getChartsData();
                this.notificationService.error(error);
            }
        });
    }

    getCardsData() {
        this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
            map(() => {
                return [
                    {
                        index: 0,
                        title: 'Currículos ativos',
                        caption: 'Quantidade de currículos existentes no sistema.',
                        icon: 'business_center',
                        count: this.HRData.jobApplicationCount,
                        suffix: 'Currículos',
                        route: 'human-resources/job-applications', routeParams: null
                    },
                    {
                        index: 1,
                        title: 'Currículos expirando',
                        caption: 'Quantidade de currículos que expirarão em breve.',
                        icon: 'free_cancellation',
                        count: this.HRData.jobApplicationsExpiringSoonCount,
                        suffix: 'Currículos',
                        route: 'human-resources/job-applications', routeParams: null
                    },
                ];
            })
        );
    }

    getChartsData() {
        this.jobApplicationsPerEducationLevel = {
            title: { text: 'Currículos / Nível de Educação', left: 'center', top: 5, textStyle: { color: '#5D6D7E' } },
            legend: { data: this.HRData.jobApplicationsPerEducationLevel.map(e => this.getEducationLevelDescription(e.level)) },
            tooltip: {
                trigger: 'item',
                formatter: function (data) {
                    return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
                <b>${data.name}:</b> <font size="2">${data.value}</font>`
                }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
            },
            xAxis: {
                show: true,
                axisLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                data: this.HRData.jobApplicationsPerEducationLevel.map(e => ({ value: this.getEducationLevelDescription(e.level) })),
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: {
                    fontSize: 11,
                    formatter: function (value) {
                        return value.length > 30 ? value.substring(0, 30) + '...' : value;
                    },
                }
            },
            grid: {
                height: 200,
                top: 100,
                containLabel: true
            },
            series: [{
                type: 'bar',
                color: '#54ACFD',
                data: this.HRData.jobApplicationsPerEducationLevel.map(e => ({ name: this.getEducationLevelDescription(e.level), value: e.count }))
            }]
        };

        this.jobApplicationsPerLanguageLevel = {
            title: { text: 'Currículos / Idiomas', left: 'center', top: 5, textStyle: { color: '#5D6D7E' } },
            tooltip: {
                trigger: 'item',
                formatter: function (data) {
                    return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
                <b>${data.name}:</b> <font size="2">${data.value}</font>`
                }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
            },
            xAxis: {
                show: true,
                axisLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                data: Extensions.distinctBy(this.HRData.jobApplicationsPerLanguageLevel, l => l.language).map(l => ({ value: l.language })),
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: {
                    fontSize: 11,
                    formatter: function (value) {
                        return value.length > 30 ? value.substring(0, 30) + '...' : value;
                    },
                }
            },
            grid: {
                height: 200,
                top: 100,
                containLabel: true
            },            
            dataZoom: [{
                type: 'slider',
                yAxisIndex: 0,
                minValueSpan: 6,
                maxValueSpan: 6,
                realtime: true,
                handleSize: 0,
                brushSelect: false,
            }],
            series: Extensions.distinctBy(this.HRData.jobApplicationsPerLanguageLevel, l => l.level).sort((a, b) => a.level.localeCompare(b.level)).map(l => ({
                name: l.level,
                stack: 'total',
                type: 'bar',
                color: this.getLanguageLevelColor(l.level),
                data: this.getLanguageCount(l.level).map(x => ({ name: this.getLanguageLevelDescription(l.level), value: x }))
            }))
        };

        this.jobApplicationsPerInterestArea = {
            title: { text: 'Currículos / Área de Interesse', left: 'center', top: 5, textStyle: { color: '#5D6D7E' } },
            tooltip: {
                trigger: 'item',
                formatter: function (data) {
                    return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
                <b>${data.name}:</b> <font size="2">${data.value}</font>`
                }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
            },
            xAxis: {
                show: true,
                axisLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'category',
                inverse: true,
                data: this.HRData.jobApplicationsPerInterestArea.map(a => ({ value: a.interestArea })),
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: {
                    fontSize: 11,
                    formatter: function (value) {
                        return value.length > 30 ? value.substring(0, 30) + '...' : value;
                    },
                }
            },
            grid: {
                height: 200,
                top: 100,
                containLabel: true
            },
            dataZoom: [{
                type: 'slider',
                yAxisIndex: 0,
                minValueSpan: 9,
                maxValueSpan: 9,
                realtime: true,
                handleSize: 0,
                brushSelect: false,
            }],
            series: [{
                type: 'bar',
                color: '#F37B6A',
                data: this.HRData.jobApplicationsPerInterestArea.map(a => ({ name: a.interestArea, value: a.count }))
            }]
        };

        this.jobApplicationsPerGender = {
            title: { text: 'Currículos / Gênero', left: 'center', top: 10, textStyle: { color: '#5D6D7E' } },
            legend: { data: this.HRData.jobApplicationsPerGender.map(g => this.getGenderDescription(g.gender)), align: 'left', top: 45, textStyle: { color: '#5D6D7E' } },
            tooltip: {
              trigger: 'item',
              formatter: function (data) {
                return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
                <b>${data.name}:</b> <font size="2">${data.value}</font><br>
                <font size=2>Percentual: ${data.percent}%</font>`
              }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
            },
            label: {
                alignTo: 'edge', formatter: function (data) { return `{name|${data.name}}\n{percent|${data.percent}%}` }, minMargin: 5,
                edgeDistance: 10, lineHeight: 15, rich: {
                  percent: { fontSize: 10, color: '#5D6D7E' },
                  name: { color: '#5D6D7E' }
                }
              },
            series: [{
              type: 'pie', radius: [50, 90], color: ['#54ACFD', '#1F6DB6', '#AAAAAA'],
              data: this.HRData.jobApplicationsPerGender.map(g => ({ name: this.getGenderDescription(g.gender), value: g.count }))
            }]
          };
    }

    getEducationLevelDescription(level: string) {
        return this.educationLevels.find(x => x.code == level)?.description ?? 'Nenhum';
    }

    getLanguageLevelDescription(level: string) {
        return this.languageLevels.find(x => x.code == level)?.description ?? 'Nenhum';
    }

    getGenderDescription(gender: string) {
        return this.genders.find(x => x.code == gender)?.description ?? 'Outro';
    }

    getLanguageCount(level: string) {
        let list = [];

        let languages = Extensions.distinctBy(this.HRData.jobApplicationsPerLanguageLevel, l => l.language).map(l => l.language);
        let matches = this.HRData.jobApplicationsPerLanguageLevel.filter(x => x.level == level);

        languages.forEach(language => {
            let languageCount = matches.find(x => x.language == language)?.count ?? 0;

            list.push(languageCount);
        });

        return list;
    }

    getLanguageLevelColor(level: string) {
        switch (level) {
            case 'A':
                return '#0EAD5B';
            case 'B':
                return '#DBA30E';
            case 'C':
                return '#B5322D';
            default:
                return '#AAAAAA';
        }
    }
}

export class HRData {
    jobApplicationCount: number;
    jobApplicationsExpiringSoonCount: number;
    jobApplicationsPerEducationLevel: JobApplicationsPerEducationLevel[];
    jobApplicationsPerLanguageLevel: JobApplicationsPerLanguageLevel[];
    jobApplicationsPerInterestArea: JobApplicationsPerInterestArea[];
    jobApplicationsPerGender: JobApplicationsPerGender[];
}