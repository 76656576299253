import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<AddRoleComponent>, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({ description: ['', Validators.required] });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
