import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Notification, NotificationType } from "../view-models/notification";
import Translations from "src/assets/json/MessageTranslations.json";

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private notification$: Subject<Notification> = new Subject();
    private defaultId = 'default-alert';

    // enable subscribing to notifications observable
    onNotify(id = this.defaultId): Observable<Notification> {
        return this.notification$.asObservable().pipe(filter(x => x && x.id === id));
    }

    sucess(message: string) {
        this.add(message, "OK", { autoClose: true, type: NotificationType.Success });
    }

    error(message: string, dynamic?: any) {
        if (!message.endsWith('.')) {
            message = `${message}.`;
        }

        if (dynamic) {
            switch (dynamic) {
                case Number:
                    let regex = /\d+/g;
                    let number =  message.split(" ").find((i: any) => i.match(regex));
                    this.add(Translations.Errors[message.replace(number, "#")]?.replace("#", number) ?? message, "OK", { autoClose: false, type: NotificationType.Error });
                    return;
            }
        }

        // Find the translation to the message, or use the original one
        this.add(Translations.Errors[message] ?? message, "OK", { autoClose: false, type: NotificationType.Error });
    }

    warning(message: string) {
        this.add(message, "OK", { autoClose: false, type: NotificationType.Warning });
    }

    info(message: string) {
        this.add(message, "OK", { autoClose: false, type: NotificationType.Info });
    }

    /** Add a notification object as observable to show a message in a snackBar. */
    private add(message: string, action: string, options?: any) {
        this.notify(new Notification({ ...options, message, action }));
    }

    // core alert method
    private notify(notification: Notification) {
        notification.id = notification.id || this.defaultId;
        notification.autoClose = (notification.autoClose === undefined ? true : notification.autoClose);
        notification.type = (notification.type === undefined ? NotificationType.Success : notification.type);
        this.notification$.next(notification);
    }

}