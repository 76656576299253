<h1 mat-dialog-title>Editar item</h1>
<mat-dialog-content [formGroup]="form">  
  <mat-form-field>
    <mat-label>Quantidade</mat-label>
    <input type="number" matInput formControlName="quantity">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>
