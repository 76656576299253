import { APP_INITIALIZER, FactoryProvider } from "@angular/core";
import { SettingsService } from "../services/settings.service";
import { UserService } from "../services/user.service";

function appInitializer(userService: UserService) {
    return () => new Promise((resolve: any) => {
        // attempt to refresh token on app start up to auto authenticate
        userService.refreshToken()
            .subscribe()
            .add(resolve);
    });
}

function settingsInitializer(settingsService: SettingsService) {
    return () => settingsService.initSettingsObject();
}

export const refreshTokenProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    deps: [UserService],
    multi: true
};

export const settingsProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: settingsInitializer,
    deps: [SettingsService],
    multi: true
};