import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-job-application-finished',
    templateUrl: './job-application-finished.component.html',
    styleUrls: ['./job-application-finished.component.scss']
})

export class JobApplicationFinishedComponent implements OnInit {
    type: number;

    constructor( private route: ActivatedRoute) { }

    ngOnInit() {
        this.type = this.route.snapshot.queryParams['type'] ?? 0;
    }
}
