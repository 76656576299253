<div class="main-div">
  <div *ngIf="tokenStatus == TokenStatus.Validating">
    Validando token...
  </div>
  <mat-card class="default-card" *ngIf="tokenStatus == TokenStatus.Valid">
    <mat-card-header>
      <div mat-card-avatar class="default-card-header-image"></div>
      <mat-card-title>Nova senha</mat-card-title>
      <mat-card-subtitle>Digite e confirme sua nova senha nos campos abaixo.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
      <form [formGroup]="form">
        <p>
          <mat-form-field class="full-container">
            <input type="password" matInput placeholder="Senha" formControlName="password">
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-container">
            <input type="password" matInput placeholder="Confirmar Senha" formControlName="confirmPassword">
          </mat-form-field>
        </p>
      </form>
    </mat-card-content>

    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="loading">Confirmar</button>
      <a mat-stroked-button routerLink="">Voltar ao login</a>
    </mat-card-actions>
  </mat-card>
  <mat-card *ngIf="tokenStatus == TokenStatus.Invalid">
    <mat-card-content>
      <div>
        A validação do token falhou, se o mesmo expirou, você pode obter outro em <a
          routerLink="../forgot-password">esqueci minha senha.</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>