import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from './services/notification.service';
import { Subscription } from 'rxjs';
import { MessageDialogComponent } from './helpers/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Notification, NotificationType } from './view-models/notification';
import { LoadingService } from './services/loading.service';
import { delay } from 'rxjs/operators';
import { SettingsService } from './services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [],
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'vitisys-web';
  loading: boolean = false;
  notificationSubscription: Subscription;
  enterpriseSubscription: Subscription;
  enterpriseId: number;
  orientationSubscription: Subscription;
  targetURL = '';
  allowPortrait = ['/', '/settings', '/job-application', '/job-application/done'];

  constructor(private notificationService: NotificationService, private loadingService: LoadingService, private snackBar: MatSnackBar,
    private dialog: MatDialog, private settingsService: SettingsService, private router: Router, private userService: UserService) {
    // subscribe to new alert notifications
    this.notificationSubscription = this.notificationService.onNotify('default-alert')
      .subscribe(notification => {
        // clear alerts when an empty alert is received
        if (notification) {
          // auto close alert if required
          if (notification.autoClose) {
            this.snackBar.open(notification.message, notification.action, { duration: 3000 });
          }
          else {
            if (notification.type == NotificationType.Success) {
              this.snackBar.open(notification.message, notification.action);
            }
            else {
              this.showMessageDialog(notification);
            }
          }
        }
      });

    // redirect to main menu when enterprise is changed
    this.enterpriseSubscription = this.settingsService.enterprise.subscribe({
      next: enterpriseID => {
        if (enterpriseID != this.enterpriseId && this.enterpriseId != null) {
          this.enterpriseId = enterpriseID;
          this.router.navigate(['/']);
        }
      }
    });

    // In case it didnt fall into the if on the request above
    this.enterpriseId = this.enterpriseId ?? this.settingsService.getParameter('enterprise');

    window.addEventListener("orientationchange", () => {
      if (window.orientation == 0 && !this.allowPortrait.includes(this.router.url)) {
        this.showRotationWarning();
      }
      else {
        if (this.targetURL.length > 0) {
          this.router.navigate([this.targetURL]);
        }
      }
    }, false);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !event.url.includes('/messages')) {
        this.targetURL = event.url;

        if (window.orientation == 0 && this.userService.authenticationValue != null && !this.allowPortrait.includes(this.router.url)) {
          this.showRotationWarning();
        }
      }
    })
  }

  showRotationWarning() {
    this.router.navigate(['/messages/3']);
  }

  showMessageDialog(notification: Notification) {
    this.dialog.open(MessageDialogComponent, { data: notification });
  }

  ngOnInit() {
    this.listenToLoading();
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.enterpriseSubscription.unsubscribe();
    this.orientationSubscription.unsubscribe();
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
