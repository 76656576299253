<div>
    <div class="pop" [ngClass]="{ 'hidden': step != 0 }">
        <div fxLayout="column" fxLayoutAlign="start center">
            <h6 class="mat-headline">Olá, {{ loggedUser?.name.split(' ')[0] }}!</h6>
            <label class="mat-body">Antes de começar a utilizar o Vitisys, configure o seu perfil.</label>
            <img class="profile-setup" src="assets/img/profile-setup.png">
            <button mat-raised-button color="primary" (click)="nextStep(1)" tabindex="-1">Vamos lá</button>
        </div>
    </div>

    <div [class]="step != 1 ? 'hidden' : 'edit-picture'">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h6 class="mat-title">Adicione uma imagem de perfil.</h6>
            <a (click)="dialog.click()" DragAndDropDirective (fileDropped)="changeProfilePicture($event)"
                class="drop-area">
                <img [class]="user?.image ? 'profile-picture border' : 'placeholder border'"
                    [src]="user?.image ?? 'assets/img/camera.png'">
            </a>
            <input hidden type="file" #dialog (change)="changeProfilePicture($event)" accept=".png, .jpeg">
            <br>
            <small>Clique ou arraste uma imagem .png ou .jpeg (400KB)</small>
            <br>
            <button mat-raised-button color="primary" (click)="nextStep(2)">Próximo</button>
        </div>
    </div>

    <div [class]="step != 2 ? 'hidden' : 'edit-department'">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h6 class="mat-title">Selecione o seu departamento.</h6>
            <mat-form-field>
                <mat-label>Departamento</mat-label>
                <mat-select aria-label="Select an option" [(ngModel)]="department">
                    <mat-option *ngFor="let dpt of departments" [value]="dpt">
                        {{ dpt.Description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <small>Utilizamos essa informação para otimizar sua experiência.</small>
            <br>
            <button mat-raised-button color="primary" (click)="save()">Próximo</button>
        </div>
    </div>

    <div [class]="step != 3 ? 'hidden' : 'edit-department'">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h6 class="mat-title">Tudo Pronto!</h6>
            <!-- <mat-icon class="icon-lg successful">verified</mat-icon> -->
            <img class="success-animation" src="assets/img/success.gif">
            <small>Seu perfil foi configurado com sucesso.</small>
            <small>Caso queira alterar alguma configuração, basta ir até o seu perfil.</small>
            <br>
            <button mat-raised-button color="primary" (click)="close()">Fechar</button>
        </div>
    </div>
</div>