import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HumanResourcesService } from 'src/app/services/human-resources.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-verify-existing-job-application',
    templateUrl: './verify-existing-job-application.component.html',
    styleUrls: ['./verify-existing-job-application.component.scss']
})

export class VerifyExistingJobApplicationComponent implements OnInit {
    form: UntypedFormGroup;
    showSendUpdateTokenOption: boolean = false;
    siteKey = environment.siteKey;
    showCaptcha: boolean = true;

    constructor(private dialogRef: MatDialogRef<VerifyExistingJobApplicationComponent>, private humanResourcesService: HumanResourcesService,
        private notificationService: NotificationService, private router: Router) { }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            cpf: new UntypedFormControl(null, [Validators.required]),
            reCaptcha: new UntypedFormControl(null)
        });
    }

    search() {
        if (this.form.invalid) {
            return;
        }

        this.humanResourcesService.verifyExistingJobApplication({ cpf: this.form.value.cpf }).subscribe({
            next: result => {
                if (result.exists) {
                    this.showSendUpdateTokenOption = true;
                }
                else {
                    this.dialogRef.close(this.form.value.cpf);
                }
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    sendUpdateToken() {
        if (this.form.value.reCaptcha == null) {
            this.notificationService.warning('Complete o CAPTCHA');
            return;
        }

        this.humanResourcesService.sendJobApplicationUpdateToken({ cpf: this.form.value.cpf, reCaptcha: this.form.value.reCaptcha, origin: location.origin }).subscribe({
            next: () => {
                this.notificationService.sucess('E-mail enviado');
                this.router.navigate(['job-application/done'], { queryParams: { type: 2 } });
                this.dialogRef.close();
            },
            error: error => {
                this.notificationService.error(error);
                this.resetCaptcha();
            }
        });
    }

    changeCPF() {
        this.showSendUpdateTokenOption = false;
        this.resetCaptcha();
        this.form.reset();
    }

    resetCaptcha() {
        this.showCaptcha = false;

        setTimeout(() => {
            this.showCaptcha = true;
        });
    }
}
