import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/models/user';
import Translations from 'src/assets/json/EnumTranslations.json';
import { Observable } from 'rxjs';
import { DashboardItem } from 'src/app/view-models/dashboard-item';

@Component({
  selector: 'app-dashboard-it',
  templateUrl: './dashboard-it.component.html',
  styleUrls: ['./dashboard-it.component.scss', '../dashboards.scss']
})
export class DashboardITComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards: Observable<DashboardItem[]>;
  department = Translations.Departments.IT.Description;
  departmentIcon = 'memory';
  ITData: ITData = new ITData();

  constructor(private breakpointObserver: BreakpointObserver, private settingsService: SettingsService, private adminService: AdminService, private userService: UserService,
    private notificationService: NotificationService, private router: Router) { }

  ngOnInit() {

    let usersRequest = this.userService.getUsers();

    forkJoin([usersRequest]).subscribe({
      next: response => {
        this.ITData.activeUsers = response[0].filter(u => u.isLogged);

        this.getCardsData();
      },
      error: error => {
        this.notificationService.error(error);
      }
    })
  }

  getCardsData() {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(() => {
        return [
          {
            index: 0,
            title: 'Usuários conectados',
            caption: 'Usuários que estão conectados atualmente no sistema.',
            icon: 'groups',
            count: this.ITData.activeUsers?.length,
            suffix: 'Usuários',
            route: 'admin', routeParams: { filter: 'Connected' }
          },
          {
            index: 1,
            title: 'Nenhum conteúdo',
            caption: 'Nenhuma descrição.',
            icon: 'vpn_lock',
            count: '-',
            suffix: null,
            route: null, routeParams: null
          },
          {
            index: 2,
            title: 'Nenhum conteúdo',
            caption: 'Nenhuma descrição.',
            icon: 'vpn_lock',
            count: '-',
            suffix: null,
            route: null, routeParams: null
          },
          {
            index: 3,
            title: 'Nenhum conteúdo',
            caption: 'Nenhuma descrição.',
            icon: 'vpn_lock',
            count: '-',            
            suffix: null,
            route: null, routeParams: null
          }
        ];
      })
    );
  }
}

export class ITData {
  activeUsers: User[];
}
