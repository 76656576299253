import { Component, OnInit, ViewChild } from '@angular/core';
import { Enterprise } from '../models/enterprise';
import { Authentication } from '../models/authentication';
import { EntryService } from '../services/entry.service';
import { UserService } from '../services/user.service';
import { SidenavItem } from '../view-models/sidenav-item';
import { SettingsService } from '../services/settings.service';
import { Router } from '@angular/router';
import { SalesGoalsRedirectComponent } from '../sales/sales-goals-redirect/sales-goals-redirect.component';
import { MatDialog } from '@angular/material/dialog';
import { Extensions } from '../helpers/extensions';
import { ProfitsSharingRedirectComponent } from '../accounting/profits-sharing-redirect/profits-sharing-redirect.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  enterprises: Enterprise[];
  selectedEnteprise: Enterprise;
  loggedUser: Authentication;
  userImage: string = null;

  entries: SidenavItem[] = [
    { title: 'Clientes', icon: 'co_present', route: '/entries' },
    { title: 'Fornecedores', icon: 'electric_rickshaw', route: '/entries/suppliers' },
    { title: 'Itens', icon: 'class', route: '/entries/items' },
    { title: 'Funcionários', icon: 'face', route: '/entries/employees' },
    { title: 'Empresas', icon: 'business', route: '/entries/enterprises' },
    { title: 'Representantes', icon: 'groups_2', route: '/entries/salesmen' },    
    { title: 'Tabela de Fretes', icon: 'trolley', route: '/entries/shipping-costs' }
  ];

  logistics: SidenavItem[] = [
    { title: 'Cargas', icon: 'local_shipping', route: '/logistics' },
    { title: 'Paletes', icon: 'storage', route: '/logistics/pallets' },
    { title: 'Paletização Resumida', icon: 'pallet', route: '/logistics/summarized-palletization' }
  ];

  accounting: SidenavItem[] = [
    { title: 'Orçamentos', icon: 'monetization_on', route: '/accounting' },
    { title: 'Centros de Custo', icon: 'euro', route: '/accounting/cost-centers' },
    { title: 'Contas Contábeis', icon: 'attach_money', route: '/accounting/accounts' },
    { title: 'Contas Orçamentárias', icon: 'account_balance_wallet', route: '/accounting/financial-accounts' },
    { title: 'Fundopem', icon: 'receipt_long', route: '/accounting/fundopem' },
    { title: 'PPR', icon: 'workspace_premium', route: undefined, dialog: ProfitsSharingRedirectComponent },
  ];

  sales: SidenavItem[] = [
    { title: 'Metas de Venda', icon: 'paid', route: undefined, dialog: SalesGoalsRedirectComponent },
    { title: 'Verbas de Clientes', icon: 'request_page', route: 'sales/customer-funding' },    
    { title: 'Simular Negociação', icon: 'assessment', route: 'sales/sale-simulations' }
  ]

  admins: SidenavItem[] = [
    { title: 'Usuários', icon: 'supervised_user_circle', route: '/admin' },
    { title: 'Regras', icon: 'verified_user', route: '/admin/roles' },
    { title: 'Parâmetros', icon: 'toggle_on', route: '/admin/parameters' },
    { title: 'Jobs', icon: 'play_circle', route: '/admin/jobs' },
    { title: 'Long-Lived Tokens', icon: 'cookie', route: '/admin/long-lived-tokens' },
  ];

  humanResources: SidenavItem[] = [
    { title: 'Currículos Web', icon: 'business_center', route: '/human-resources/job-applications' },
    { title: 'Áreas de Interesse', icon: 'bookmarks', route: '/human-resources/interest-areas' },
    { title: 'Ocorrências', icon: 'timeline', route: '/human-resources/occurrences' },
  ];

  @ViewChild('sidenav') sidenav;

  constructor(private userService: UserService, private entryService: EntryService, private settingsService: SettingsService, private router: Router,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.entryService.getEnterprises().subscribe({
      next: enterprises => {
        this.enterprises = enterprises;
        this.selectEnterprise(enterprises.find(e => e.id == this.settingsService.getParameter('enterprise')));
      },
      error: () => { }
    });

    this.userService.authentication.subscribe({
      next: data => {
        this.loggedUser = data;
        this.getUserImage();
      },
      error: error => {
        this.loggedUser.name = error;
      }
    });
  }

  getUserImage() {
    if (this.loggedUser == null) {
      return;
    }

    this.userService.getUser({ ID: this.loggedUser?.id }).subscribe({
      next: user => {
        this.userImage = user.image?.length > 0 ? Extensions.convertImage(user.image) : null;
      },
      error: () => {
        this.userImage = null;
      }
    });
  }

  navigate(item) {
    this.sidenav.close();
    if (item.route) {
      this.router.navigate([item.route]);
    }
    else {
      this.dialog.open(item.dialog, { panelClass: "adjustable-dialog" });
    }
  }

  selectEnterprise(enterprise: Enterprise) {
    this.selectedEnteprise = enterprise;
    this.settingsService.setParameter('enterprise', enterprise.id);
  }

  logout() {
    this.userService.logout();
  }

  get enterpriseShort(): string {
    let enterprise = this.selectedEnteprise?.registrationName ?? '';
    enterprise = enterprise.replace('COOPERATIVA', '').replace('VINICOLA', '').replace('GARIBALDI', '').replace('LTDA', '').replace('-', '');
    return enterprise.trim().length > 0 ? enterprise : 'EMPRESA MATRIZ';
  }

  get usernameShort(): string {
    return this.loggedUser?.name.substring(0, 1);
  }
}
