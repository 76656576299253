<div class="main-div">
  <div *ngIf="tokenStatus == TokenStatus.Validating">
    Validando token...
  </div>
  <mat-card class="large-card details" *ngIf="tokenStatus == TokenStatus.Valid">
    <mat-card-header>
      <div mat-card-avatar class="default-card-header-image"></div>
      <mat-card-title>Agende sua coleta</mat-card-title>
      <mat-card-subtitle class="subtitle">
        <div *ngIf="tabGroup.selectedIndex == 0">
          <span>Preencha os campos abaixo com os dados do veículo e sugira uma data de
            coleta.</span><span>Após, clique em "confirmar" para acessar o menu dos itens</span>
        </div>
        <div *ngIf="tabGroup.selectedIndex == 1">
          <span>Selecione <mat-icon class="checkbox-icon">check_box</mat-icon>
            os itens que deseja carregar. Verifique também o peso total da carga.</span>
          <span *ngIf="tabGroup.selectedIndex == 1">Aguarde a confirmação da coleta no e-mail.</span>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group #tabGroup dynamicHeight [(selectedIndex)]="selectedTab">
        <mat-tab label="Dados">
          <mat-list>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <p class="mat-body-strong">Carga {{cargo.description}} de {{cargo.date | date: 'dd/MM/yyyy'}}
              </p>
              <p class="mat-body-strong">Pallets: {{cargo.palletsCount}} / Cidades: {{cargo.cities}}
              </p>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item>
              <p class="mat-body">Ordens de compra: {{cargo.invoices}} </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <form [formGroup]="form">
              <p class="radio-container">
                <mat-radio-group formControlName="type">
                  <mat-radio-button value="P" checked>Paletizada</mat-radio-button>
                </mat-radio-group>
                <label class="mat-body-2" *ngIf="form.value.type == 'P'">OBS: É obrigatório trazer pallets PBR coforme
                  indicado no link. Não serão carregados veículos batidos. Só serão carregados veículos fechados (baú ou
                  sider). Para maiores informações, entrar em contato com o setor de logística.</label>
              </p>
              <p>
                <mat-form-field class="half-container">
                  <mat-label>Data para coleta</mat-label>
                  <input matInput [matDatepickerFilter]="weekendsDatesFilter" [min]="minDate" [max]="maxDate"
                    [matDatepicker]="picker" formControlName="date">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </p>
              <p class="form-field-container">
                <mat-form-field class="full-container">
                  <mat-label>Transportador</mat-label>
                  <input matInput formControlName="shippingCompany">
                </mat-form-field>
                <mat-form-field class="full-container">
                  <mat-label>CNPJ</mat-label>
                  <input matInput placeholder="(Somente números)" formControlName="shippingCompanyCode" mask="CPF_CNPJ">
                </mat-form-field>
              </p>
              <p class="form-field-container">
                <mat-form-field class="half-container">
                  <mat-label>Motorista</mat-label>
                  <input matInput formControlName="driverName">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Placa 1</mat-label>
                  <input matInput formControlName="licencePlate" placeholder="Ex. ABC-1D23">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Placa 2 - Carreta</mat-label>
                  <input matInput formControlName="truckLicencePlate" placeholder="Ex. ABC-1D23">
                  <mat-hint>Preencher ambas se for carreta</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field class="full-container">
                  <mat-label>Comentários</mat-label>
                  <textarea matInput placeholder="Ex. Quero cancelar minha carga..." formControlName="comments"
                    #comments maxlength="200"></textarea>
                  <mat-hint align="end">{{comments.value.length}} / 200</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field class="half-container">
                  <mat-label>Telefone</mat-label>
                  <input matInput placeholder="Seu telefone de contato." formControlName="phoneNumber"
                    mask="(00) 00000-0000">
                </mat-form-field>
              </p>
            </form>
          </mat-list>
        </mat-tab>
        <mat-tab label="Itens">
          <div class="scrollable-table-container">
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            <table mat-table [dataSource]="cargo.groupedItems" class="mat-elevation-z1">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" *ngIf="!row.isBlocked">
                  </mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef>TOTAL</td>
              </ng-container>

              <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef> Item </th>
                <td mat-cell *matCellDef="let element"> {{element.item}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef> Quantidade </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity | number}} </td>
                <td mat-footer-cell *matFooterCellDef>{{getSelectedQuantity() | number}}</td>
              </ng-container>

              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef> Peso total</th>
                <td mat-cell *matCellDef="let element"> {{element.totalWeight | number}} </td>
                <td mat-footer-cell *matFooterCellDef>{{getSelectedWeight() | number}}</td>
              </ng-container>

              <ng-container matColumnDef="isBlocked">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <mat-chip-list *ngIf="element.isBlocked">
                    <mat-chip>Indisponível</mat-chip>
                  </mat-chip-list>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" align="right">
                  <button mat-icon-button *ngIf="!element.isBlocked" (click)="showEditDialog(element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
          <br>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-button color="primary" (click)="showPalletsInfo()">
              <mat-icon class="material-icons-outlined">info</mat-icon>
              Veja aqui a paletização resumida
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="loading">Confirmar</button>
      <a mat-stroked-button routerLink="">Cancelar</a>
    </mat-card-actions>
  </mat-card>
  <mat-card *ngIf="tokenStatus == TokenStatus.Invalid">
    <mat-card-content>
      <div>
        Este link não é válido. Se tiver alguma dúvida, entre em contato conosco.
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="tokenStatus == TokenStatus.AfterSent">
    <mat-card-content>
      <div>
        Agendamento enviado com sucesso! Aguarde a confirmação no e-mail.
      </div>
    </mat-card-content>
  </mat-card>
</div>