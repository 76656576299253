<h1 mat-dialog-title>
    Escolha o período
</h1>

<div mat-dialog-content>
    <div>
        <mat-form-field>
            <mat-select [(value)]="selectedPeriod" (selectionChange)="selectPeriod()">
                <mat-option *ngFor="let period of periods" [value]="period">
                    {{period.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p>
            <mat-checkbox (change)="setPeriodAsCurrentMonth($event.checked)" [checked]="isCurrentMonthSelected" color="primary">
                Selecionar mês atual</mat-checkbox>
        </p>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Confirmar</button>
</div>