import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cargo } from '../models/cargo';
import { CargoSchedule } from '../models/cargo-schedule';
import { CargoScheduleItem } from '../models/cargo-schedule-item';
import { PalletReceipt } from '../models/pallet-receipt';
import { PalletsBalance } from '../models/pallet-balance';
import { CargoScheduleComparison } from '../models/cargo-schedule-comparison';
import { SummarizedPalletization } from '../models/summarized-palletization';

@Injectable({
  providedIn: 'root'
})
export class LogisticsService {

  constructor(private http: HttpClient) { }

  getCargos(params?) {
    return this.http.get<Cargo[]>(`${environment.API}cargos`, { params }).pipe();
  }

  getCargo(params?) {
    return this.http.get<Cargo>(`${environment.API}cargos/id`, { params }).pipe();
  }

  getReceipts(params?) {
    return this.http.get<PalletReceipt[]>(`${environment.API}pallets`, { params }).pipe();
  }

  getSchedule(params?) {
    return this.http.get<CargoSchedule>(`${environment.API}cargos/schedule`, { params }).pipe();
  }
  
  getScheduleItems(params?) {
    return this.http.get<CargoScheduleItem[]>(`${environment.API}cargos/schedule-items`, { params }).pipe();
  }

  putReceipt(id: number, receipt) {
    return this.http.put<any>(`${environment.API}pallets/${id}`, receipt).pipe();
  }

  postReceipt(receipt) {
    return this.http.post<PalletReceipt>(`${environment.API}pallets`, receipt).pipe();
  }

  putCargo(id: number, cargo) {
    return this.http.put<any>(`${environment.API}cargos/${id}`, cargo).pipe();
  }

  putCargoItems(cargoId: number, itemId: number, isBlocked: boolean) {
    return this.http.put(`${environment.API}cargos/items`, { cargoId, itemId, isBlocked })
  }

  sendScheduleRequest(cargoId: number, recipient: string, sender: string, comments: string, palletsCount: number) {
    let origin = location.origin;
    return this.http.post(`${environment.API}cargos/send-schedule`, { cargoId, sender, recipient, origin, comments, palletsCount });
  }

  confirmSchedule(id: number, date: Date, isResend: boolean, origin: string, shippingAddress: string) {
    return this.http.post(`${environment.API}cargos/confirm-schedule`, { id, date, isResend, origin, shippingAddress });
  }

  validateScheduleToken(token: string) {
    return this.http.post<Cargo>(`${environment.API}cargos/validate-schedule-token`, { token });
  }

  proposeSchedule(schedule) {
    return this.http.post(`${environment.API}cargos/propose-schedule`, schedule);
  }

  deleteSchedule(params) {
    return this.http.delete(`${environment.API}cargos/schedule`, { params });
  }

  getReceiptsBalance(params?) {
    return this.http.get<PalletsBalance[]>(`${environment.API}pallets/balances`, { params }).pipe();
  }

  getCargoComparison(params?) {
    return this.http.get<CargoScheduleComparison[]>(`${environment.API}cargos/compare`, { params }).pipe();
  }

  getSummarizedPalletization() {
    return this.http.get<SummarizedPalletization[]>(`${environment.API}summarizedPalletization`).pipe();
  }

  getSummarizedPalletizationById(params) {
    return this.http.get<SummarizedPalletization>(`${environment.API}summarizedPalletization/id`, { params }).pipe();
  }

  createSummarizedPalletization(body) {
    return this.http.post<any>(`${environment.API}summarizedPalletization`, body).pipe();
  }

  updateSummarizedPalletization(id: number, quantity: number) {
    return this.http.put<any>(`${environment.API}summarizedPalletization/${id}`, { id, quantity }).pipe();
  }

  deleteSummarizedPalletization(params) {
    return this.http.delete(`${environment.API}summarizedPalletization`, { params }).pipe();
  }
}
