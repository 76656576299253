<h1 mat-dialog-title>Área de Interesse</h1>
<mat-dialog-content>
    <mat-form-field class="full-container">
        <mat-label>Área</mat-label>
        <mat-select [(ngModel)]="interestArea" placeholder="Selecione">
            <mat-option *ngFor="let interestArea of interestAreas" [value]="interestArea">
                {{ interestArea.description }}
            </mat-option>
        </mat-select>
        <mat-error>Área inválida</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>