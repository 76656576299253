<div class="main-div">
    <mat-card class="default-card">
        <mat-card-header>
            <div mat-card-avatar class="default-card-header-image"></div>
            <mat-card-title>Esqueceu a senha?</mat-card-title>
            <mat-card-subtitle>Forneça um e-mail que lhe mandaremos instruções para recuperar sua conta.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            <form [formGroup]="form">
                <p>
                    <mat-form-field class="full-container">
                        <input type="text" matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                    <ngx-recaptcha2 *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
                        size="normal" hl="pt" theme="light" type="image" formControlName="reCaptcha">
                    </ngx-recaptcha2>
                </p>
            </form>
        </mat-card-content>

        <mat-card-actions>
            <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="loading">Envie-me
                instruções</button>
            <a mat-stroked-button routerLink="">Voltar ao login</a>
        </mat-card-actions>
    </mat-card>
</div>