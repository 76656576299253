import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class ExternalService {
  
    constructor(private http: HttpClient) { }

    getCountries() {
      return this.http.get<any>(`https://servicodados.ibge.gov.br/api/v1/localidades/paises?orderBy=nome`).pipe();
    }

    getCities(state: string) {
        return this.http.get<any[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`).pipe();
    }
}