<mat-toolbar color="primary">

  <a mat-button routerLink="." (click)="sidenav.close()" class="title-mobile">
    <img src="/assets/img/icon.png" width="30" height="30">
    Vitisys Web</a>
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <button mat-icon-button routerLink="." (click)="sidenav.close()" class="home-mobile">
    <mat-icon>home</mat-icon>
  </button>

  <span class="menu-spacer"></span>

  <mat-chip-list class="enterprise-desktop">
    <mat-chip color="primary" selected>{{selectedEnteprise?.registrationName}}</mat-chip>
  </mat-chip-list>
  <label class="enterprise-mobile mat-caption">{{ enterpriseShort }}</label>
  <button mat-icon-button class="show-more" [matMenuTriggerFor]="eMenu">
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #eMenu="matMenu">
    <button mat-menu-item *ngFor="let enterprise of enterprises" (click)="selectEnterprise(enterprise)">
      <mat-icon>business</mat-icon>
      <span>{{enterprise.id + ' - ' + enterprise.registrationName}}</span>
    </button>
  </mat-menu>

  <mat-chip-list ng-if="!loggedUser">
    <mat-chip color="warn" class="user-desktop" selected>{{loggedUser.name}}
    </mat-chip>
  </mat-chip-list>
  <img [class]="userImage ? 'profile-picture' : 'default-avatar'" [src]="userImage ?? 'assets/img/default-avatar.png'"
    [matMenuTriggerFor]="menu">
  <mat-menu #menu="matMenu">
    <a mat-menu-item routerLink="/settings" (click)="sidenav.close()">
      <mat-icon>account_circle</mat-icon>
      <span>Meu perfil</span>
    </a>
    <button mat-menu-item (click)="logout()" (click)="sidenav.close()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container fullscreen class="container">
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <mat-nav-list>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">CADASTROS</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of entries" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">LOGÍSTICA</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of logistics" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">CONTABILIDADE</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of accounting" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">COMERCIAL</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of sales" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">RECURSOS HUMANOS</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of humanResources" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h2 matSubheader class="font-mobile">ADMINISTRAÇÃO</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of admins" mat-list-item (click)="navigate(item)" class="font-mobile">
            <mat-icon matListIcon class="icon-mobile"> {{ item.icon }} </mat-icon>
            {{item.title}}
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>