import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { EmbedReportConfig } from "../models/embed-report-config";

@Injectable({
    providedIn: 'root'
})

export class PbiEmbeddedService {
    
    constructor(private http: HttpClient) { }

    getPowerBIReport(params?: any) {
        return this.http.get<EmbedReportConfig>(`${environment.API}BI`, { params }).pipe();
    }
}