import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as xlsx from 'xlsx';
import * as moment from 'moment';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Extensions } from 'src/app/helpers/extensions';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-cargo-schedule-report',
  templateUrl: './cargo-schedule-report.component.html',
  styleUrls: ['./cargo-schedule-report.component.scss']
})
export class CargoScheduleReportComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['item', 'quantity'];
  reports = Object.keys(ReportTypes).map(key => ReportTypes[key]);
  report = ReportTypes.Items;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(new Date()),
    end: new UntypedFormControl(new Date())
  });

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private service: LogisticsService, private notificationService: NotificationService, private settingsService: SettingsService) { }

  ngOnInit() {
    this.applyDate();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    moment.locale('pt');
  }

  applyDate() {
    if (this.range.value.start == null) {
      this.range.patchValue({ start: new Date() })
    }

    if (this.range.value.end == null) {
      this.range.patchValue({ end: this.range.value.start });
    }
  }

  getReportData(report) {
    let enterprise = this.settingsService.getParameter('enterprise');
    this.report = report;
    let params = new HttpParams().appendAll({
      startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise
    });

    switch (this.report) {
      case ReportTypes.Items:
        this.displayedColumns = ['item', 'quantity'];
        this.service.getScheduleItems(params)
          .subscribe({
            next: items => this.dataSource.data = items,
            error: error => this.notificationService.error(error)
          });
        break;
      case ReportTypes.Cargos:
        params = params.append('isSchedule', true);
        this.displayedColumns = ['date', 'cargo', 'schedule'];
        this.service.getCargos(params)
          .subscribe({
            next: cargos => this.dataSource.data = cargos,
            error: error => this.notificationService.error(error)
          });
        break;
    }
  }

  getTotalQuantity() {
    if (this.dataSource != null) {
      return this.dataSource.data.map(c => c.quantity)
        .reduce((acc, value) => acc + value, 0);
    }

    return 0;
  }

  exportToPDF() {
    var doc = new jsPDF();
    doc.addImage('assets/img/logo.jpg', 'JPGE', 15, 1, 40, 16);
    doc.setFontSize(20);
    doc.text('Programação', 75, 10);
    doc.setDrawColor(230);
    doc.line(15, 20, 195, 20);
    doc.setFontSize(10);
    let d1 = moment(this.range.value.start);
    let d2 = moment(this.range.value.end);
    doc.text(`De: ${d1.format('l')} a ${d2.format('l')}`, 15, 25);
    autoTable(doc, { html: '#tb-itens', startY: 30 });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  }

  exportToExcel() {
    const ws = xlsx.utils.book_new();
    let worksheet;

    switch (this.report) {
      case ReportTypes.Items:
        xlsx.utils.sheet_add_aoa(ws, [['Item', 'Quantidade']]);
        worksheet = xlsx.utils.sheet_add_json(ws, this.dataSource.data, { skipHeader: true, origin: 'A2' });
        break;
      case ReportTypes.Cargos:
        xlsx.utils.sheet_add_aoa(ws, [['', 'Carga', 'Data de Geração', 'Agendamento', '', '', '', '', '', '', '', '', '', '']]);
        worksheet = xlsx.utils.sheet_add_json(ws, this.dataSource.data, { skipHeader: true, origin: 'A2' });
        worksheet['!cols'] = []
        worksheet['!cols'][0] = { hidden: true };
        worksheet['!cols'][4] = { hidden: true };
        worksheet['!cols'][5] = { hidden: true };
        worksheet['!cols'][6] = { hidden: true };
        worksheet['!cols'][7] = { hidden: true };
        worksheet['!cols'][8] = { hidden: true };
        worksheet['!cols'][9] = { hidden: true };
        worksheet['!cols'][10] = { hidden: true };
        worksheet['!cols'][11] = { hidden: true };
        worksheet['!cols'][12] = { hidden: true };
        worksheet['!cols'][13] = { hidden: true };
        worksheet['!cols'][14] = { hidden: true };

        Object.keys(worksheet).filter(i => (i.startsWith('C') || i.startsWith('D')) && !['C1', 'D1'].includes(i)).forEach(col => {
          worksheet[col] = { t: 's', v: new Date(worksheet[col].v).toLocaleDateString('pt') }
        });
        break;
    }
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    Extensions.saveAsExcelFile(excelBuffer, "programacao_de_cargas");
  }

  get ReportTypes(): typeof ReportTypes {
    return ReportTypes;
  }
}

export enum ReportTypes {
  Items = 'Itens',
  Cargos = 'Cargas'
}
