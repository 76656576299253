<header>
    <div fxLayout="row" fxLayoutAlign="space-between end">
        <img src="/assets/img/brand-logo.png">
        <div fxLayout="column" fxLayoutAlign="end end">
            <div mat-dialog-title class="title">Trabalhe Conosco</div>
            <small class="subtitle">Cooperativa Vinícola Garibaldi</small>
        </div>
    </div>
</header>
<div class="container-content">
    <mat-stepper #stepper linear>
        <mat-step [stepControl]="personalInfoForm" label="Informações pessoais" state="person">
            <form [formGroup]="personalInfoForm">
                <br>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div>
                        <mat-form-field class="input-lg">
                            <mat-label>Nome completo</mat-label>
                            <input matInput placeholder="Insira seu nome" formControlName="name" maxlength="70">
                            <mat-hint align="end">{{ personalInfoForm.value.name?.length ?? 0 }} / 70</mat-hint>
                            <mat-error>Nome inválido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-lg">
                            <mat-label>E-mail</mat-label>
                            <input matInput placeholder="Insira seu email" formControlName="email" maxlength="70">
                            <mat-hint align="end">{{ personalInfoForm.value.email?.length ?? 0 }} / 70</mat-hint>
                            <mat-error>E-mail inválido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-md">
                            <mat-label>Telefone</mat-label>
                            <input matInput placeholder="Insira seu telefone" formControlName="phoneNumber"
                                mask="(00) 0 0000-0000">
                            <mat-error>Telefone inválido</mat-error>
                        </mat-form-field><br>
                        <mat-form-field class="input-lg" *ngIf="countries?.length > 0">
                            <mat-label>Nacionalidade * <mat-icon
                                    *ngIf="personalInfoForm.value.citizenship != null">push_pin</mat-icon>
                            </mat-label>
                            <input matInput #countryInput type="search" placeholder="Pesquise seu país de origem"
                                [matAutocomplete]="autoCountries" (input)="filterCountries(countryInput.value)"
                                [readonly]="personalInfoForm.value.citizenship != null">
                            <button mat-icon-button matSuffix (click)="selectCountry(null);"
                                *ngIf="countryInput.value.length > 0" class="btn-remove" matTooltip="Remover">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-autocomplete #autoCountries="matAutocomplete">
                                <mat-option *ngFor="let country of filteredCountries" [value]="country?.nome"
                                    (onSelectionChange)="selectCountry(country?.nome)">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <mat-icon>public</mat-icon>
                                        <div fxLayout="column" fxLayoutAlign="start start">
                                            <span class="address-info">
                                                <small>{{ country.nome }}</small>
                                            </span>
                                            <span class="address-other-info">
                                                <small>País</small>
                                            </span>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field class="input-lg" *ngIf="countries == null">
                            <mat-label>Nacionalidade</mat-label>
                            <input matInput placeholder="Insira sua nacionalidade" formControlName="citizenship"
                                maxlength="40">
                            <mat-hint align="end">{{ personalInfoForm.value.citizenship?.length ?? 0 }} / 40</mat-hint>
                            <mat-error>Nacionalidade inválida</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-sm">
                            <mat-label>CPF (Não editável)</mat-label>
                            <input matInput placeholder="Insira seu CPF" formControlName="cpf" mask="000.000.000-00"
                                readonly>
                            <mat-error>CPF inválido</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-sm">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput [max]="maxDate" [matDatepicker]="picker" formControlName="birthDate"
                                placeholder="dd/mm/aaaa">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
                            <mat-error>Data inválida</mat-error>
                        </mat-form-field>
                        <mat-form-field class="input-sm">
                            <mat-label>Sexo</mat-label>
                            <mat-select formControlName="gender" placeholder="Selecione">
                                <mat-option *ngFor="let gender of genders" [value]="gender.code">
                                    {{ gender.description }}
                                </mat-option>
                            </mat-select>
                            <mat-error>Sexo inválido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="picture-container" fxLayout="column" fxLayoutAlign="start end">
                        <a (click)="dialog.click()" matTooltip="Clique para alterar a foto (opcional)"
                            DragAndDropDirective (fileDropped)="uploadPicture($event)" class="drop-area">
                            <img [class]="personalInfoForm.value.picture != null ? 'profile-picture border' : 'placeholder border'"
                                [src]="personalInfoForm.value.picture ?? 'assets/img/camera.png'">
                        </a>
                        <button mat-button color="warn" class="full-container" (click)="removePicture()"
                            *ngIf="personalInfoForm.value.picture != null">Remover</button>
                    </div>
                </div>

                <div class="picture-container-mobile">
                    <a (click)="dialog.click()" matTooltip="Clique para alterar a foto">
                        <img [class]="personalInfoForm.value.picture != null ? 'profile-picture border' : 'placeholder border'"
                            [src]="personalInfoForm.value.picture ?? 'assets/img/camera.png'">
                    </a>
                    <small>Insira uma imagem (opcional)</small>
                </div>
                <mat-divider></mat-divider><br>
                <mat-form-field class="input-sm">
                    <mat-label>UF</mat-label>
                    <mat-select formControlName="state" placeholder="Selecione" (selectionChange)="searchCities()">
                        <mat-option *ngFor="let state of states" [value]="state.code">
                            {{ state.description }}
                        </mat-option>
                    </mat-select>
                    <mat-error>UF inválida</mat-error>
                </mat-form-field>
                <mat-form-field class="input-lg" *ngIf="cities?.length > 0">
                    <mat-label>Cidade * <mat-icon *ngIf="personalInfoForm.value.city != null">push_pin</mat-icon>
                    </mat-label>
                    <input matInput #cityInput type="search" placeholder="Pesquise sua cidade"
                        [matAutocomplete]="autoCities" (input)="filterCities(cityInput.value)"
                        [readonly]="personalInfoForm.value.city != null">
                    <button mat-icon-button matSuffix (click)="selectCity(null);" *ngIf="cityInput.value.length > 0"
                        class="btn-remove" matTooltip="Remover">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete #autoCities="matAutocomplete">
                        <mat-option *ngFor="let city of filteredCities" [value]="city?.nome"
                            (onSelectionChange)="selectCity(city?.nome)">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <mat-icon>place</mat-icon>
                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <span class="address-info">
                                        <small>{{ city.nome }}</small>
                                    </span>
                                    <span class="address-other-info">
                                        <small>Municipio</small>
                                    </span>
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="input-lg" *ngIf="cities == null">
                    <mat-label>Cidade</mat-label>
                    <input matInput formControlName="city" placeholder="Insira a cidade" maxlength="200">
                    <mat-hint align="end">{{ personalInfoForm.value.city?.length ?? 0 }} / 200</mat-hint>
                    <mat-error>Cidade inválida</mat-error>
                </mat-form-field>
                <mat-form-field class="input-lg" *ngIf="personalInfoForm.value.city != null">
                    <mat-label>Bairro (opcional)</mat-label>
                    <input matInput formControlName="neighbourhood" placeholder="Insira o bairro" maxlength="80">
                    <mat-hint align="end">{{ personalInfoForm.value.neighbourhood?.length ?? 0 }} / 80</mat-hint>
                    <mat-error>Bairro inválido</mat-error>
                </mat-form-field><br>
                <mat-form-field class="input-lg" *ngIf="personalInfoForm.value.neighbourhood?.length > 0">
                    <mat-label>Rua (opcional)</mat-label>
                    <input matInput formControlName="street" placeholder="Insira a rua" maxlength="80">
                    <mat-hint align="end">{{ personalInfoForm.value.street?.length ?? 0 }} / 80</mat-hint>
                    <mat-error>Rua inválida</mat-error>
                </mat-form-field>
                <mat-form-field class="input-md" *ngIf="personalInfoForm.value.street?.length > 0">
                    <mat-label>Complemento (opcional)</mat-label>
                    <input matInput formControlName="addressComplement" placeholder="Insira o complemento"
                        maxlength="20">
                    <mat-hint align="end">{{ personalInfoForm.value.addressComplement?.length ?? 0 }} / 20</mat-hint>
                    <mat-error>Complemento inválido</mat-error>
                </mat-form-field>
                <mat-divider></mat-divider>
                <br>
                <span>Você se identifica como uma Pessoa com Deficiência (PcD)?</span>
                <mat-radio-group formControlName="hasDisability">
                    <mat-radio-button color="primary" [value]="true">Sim</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">Não</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="input-md" *ngIf="personalInfoForm.value.hasDisability">
                    <mat-label>Qual tipo de deficiência?</mat-label>
                    <mat-select formControlName="disabilityType" placeholder="Selecione">
                        <mat-option *ngFor="let disabilityType of disabilityTypes" [value]="disabilityType.code">
                            {{ disabilityType.description }}
                        </mat-option>
                    </mat-select>
                    <mat-error>Deficiência inválida</mat-error>
                </mat-form-field>
                <p>
                    <button mat-raised-button color="primary" matStepperNext>Avançar</button>
                </p>
            </form>
            <input hidden type="file" #dialog (change)="uploadPicture($event)" accept=".png, .jpg, .jpeg">
        </mat-step>
        <mat-step [stepControl]="professionalExperienceForm" label="Experiência Profissional" state="work">
            <form [formGroup]="professionalExperienceForm">
                <br>
                <span>Este é seu primeiro emprego?</span>
                <mat-radio-group formControlName="hasProfessionalExperience">
                    <mat-radio-button color="primary" [value]="false">Sim</mat-radio-button>
                    <mat-radio-button color="primary" [value]="true">Não</mat-radio-button>
                </mat-radio-group>
                <div fxLayout="column" fxLayoutAlign="start start"
                    *ngIf="professionalExperienceForm.value.hasProfessionalExperience == true">
                    <span>Em quantas empresas você já trabalhou?</span><br>
                    <mat-form-field class="input-sm">
                        <mat-label>Quantidade</mat-label>
                        <input matInput placeholder="Insira a quantidade" formControlName="workedCompaniesCount"
                            type="text" mask="separator.0">
                        <mat-error>Quantidade inválida</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="professionalExperienceForm.value.hasProfessionalExperience == true">
                    <div *ngIf="professionalExperienceDataSource.data?.length >= 1">
                        <button mat-raised-button color="primary"
                            [disabled]="professionalExperienceDataSource.data?.length >= 5"
                            (click)="addProfessionalExperience()">Adicionar</button>
                        <br><br>
                    </div>
                    <div class="mat-elevation-z4 mat-table" *ngIf="professionalExperienceDataSource.data?.length > 0">
                        <table mat-table [dataSource]="professionalExperienceDataSource">
                            <ng-container matColumnDef="employer">
                                <th mat-header-cell *matHeaderCellDef> Empresa </th>
                                <td mat-cell *matCellDef="let element"> {{ element.employer }} </td>
                            </ng-container>

                            <ng-container matColumnDef="period">
                                <th mat-header-cell *matHeaderCellDef> Período </th>
                                <td mat-cell *matCellDef="let element"> {{ element.startDate | date:"MM/yyyy" }} -
                                    <span *ngIf="element.endDate != null">{{ element.endDate | date:"MM/yyyy" }}</span>
                                    <span *ngIf="element.endDate == null">Atualmente</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="jobOccupation">
                                <th mat-header-cell *matHeaderCellDef> Função </th>
                                <td mat-cell *matCellDef="let element"> {{ element.jobOccupation?.description }} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" align="right">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editProfessionalExperience(element)">
                                            <mat-icon>create</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteProfessionalExperience(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Excluir</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="professionalExperienceDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: professionalExperienceDisplayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Avançar</button>
                </p>
            </form>
        </mat-step>
        <mat-step [stepControl]="educationForm" label="Formação" state="school">
            <form [formGroup]="educationForm">
                <br>
                <span>Você foi alfabetizado?</span>
                <mat-radio-group formControlName="hasEducation">
                    <mat-radio-button color="primary" [value]="true">Sim</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">Não</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="educationForm.value.hasEducation == true">
                    <div *ngIf="educationDataSource.data?.length >= 1">
                        <button mat-raised-button color="primary" [disabled]="educationDataSource.data?.length >= 5"
                            (click)="addEducation()">Adicionar</button>
                        <br><br>
                    </div>
                    <div class="mat-elevation-z4 mat-table" *ngIf="educationDataSource.data?.length > 0">
                        <table mat-table [dataSource]="educationDataSource">
                            <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef> Nível de Ensino </th>
                                <td mat-cell *matCellDef="let element"> {{ getEducationLevel(element.level) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="institution">
                                <th mat-header-cell *matHeaderCellDef> Instituição </th>
                                <td mat-cell *matCellDef="let element"> {{ element.institution }} </td>
                            </ng-container>

                            <ng-container matColumnDef="period">
                                <th mat-header-cell *matHeaderCellDef> Período </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.startDate | date:"MM/yyyy" }} - {{ element.endDate | date:"MM/yyyy" }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" align="right">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editEducation(element)">
                                            <mat-icon>create</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteEducation(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Excluir</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="educationDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: educationDisplayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Avançar</button>
                </p>
            </form>
        </mat-step>
        <mat-step [stepControl]="qualificationsForm" label="Qualificações" state="qualification">
            <form [formGroup]="qualificationsForm">
                <br>
                <span>Você possui alguma qualificação complementar?</span>
                <mat-radio-group formControlName="hasQualification">
                    <mat-radio-button color="primary" [value]="true">Sim</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">Não</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="qualificationsForm.value.hasQualification == true">
                    <div *ngIf="qualificationsDataSource.data?.length >= 1">
                        <button mat-raised-button color="primary"
                            [disabled]="qualificationsDataSource.data?.length >= 5"
                            (click)="addQualification()">Adicionar</button>
                        <br><br>
                    </div>
                    <div class="mat-elevation-z4 mat-table" *ngIf="qualificationsDataSource.data?.length > 0">
                        <table mat-table [dataSource]="qualificationsDataSource">
                            <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                <td mat-cell *matCellDef="let element"> {{ getQualificationLevel(element.level) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="institution">
                                <th mat-header-cell *matHeaderCellDef> Instituição </th>
                                <td mat-cell *matCellDef="let element"> {{ element.institution }} </td>
                            </ng-container>

                            <ng-container matColumnDef="period">
                                <th mat-header-cell *matHeaderCellDef> Período </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.startDate | date:"MM/yyyy" }} - {{ element.endDate | date:"MM/yyyy" }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" align="right">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editQualification(element)">
                                            <mat-icon>create</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteQualification(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Excluir</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="qualificationsDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: qualificationsDisplayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Avançar</button>
                </p>
            </form>
        </mat-step>
        <mat-step [stepControl]="languagesForm" label="Idiomas" state="language">
            <form [formGroup]="languagesForm">
                <br>
                <span>Você possui conhecimento de algum outro idioma?</span>
                <mat-radio-group formControlName="hasLanguage">
                    <mat-radio-button color="primary" [value]="true">Sim</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">Não</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="languagesForm.value.hasLanguage == true">
                    <div *ngIf="languagesDataSource.data?.length >= 1">
                        <button mat-raised-button color="primary" [disabled]="languagesDataSource.data?.length >= 5"
                            (click)="addLanguage()">Adicionar</button>
                        <br><br>
                    </div>
                    <div class="mat-elevation-z4 mat-table" *ngIf="languagesDataSource.data?.length > 0">
                        <table mat-table [dataSource]="languagesDataSource">
                            <ng-container matColumnDef="language">
                                <th mat-header-cell *matHeaderCellDef> Idioma </th>
                                <td mat-cell *matCellDef="let element"> {{ element.language?.description }} </td>
                            </ng-container>

                            <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef> Nível de Proficiência </th>
                                <td mat-cell *matCellDef="let element"> {{ getLanguageLevel(element.level) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" align="right">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editLanguage(element)">
                                            <mat-icon>create</mat-icon>
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteLanguage(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Excluir</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="languagesDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: languagesDisplayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext>Avançar</button>
                </p>
            </form>
        </mat-step>
        <mat-step [stepControl]="interestAreasForm" label="Interesses" state="interest">
            <form [formGroup]="interestAreasForm">
                <br>
                <label>Insira suas áreas de interesse</label><br><br>
                <div>
                    <button mat-raised-button color="primary" [disabled]="interestAreasDataSource.data?.length >= 3"
                        (click)="addInterestArea()">Adicionar</button>
                    <br><br>
                </div>
                <div class="mat-elevation-z4 mat-table" *ngIf="interestAreasDataSource.data?.length > 0">
                    <table mat-table [dataSource]="interestAreasDataSource">
                        <ng-container matColumnDef="interestArea">
                            <th mat-header-cell *matHeaderCellDef> Área de Interesse </th>
                            <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" align="right">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="deleteInterestArea(element)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Excluir</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="interestAreasDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: interestAreasDisplayedColumns;"></tr>
                    </table>
                </div>
                <br>
                <div *ngIf="interestAreasDataSource.data?.length > 0">
                    <span>Interesse é para estágio?</span>
                    <mat-radio-group formControlName="isInternship">
                        <mat-radio-button color="primary" [value]="true">Sim</mat-radio-button>
                        <mat-radio-button color="primary" [value]="false">Não</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-divider></mat-divider>
                <br>
                <mat-form-field class="full-container">
                    <mat-label>Informações adicionais (opcional)</mat-label>
                    <textarea matInput placeholder="Caso desejar, insira alguma informação adicional"
                        formControlName="additionalInformation" rows="7" maxlength="500"></textarea>
                    <mat-hint align="end">{{ interestAreasForm.value.additionalInformation?.length ?? 0 }} / 500</mat-hint>
                </mat-form-field>
                <p>
                    <small>Complete o CAPTCHA para continuar</small>
                </p>
                <ngx-recaptcha2 *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
                    size="normal" hl="pt" theme="light" type="image" formControlName="reCaptcha">
                </ngx-recaptcha2>
                <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious>Voltar</button>
                    <button mat-raised-button color="primary" (click)="onSubmit()">Finalizar</button>
                </p>
            </form>
        </mat-step>

        <ng-template matStepperIcon="person">
            <mat-icon>person</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="work">
            <mat-icon>business_center</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="school">
            <mat-icon>school</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="qualification">
            <mat-icon>workspace_premium</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="language">
            <mat-icon>translate</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="interest">
            <mat-icon>flag</mat-icon>
        </ng-template>
    </mat-stepper>
</div>