<h1 mat-dialog-title>Novo usuário</h1>
<mat-dialog-content [formGroup]="form" class="full-container">
    <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Nome" matInput formControlName="name">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" matInput formControlName="email" placeholder="Ex. pat@example.com">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select class="search-input" formControlName="type" placeholder="SELECIONAR...">
            <mat-option [value]="type" *ngFor="let type of UserTypes">
                <label *ngIf="type == 'F'">Funcionário</label>
                <label *ngIf="type == 'R'">Representante</label>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Senha</mat-label>
        <input matInput type="password" matInput formControlName="password">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Confirmar Senha</mat-label>
        <input matInput matInput type="password" matInput formControlName="confirmPassword">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>