<div fxLayout="row" fxLayoutAlign="space-between start">
  <label mat-dialog-title>Paletização Resumida</label>
  <button mat-button (click)="close()">Fechar</button>
</div>
<div class="mat-elevation-z8 mat-table">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Pallet Fechado </th>
      <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Cx / Pallet </th>
      <td mat-cell *matCellDef="let element"> {{ element.quantity }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'cell-highlight': row.quantity == null }"></tr>
  </table>
</div>