import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { JobApplicationQualification } from 'src/app/models/job-application-qualification';
import { NotificationService } from 'src/app/services/notification.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-add-qualification',
    templateUrl: './add-qualification.component.html',
    styleUrls: ['./add-qualification.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS
    }]
})

export class AddQualificationComponent implements OnInit {
    form: UntypedFormGroup;
    qualificationLevels: string[];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AddQualificationComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.qualificationLevels = this.data?.qualificationLevels ?? [];

        this.form = new UntypedFormGroup({
            institution: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            level: new UntypedFormControl(null, [Validators.required]),
            description: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            startDate: new UntypedFormControl(null, Validators.required),
            endDate: new UntypedFormControl(null, [Validators.required])
        });

        let qualification = this.data?.qualification as JobApplicationQualification;

        if (qualification != null) {
            this.form.patchValue({
                institution: qualification.institution, level: qualification.level,
                description: qualification.description, startDate: qualification.startDate,
                endDate: qualification.endDate,
            });
        }
    }

    save() {
        if (this.form.invalid) {
            this.notificationService.warning('Há campos obrigatórios não preenchidos')
            return;
        }

        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }

    setMonthAndYear(normalizedMonthAndYear: Moment, field: string, datepicker: MatDatepicker<Moment>) {
        this.form.patchValue({ [field]: new Date(normalizedMonthAndYear.year(), normalizedMonthAndYear.month(), 1) })
        datepicker.close();
    }
}
