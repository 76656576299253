<div fxLayout="row" fxLayoutAlign="space-between center">
  <h1 mat-dialog-title>{{cargo.description}}</h1>
  <button mat-button aria-label="send" (click)="send()" matTooltip="Envia uma solicitação." [disabled]="loading">
    Enviar
    <mat-icon>send</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-card>
    <mat-card-title>Total da carga: {{ cargo.totalWeightWithoutBlocked | number }}</mat-card-title>
    <br>
    <mat-card-content [formGroup]="form">
      <p fxLayoutGap="10px">
        <mat-form-field class="half-container">
          <mat-label>E-mail</mat-label>
          <input type="text" matInput placeholder="Ex. pat@example.com" formControlName="email">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Pallets</mat-label>
          <input matInput type="number" formControlName="palletsCount">
        </mat-form-field>
        <mat-form-field class="full-container">
          <mat-label>Comentários</mat-label>
          <textarea matInput placeholder="Digite algo..." formControlName="comments" #comments
            maxlength="200"></textarea>
          <mat-hint align="end">{{comments.value.length}} / 200</mat-hint>
        </mat-form-field>
      </p>

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let element"> {{element.item}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef> Quantidade </th>
          <td mat-cell *matCellDef="let element"> {{element.quantity | number}} </td>
        </ng-container>

        <ng-container matColumnDef="totalWeight">
          <th mat-header-cell *matHeaderCellDef> Peso total </th>
          <td mat-cell *matCellDef="let element"> {{element.totalWeight | number}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>{{getIcon(element)}}</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" align="right">
            <button mat-stroked-button (click)="editItem(element)">
              {{getButtonLabel(element)}}
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>