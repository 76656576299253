<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon>flag</mat-icon>
        <mat-card-title>Metas de Venda</mat-card-title>
    </div>
    <small>O que deseja visualizar?</small>
    <div class="buttons-row">
        <button mat-raised-button color="primary" routerLink="/sales/quantity" class="block-button" (click)="close()">
            <mat-icon>widgets</mat-icon>
            <br>
            <label>Quantidade</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/sales/amount" class="block-button" (click)="close()">
            <mat-icon>paid</mat-icon>
            <br>
            <label>Valor</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/sales/notes" class="block-button" (click)="close()">
            <mat-icon>edit_note</mat-icon>
            <br>
            <label>Anotações</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/sales/revision" class="block-button" (click)="close()">
            <mat-icon>history_edu</mat-icon>
            <br>
            <label>Revisões</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/sales/production-goals" class="block-button" (click)="close()">
            <mat-icon>factory</mat-icon>
            <br>
            <label>Produção</label>
        </button>
    </div>
</div>