import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-profits-sharing-redirect',
    templateUrl: './profits-sharing-redirect.component.html',
    styleUrls: ['./profits-sharing-redirect.component.scss']
})

export class ProfitsSharingRedirectComponent {

    constructor(private dialogRef: MatDialogRef<ProfitsSharingRedirectComponent>) { }

    close() {
        this.dialogRef.close();
    }
}
