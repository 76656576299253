<div class="container-content">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 class="mat-headline">
            DASHBOARD
        </h1>        
    </div>
    <mat-divider></mat-divider>
    <br>
    <div>
        <app-dashboard-logistics *ngIf="department == Translations.Departments.Logistics.ID"></app-dashboard-logistics>
        <app-dashboard-it *ngIf="department == Translations.Departments.IT.ID"></app-dashboard-it>
        <app-dashboard-finance *ngIf="department == Translations.Departments.Finance.ID"></app-dashboard-finance>
        <app-dashboard-sales *ngIf="department == Translations.Departments.Sales.ID"></app-dashboard-sales>
        <app-dashboard-salesmen *ngIf="department == Translations.Departments.Salesman.ID"></app-dashboard-salesmen>
        <app-dashboard-hr *ngIf="department == Translations.Departments.HumanResources.ID"></app-dashboard-hr>
    </div>
</div>