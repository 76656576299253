import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobApplicationLanguage } from 'src/app/models/job-application-language';
import { Language } from 'src/app/models/language';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-add-language',
    templateUrl: './add-language.component.html',
    styleUrls: ['./add-language.component.scss']
})

export class AddLanguageComponent implements OnInit {
    form: UntypedFormGroup;
    languages: Language[];
    languageLevels: string[];
    editMode: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AddLanguageComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.languages = this.data?.languages ?? [];
        this.languageLevels = this.data?.languageLevels ?? [];

        this.form = new UntypedFormGroup({
            language: new UntypedFormControl(null, [Validators.required]),
            level: new UntypedFormControl(null, [Validators.required]),
        });

        let language = this.data?.language as JobApplicationLanguage;

        if (language != null) {
            let selectedLanguage = this.languages.find(l => l.id == language.language?.id);            
            this.form.patchValue({ language: selectedLanguage, level: language.level });
            
            this.editMode = true;
        }
    }

    save() {
        if (this.form.invalid) {
            this.notificationService.warning('Há campos obrigatórios não preenchidos')
            return;
        }

        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }
}
