<h1 mat-dialog-title>Experiência Profissional</h1>
<mat-dialog-content [formGroup]="form">
    <mat-form-field class="full-container">
        <mat-label>Empresa</mat-label>
        <input matInput placeholder="Insira a empresa" formControlName="employer" maxlength="70">
        <mat-hint align="end">{{ form.value.employer?.length ?? 0 }} / 70</mat-hint>
        <mat-error>Empresa inválida</mat-error>
    </mat-form-field>
    <mat-form-field class="full-container">
        <mat-label>Função <mat-icon *ngIf="form.value.jobOccupation != null">push_pin</mat-icon>
        </mat-label>
        <input matInput #jobOccupationInput type="search" placeholder="Pesquise a função"
            [matAutocomplete]="autoJobOccupations" (input)="filterJobOccupations(jobOccupationInput.value)"
            [readonly]="form.value.jobOccupation != null">
        <button mat-icon-button matSuffix (click)="selectJobOccupation(null);"
            *ngIf="jobOccupationInput.value.length > 0" class="btn-remove" matTooltip="Remover">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #autoJobOccupations="matAutocomplete">
            <mat-option *ngFor="let jobOccupation of filteredOccupations" [value]="jobOccupation?.description"
                (onSelectionChange)="selectJobOccupation(jobOccupation)">
                <small>CBO: {{ jobOccupation.id }} | {{ jobOccupation.description }}</small>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="full-container">
        <mat-label>Descreva sua experiência</mat-label>
        <textarea matInput placeholder="Descreva sua experiência na função" formControlName="description" rows="10"
            maxlength="300"></textarea>
        <mat-hint align="end">{{ form.value.description?.length ?? 0 }} / 300</mat-hint>
        <mat-error>Descrição inválida</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="currentlyWorksThere" color="primary" style="margin-left: 10px">Atualmente trabalho
        aqui</mat-checkbox>
    <br><br>
    <mat-form-field class="date-field">
        <mat-label>Data de início</mat-label>
        <input matInput [max]="maxDate" [matDatepicker]="startDatePicker" formControlName="startDate"
            placeholder="mm/aaaa" maxlength="7" readonly>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" (monthSelected)="setMonthAndYear($event, 'startDate', startDatePicker)"
            #startDatePicker></mat-datepicker>
        <mat-error>Data inválida</mat-error>
    </mat-form-field>
    <mat-form-field class="date-field" [ngClass]="{'readonly': form.value.currentlyWorksThere == true}">
        <mat-label>Data de fim</mat-label>
        <input matInput [min]="form.value.startDate" [max]="maxDate" [matDatepicker]="endDatePicker"
            formControlName="endDate" maxlength="7" readonly
            [placeholder]="form.value.currentlyWorksThere == false ? 'mm/aaaa' : '--/----'"
            [disabled]="form.value.currentlyWorksThere == true">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"
            [disabled]="form.value.currentlyWorksThere == true"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" (monthSelected)="setMonthAndYear($event, 'endDate', endDatePicker)"
            #endDatePicker></mat-datepicker>
        <mat-error>Data inválida</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>