import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ExternalService } from 'src/app/services/external.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AddProfessionalExperienceComponent } from './add-professional-experience/add-professional-experience.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CropImageComponent } from 'src/app/helpers/crop-image/crop-image.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/helpers/confirm-dialog/confirm-dialog.component';
import { JobOccupation } from 'src/app/models/job-occupation';
import { HumanResourcesService } from 'src/app/services/human-resources.service';
import { JobApplicationExperience } from 'src/app/models/job-application-experience';
import { forkJoin } from 'rxjs';
import { Language } from 'src/app/models/language';
import { InterestArea } from 'src/app/models/interest-area';
import { JobApplicationEducation } from 'src/app/models/job-application-education';
import { AddEducationComponent } from './add-education/add-education.component';
import { JobApplicationLanguage } from 'src/app/models/job-application-language';
import { JobApplicationQualification } from 'src/app/models/job-application-qualification';
import { AddQualificationComponent } from './add-qualification/add-qualification.component';
import { AddLanguageComponent } from './add-language/add-language.component';
import { AddInterestComponent } from './add-interest/add-interest.component';
import { VerifyExistingJobApplicationComponent } from './verify-existing-job-application/verify-existing-job-application.component';
import { TermsComponent } from './terms/terms.component';
import { Extensions } from 'src/app/helpers/extensions';
import { JobApplication } from 'src/app/models/job-application';

@Component({
    selector: 'app-job-application',
    templateUrl: './job-application.component.html',
    styleUrls: ['./job-application.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false },
    }]
})

export class JobApplicationComponent implements OnInit {
    personalInfoForm: UntypedFormGroup;
    professionalExperienceForm: UntypedFormGroup;
    educationForm: UntypedFormGroup;
    qualificationsForm: UntypedFormGroup;
    languagesForm: UntypedFormGroup;
    interestAreasForm: UntypedFormGroup;
    jobOccupations = new MatTableDataSource<JobOccupation>();
    languages = new MatTableDataSource<Language>();
    interestAreas = new MatTableDataSource<InterestArea>();
    maxDate: Date = new Date();
    states: any[] = [
        { description: 'Acre', code: 'AC' }, { description: 'Alagoas', code: 'AL' }, { description: 'Amapá', code: 'AP' },
        { description: 'Amazonas', code: 'AM' }, { description: 'Bahia', code: 'BA' }, { description: 'Ceará', code: 'CE' },
        { description: 'Espírito Santo', code: 'ES' }, { description: 'Goiás', code: 'GO' }, { description: 'Maranhão', code: 'MA' },
        { description: 'Mato Grosso', code: 'MT' }, { description: 'Mato Grosso do Sul', code: 'MS' }, { description: 'Minas Gerais', code: 'MG' },
        { description: 'Pará', code: 'PA' }, { description: 'Paraíba', code: 'PB' }, { description: 'Paraná', code: 'PR' },
        { description: 'Pernambuco', code: 'PE' }, { description: 'Piauí', code: 'PI' }, { description: 'Rio de Janeiro', code: 'RJ' },
        { description: 'Rio Grande do Norte', code: 'RN' }, { description: 'Rio Grande do Sul', code: 'RS' }, { description: 'Rondônia', code: 'RO' },
        { description: 'Roraima', code: 'RR' }, { description: 'Santa Catarina', code: 'SC' }, { description: 'São Paulo', code: 'SP' },
        { description: 'Sergipe', code: 'SE' }, { description: 'Tocantins', code: 'TO' },
    ];
    countries: any[] = [];
    filteredCountries: any[] = [];
    cities: any[] = [];
    filteredCities: any[] = [];
    disabilityTypes: any = [
        { description: 'Física', code: 'DF' },
        { description: 'Visual', code: 'DV' },
        { description: 'Auditiva', code: 'DA' },
        { description: 'Intelectual', code: 'DI' },
        { description: 'Psicossocial', code: 'DP' },
        { description: 'Múltipla', code: 'DM' },
    ];
    genders: any[] = [
        { description: 'Feminino', code: 'F' },
        { description: 'Masculino', code: 'M' },
        { description: 'Prefiro não responder', code: '-' }
    ];
    professionalExperienceDataSource = new MatTableDataSource<JobApplicationExperience>();
    professionalExperienceDisplayedColumns: string[] = ['employer', 'period', 'jobOccupation', 'actions'];
    educationDataSource = new MatTableDataSource<JobApplicationEducation>();
    educationDisplayedColumns: string[] = ['level', 'institution', 'period', 'actions'];
    qualificationsDataSource = new MatTableDataSource<JobApplicationQualification>();
    qualificationsDisplayedColumns: string[] = ['level', 'institution', 'period', 'actions'];
    languagesDataSource = new MatTableDataSource<JobApplicationLanguage>();
    languagesDisplayedColumns: string[] = ['language', 'level', 'actions'];
    interestAreasDataSource = new MatTableDataSource<InterestArea>();
    interestAreasDisplayedColumns: string[] = ['interestArea', 'actions'];
    educationLevels: any[] = [
        { description: 'Ensino Fundamental Incompleto', code: 'EFI', preAssignedCourse: 'Ensino Fundamental' },
        { description: 'Ensino Fundamental Completo', code: 'EFC', preAssignedCourse: 'Ensino Fundamental' },
        { description: 'Ensino Médio Incompleto', code: 'EMI', preAssignedCourse: 'Ensino Médio' },
        { description: 'Ensino Médio Completo', code: 'EMC', preAssignedCourse: 'Ensino Médio' },
        { description: 'Graduação Incompleta', code: 'GI', preAssignedCourse: null },
        { description: 'Graduação Completa', code: 'GC', preAssignedCourse: null },
        { description: 'Pós-Graduação (MBA) Incompleta', code: 'PGI', preAssignedCourse: null },
        { description: 'Pós-Graduação (MBA) Completa', code: 'PGC', preAssignedCourse: null },
        { description: 'Mestrado Incompleto', code: 'MI', preAssignedCourse: null },
        { description: 'Mestrado Completo', code: 'MC', preAssignedCourse: null },
        { description: 'Doutorado Incompleto', code: 'DI', preAssignedCourse: null },
        { description: 'Doutorado Completo', code: 'DC', preAssignedCourse: null },
    ];
    qualificationLevels: any[] = [
        { description: 'Curso Técnico', code: 'TEC' },
        { description: 'Extensão', code: 'EXT' },
        { description: 'Pesquisa', code: 'PES' },
        { description: 'Intercâmbio', code: 'INT' },
        { description: 'Especialização', code: 'ESP' },
        { description: 'Outro', code: 'O' },
    ];
    languageLevels: any[] = [
        { description: 'Iniciante', code: 'C' },
        { description: 'Intermediário', code: 'B' },
        { description: 'Fluente', code: 'A' },
    ];
    siteKey = environment.siteKey;
    showCaptcha: boolean = true;
    updateToken?: string;

    @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
    @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;

    constructor(private humanResourcesService: HumanResourcesService, private notificationService: NotificationService, private externalService: ExternalService, private dialog: MatDialog, private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.updateToken = this.route.snapshot.queryParams['token'];
        this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

        this.personalInfoForm = new UntypedFormGroup({
            name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            cpf: new UntypedFormControl(null, [Validators.required]),
            birthDate: new UntypedFormControl(null, [Validators.required]),
            citizenship: new UntypedFormControl(null, [Validators.required, Validators.maxLength(40)]),
            state: new UntypedFormControl(null, [Validators.required]),
            city: new UntypedFormControl(null, [Validators.required, Validators.maxLength(200)]),
            neighbourhood: new UntypedFormControl(null, [Validators.maxLength(80)]),
            street: new UntypedFormControl(null, [Validators.maxLength(80)]),
            addressComplement: new UntypedFormControl(null, [Validators.maxLength(20)]),
            gender: new UntypedFormControl(null, [Validators.required, Validators.maxLength(1)]),
            email: new UntypedFormControl(null, [Validators.required, Validators.email, Validators.maxLength(70)]),
            phoneNumber: new UntypedFormControl(null, [Validators.required]),
            hasDisability: new UntypedFormControl(null, [Validators.required]),
            disabilityType: new UntypedFormControl(null),
            picture: new UntypedFormControl(null)
        });

        this.professionalExperienceForm = new UntypedFormGroup({
            hasProfessionalExperience: new UntypedFormControl(null, [Validators.required]),
            workedCompaniesCount: new UntypedFormControl(null)
        });

        this.educationForm = new UntypedFormGroup({
            hasEducation: new UntypedFormControl(null, [Validators.required])
        });

        this.qualificationsForm = new UntypedFormGroup({
            hasQualification: new UntypedFormControl(null, [Validators.required])
        });

        this.languagesForm = new UntypedFormGroup({
            hasLanguage: new UntypedFormControl(null, [Validators.required])
        });

        this.interestAreasForm = new UntypedFormGroup({
            isInternship: new UntypedFormControl(false, [Validators.required]),
            additionalInformation: new UntypedFormControl(null, [Validators.maxLength(500)]),
            reCaptcha: new UntypedFormControl(null, [Validators.required])
        });

        this.personalInfoForm.controls.hasDisability.valueChanges.subscribe(hasDisability => {
            this.updateValidators(this.personalInfoForm, 'disabilityType', hasDisability);
        });

        this.professionalExperienceForm.controls.hasProfessionalExperience.valueChanges.subscribe(hasProfessionalExperience => {
            if (hasProfessionalExperience && this.professionalExperienceDataSource.data?.length <= 0) {
                this.addProfessionalExperience();
            }
        });

        this.educationForm.controls.hasEducation.valueChanges.subscribe(hasEducation => {
            if (hasEducation && this.educationDataSource.data?.length <= 0) {
                this.addEducation();
            }
        });

        this.qualificationsForm.controls.hasQualification.valueChanges.subscribe(hasQualification => {
            if (hasQualification && this.qualificationsDataSource.data?.length <= 0) {
                this.addQualification();
            }
        });

        this.languagesForm.controls.hasLanguage.valueChanges.subscribe(hasLanguage => {
            if (hasLanguage && this.languagesDataSource.data?.length <= 0) {
                this.addLanguage();
            }
        });

        if (this.updateToken != null) {
            this.humanResourcesService.getJobApplicationByToken({ token: this.updateToken }).subscribe({
                next: jobApplication => {
                    forkJoin([
                        this.humanResourcesService.getJobOccupations(),
                        this.humanResourcesService.getLanguages(),
                        this.humanResourcesService.getInterestAreas({ activeOnly: true }),
                        this.externalService.getCountries(),
                        this.externalService.getCities(jobApplication.state)
                    ]).subscribe({
                        next: data => {
                            this.jobOccupations.data = data[0];
                            this.languages.data = data[1];
                            this.interestAreas.data = data[2];
                            this.countries = data[3];
                            this.filteredCountries = data[3];
                            this.cities = data[4];
                            this.filteredCities = data[4];

                            this.fillForms(jobApplication);
                        },
                        error: error => {
                            this.notificationService.error(error);
                        }
                    });
                },
                error: error => {
                    this.requestInitialInfo();

                    setTimeout(() => {
                        this.notificationService.error(error);
                    }, 100)
                }
            });
        }
        else {
            this.requestInitialInfo();
        }

        this.showTerms();
    }

    requestInitialInfo() {
        this.externalService.getCountries().subscribe({
            next: countries => {
                this.countries = countries;
                this.filteredCountries = countries;
            },
            error: () => {
                this.countries = null;
            }
        });

        forkJoin([this.humanResourcesService.getJobOccupations(), this.humanResourcesService.getLanguages(), this.humanResourcesService.getInterestAreas({ activeOnly: true })]).subscribe({
            next: data => {
                this.jobOccupations.data = data[0];
                this.languages.data = data[1];
                this.interestAreas.data = data[2];
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    showTerms() {
        const termsDialogRef = this.dialog.open(TermsComponent, { panelClass: 'dialog-extra-large', disableClose: true });

        termsDialogRef.afterClosed().subscribe(() => {
            if (this.updateToken == null) {
                const dialogRef = this.dialog.open(VerifyExistingJobApplicationComponent, { panelClass: 'dialog-large', disableClose: true });

                dialogRef.afterClosed().subscribe(cpf => {
                    if (cpf) {
                        this.personalInfoForm.patchValue({ cpf: cpf });
                    }
                });
            }
        });
    }

    searchCities() {
        this.cities = [];
        this.selectCity(null);

        this.externalService.getCities(this.personalInfoForm.value.state).subscribe({
            next: cities => {
                this.cities = cities;
                this.filteredCities = cities;
            },
            error: () => {
                this.cities = null;
            }
        });
    }

    filterCountries(searchTerm?: string) {
        var trimmedSearchTerm = searchTerm?.trim()?.toLocaleLowerCase();

        if (trimmedSearchTerm?.length <= 0) {
            this.filteredCountries = this.cities.slice(0, 20);
            return;
        }

        this.filteredCountries = this.countries.filter(c => c.nome?.toLocaleLowerCase().includes(trimmedSearchTerm)).slice(0, 20);
    }

    selectCountry(country?: string) {
        this.personalInfoForm.patchValue({ citizenship: country });

        if (this.countryInput != null) {
            this.countryInput.nativeElement.value = country ?? '';
        }
    }

    filterCities(searchTerm?: string) {
        var trimmedSearchTerm = searchTerm?.trim()?.toLocaleLowerCase();

        if (trimmedSearchTerm?.length <= 0) {
            this.filteredCities = this.cities.slice(0, 20);
            return;
        }

        this.filteredCities = this.cities.filter(c => c.nome?.toLocaleLowerCase().includes(trimmedSearchTerm)).slice(0, 20);
    }

    selectCity(city?: string) {
        this.personalInfoForm.patchValue({ city: city });

        if (city == null) {
            this.personalInfoForm.patchValue({ neighbourhood: null, street: null, addressComplement: null });
        }

        if (this.cityInput != null) {
            this.cityInput.nativeElement.value = city ?? '';
        }
    }

    uploadPicture(e) {
        let file: File = e.target.files[0];

        if (file == null) {
            return;
        }

        if (file && !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
            this.notificationService.error('Tipo de arquivo não compatível.');
            return;
        }

        const dialogRef = this.dialog.open(CropImageComponent, { panelClass: "dialog-large", disableClose: true, data: { image: e, returnCroppedImage: true } });

        dialogRef.afterClosed().subscribe(picture => {
            if (picture) {
                this.personalInfoForm.patchValue({ picture: picture });
                this.notificationService.sucess('Imagem atualizada.');
            }
        });
    }

    removePicture() {
        this.personalInfoForm.patchValue({ picture: null });
        this.notificationService.sucess('Imagem removida.');
    }

    addProfessionalExperience() {
        if (this.professionalExperienceDataSource.data?.length >= 5) {
            this.notificationService.warning('Só é possível inserir 5 experiências.');
            return;
        }

        const dialogRef = this.dialog.open(AddProfessionalExperienceComponent, { panelClass: 'dialog-extra-large', data: { jobOccupations: this.jobOccupations.data } });

        dialogRef.afterClosed().subscribe((experience: JobApplicationExperience) => {
            if (experience != null) {
                this.professionalExperienceDataSource.data.push(experience);
                this.professionalExperienceDataSource.data = this.professionalExperienceDataSource.data;
                this.updateValidators(this.professionalExperienceForm, 'workedCompaniesCount', true);
            }
            else if (this.professionalExperienceDataSource.data?.length <= 0) {
                this.professionalExperienceForm.patchValue({ hasProfessionalExperience: false });
                this.updateValidators(this.professionalExperienceForm, 'workedCompaniesCount', false);
            }
        });
    }

    editProfessionalExperience(jobExperience: JobApplicationExperience) {
        const dialogRef = this.dialog.open(AddProfessionalExperienceComponent, {
            panelClass: 'dialog-extra-large', data: { jobExperience: jobExperience, jobOccupations: this.jobOccupations.data }
        });

        dialogRef.afterClosed().subscribe((editedExperience: JobApplicationExperience) => {
            if (editedExperience != null) {
                jobExperience.employer = editedExperience.employer;
                jobExperience.jobOccupation = editedExperience.jobOccupation;
                jobExperience.description = editedExperience.description;
                jobExperience.startDate = editedExperience.startDate;
                jobExperience.endDate = editedExperience.endDate;
                jobExperience.currentlyWorksThere = editedExperience.currentlyWorksThere;

                this.professionalExperienceDataSource.data = this.professionalExperienceDataSource.data;
            }
            else if (this.professionalExperienceDataSource.data?.length <= 0) {
                this.professionalExperienceForm.patchValue({ hasProfessionalExperience: false });
            }
        });
    }

    deleteProfessionalExperience(experience: JobApplicationExperience) {
        const message = 'Deseja realmente excluir esta experiência?';
        const dialogData = new ConfirmDialogModel("Confirmação", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.professionalExperienceDataSource.data.splice(this.professionalExperienceDataSource.data.indexOf(experience), 1);
                this.professionalExperienceDataSource.data = this.professionalExperienceDataSource.data;

                if (this.professionalExperienceDataSource.data?.length <= 0) {
                    this.professionalExperienceForm.patchValue({ hasProfessionalExperience: false });
                }
            }
        });
    }

    addEducation() {
        if (this.educationDataSource.data?.length >= 5) {
            this.notificationService.warning('Só é possível inserir 5 formações.');
            return;
        }

        const dialogRef = this.dialog.open(AddEducationComponent, { panelClass: 'dialog-extra-large', data: { educationLevels: this.educationLevels } });

        dialogRef.afterClosed().subscribe((education: JobApplicationEducation) => {
            if (education != null) {
                this.educationDataSource.data.push(education);
                this.educationDataSource.data = this.educationDataSource.data;
            }
            else if (this.educationDataSource.data?.length <= 0) {
                this.educationForm.patchValue({ hasEducation: false });
            }
        });
    }

    editEducation(education: JobApplicationEducation) {
        const dialogRef = this.dialog.open(AddEducationComponent, {
            panelClass: 'dialog-extra-large', data: { education: education, educationLevels: this.educationLevels }
        });

        dialogRef.afterClosed().subscribe((editedEducation: JobApplicationEducation) => {
            if (editedEducation != null) {
                education.institution = editedEducation.institution;
                education.level = editedEducation.level;
                education.degree = editedEducation.degree;
                education.startDate = editedEducation.startDate;
                education.endDate = editedEducation.endDate;

                this.educationDataSource.data = this.educationDataSource.data;
            }
            else if (this.educationDataSource.data?.length <= 0) {
                this.educationForm.patchValue({ hasEducation: false });
            }
        });
    }

    deleteEducation(education: JobApplicationEducation) {
        const message = 'Deseja realmente excluir esta formação?';
        const dialogData = new ConfirmDialogModel("Confirmação", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.educationDataSource.data.splice(this.educationDataSource.data.indexOf(education), 1);
                this.educationDataSource.data = this.educationDataSource.data;

                if (this.educationDataSource.data?.length <= 0) {
                    this.educationForm.patchValue({ hasEducation: false });
                }
            }
        });
    }

    addQualification() {
        if (this.qualificationsDataSource.data?.length >= 5) {
            this.notificationService.warning('Só é possível inserir 5 qualificações.');
            return;
        }

        const dialogRef = this.dialog.open(AddQualificationComponent, { panelClass: 'dialog-extra-large', data: { qualificationLevels: this.qualificationLevels } });

        dialogRef.afterClosed().subscribe((qualification: JobApplicationQualification) => {
            if (qualification != null) {
                this.qualificationsDataSource.data.push(qualification);
                this.qualificationsDataSource.data = this.qualificationsDataSource.data;
            }
            else if (this.qualificationsDataSource.data?.length <= 0) {
                this.qualificationsForm.patchValue({ hasQualification: false });
            }
        });
    }

    editQualification(qualification: JobApplicationQualification) {
        const dialogRef = this.dialog.open(AddQualificationComponent, {
            panelClass: 'dialog-extra-large', data: { qualification: qualification, qualificationLevels: this.qualificationLevels }
        });

        dialogRef.afterClosed().subscribe((editedQualification: JobApplicationQualification) => {
            if (editedQualification != null) {
                qualification.institution = editedQualification.institution;
                qualification.level = editedQualification.level;
                qualification.description = editedQualification.description;
                qualification.startDate = editedQualification.startDate;
                qualification.endDate = editedQualification.endDate;

                this.qualificationsDataSource.data = this.qualificationsDataSource.data;
            }
            else if (this.qualificationsDataSource.data?.length <= 0) {
                this.qualificationsForm.patchValue({ hasQualification: false });
            }
        });
    }

    deleteQualification(qualification: JobApplicationQualification) {
        const message = 'Deseja realmente excluir esta qualificação?';
        const dialogData = new ConfirmDialogModel("Confirmação", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.qualificationsDataSource.data.splice(this.qualificationsDataSource.data.indexOf(qualification), 1);
                this.qualificationsDataSource.data = this.qualificationsDataSource.data;

                if (this.qualificationsDataSource.data?.length <= 0) {
                    this.qualificationsForm.patchValue({ hasQualification: false });
                }
            }
        });
    }

    addLanguage() {
        if (this.languagesDataSource.data?.length >= 5) {
            this.notificationService.warning('Só é possível inserir 5 idiomas.');
            return;
        }

        const dialogRef = this.dialog.open(AddLanguageComponent, {
            panelClass: 'dialog-large',
            data: { languages: this.languages.data, languageLevels: this.languageLevels }
        });

        dialogRef.afterClosed().subscribe((language: JobApplicationLanguage) => {
            if (language != null) {
                if (this.languagesDataSource.data?.find(l => l.language.id == language.language.id) != null) {
                    this.notificationService.warning('Este idioma já foi inserido');
                    return;
                }

                this.languagesDataSource.data.push(language);
                this.languagesDataSource.data = this.languagesDataSource.data;
            }
            else if (this.languagesDataSource.data?.length <= 0) {
                this.languagesForm.patchValue({ hasLanguage: false });
            }
        });
    }

    editLanguage(language: JobApplicationLanguage) {
        const dialogRef = this.dialog.open(AddLanguageComponent, {
            panelClass: 'dialog-large',
            data: { language: language, languages: this.languages.data, languageLevels: this.languageLevels }
        });

        dialogRef.afterClosed().subscribe((editedLanguage: JobApplicationLanguage) => {
            if (editedLanguage != null) {
                language.language = editedLanguage.language;
                language.level = editedLanguage.level;

                this.languagesDataSource.data = this.languagesDataSource.data;
            }
            else if (this.languagesDataSource.data?.length <= 0) {
                this.languagesForm.patchValue({ hasLanguage: false });
            }
        });
    }

    deleteLanguage(language: JobApplicationLanguage) {
        const message = 'Deseja realmente excluir este idioma?';
        const dialogData = new ConfirmDialogModel("Confirmação", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.languagesDataSource.data.splice(this.languagesDataSource.data.indexOf(language), 1);
                this.languagesDataSource.data = this.languagesDataSource.data;

                if (this.languagesDataSource.data?.length <= 0) {
                    this.languagesForm.patchValue({ hasLanguage: false });
                }
            }
        });
    }

    addInterestArea() {
        if (this.interestAreasDataSource.data?.length >= 3) {
            this.notificationService.warning('Só é possível inserir 3 áreas de interesse.');
            return;
        }

        const dialogRef = this.dialog.open(AddInterestComponent, {
            panelClass: 'dialog-large', data: { interestAreas: this.interestAreas.data }
        });

        dialogRef.afterClosed().subscribe((interestArea: InterestArea) => {
            if (interestArea != null) {
                if (this.interestAreasDataSource.data?.find(a => a.id == interestArea.id) != null) {
                    this.notificationService.warning('Esta área de interesse já foi inserida');
                    return;
                }

                this.interestAreasDataSource.data.push(interestArea);
                this.interestAreasDataSource.data = this.interestAreasDataSource.data;
            }
        });
    }

    deleteInterestArea(interestArea: InterestArea) {
        const message = 'Deseja realmente excluir esta área de interesse?';
        const dialogData = new ConfirmDialogModel("Confirmação", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.interestAreasDataSource.data.splice(this.interestAreasDataSource.data.indexOf(interestArea), 1);
                this.interestAreasDataSource.data = this.interestAreasDataSource.data;
            }
        });
    }

    onSubmit() {
        if (this.professionalExperienceForm.invalid || this.professionalExperienceForm.invalid ||
            this.qualificationsForm.invalid || this.languagesForm.invalid || this.interestAreasForm.invalid) {
            this.notificationService.warning('Existem campos inválidos.\nVerifique os formulários e tente novamente.');
            return;
        }

        if (this.interestAreasDataSource.data?.length <= 0) {
            this.notificationService.warning('No mínimo uma área de interesse é necessária.');
            return;
        }

        this.showCaptcha = false;

        setTimeout(() => {
            this.showCaptcha = true;
        });

        var jobApplication = {
            name: this.personalInfoForm.value.name,
            cpf: this.personalInfoForm.value.cpf,
            birthDate: this.personalInfoForm.value.birthDate,
            citizenship: this.personalInfoForm.value.citizenship,
            state: this.personalInfoForm.value.state,
            city: this.personalInfoForm.value.city,
            neighbourhood: this.personalInfoForm.value.neighbourhood,
            street: this.personalInfoForm.value.street,
            addressComplement: this.personalInfoForm.value.addressComplement,
            gender: this.personalInfoForm.value.gender,
            email: this.personalInfoForm.value.email,
            phoneNumber: this.personalInfoForm.value.phoneNumber,
            hasDisability: this.personalInfoForm.value.hasDisability,
            disabilityType: this.personalInfoForm.value.disabilityType,
            picture: this.personalInfoForm.value.picture,
            workedCompaniesCount: this.professionalExperienceForm.value.workedCompaniesCount,
            additionalInformation: this.interestAreasForm.value.additionalInformation,
            reCaptcha: this.interestAreasForm.value.reCaptcha,
            experiences: this.professionalExperienceDataSource.data,
            educations: this.educationDataSource.data,
            qualifications: this.qualificationsDataSource.data,
            languages: this.languagesDataSource.data,
            interestAreas: this.interestAreasDataSource.data,
            isInternship: this.interestAreasForm.value.isInternship,
            token: this.updateToken,
            origin: location.origin
        };

        if (this.updateToken != null) {

            this.humanResourcesService.updateJobApplication(jobApplication).subscribe({
                next: () => {
                    this.notificationService.sucess('Atualizado');
                    this.router.navigate(['job-application/done'], { queryParams: { type: 1 } });
                },
                error: error => {
                    this.notificationService.error(error);
                }
            });
        }
        else {
            this.humanResourcesService.createJobApplication(jobApplication).subscribe({
                next: () => {
                    this.notificationService.sucess('Enviado');
                    this.router.navigate(['job-application/done'], { queryParams: { type: 0 } });
                },
                error: error => {
                    this.notificationService.error(error);
                }
            });
        }
    }

    fillForms(jobApplication: JobApplication) {
        this.personalInfoForm.patchValue({
            name: jobApplication.name,
            cpf: jobApplication.cpf,
            birthDate: jobApplication.birthDate,
            state: jobApplication.state,
            neighbourhood: jobApplication.neighbourhood,
            street: jobApplication.street,
            addressComplement: jobApplication.addressComplement,
            city: jobApplication.city,
            gender: jobApplication.gender,
            email: jobApplication.email,
            phoneNumber: jobApplication.phoneNumber,
            hasDisability: jobApplication.hasDisability,
            disabilityType: jobApplication.disabilityType,
            picture: jobApplication.picture?.length > 0 ? Extensions.convertImage(jobApplication.picture) : null
        });

        setTimeout(() => {
            this.selectCountry(jobApplication.citizenship);
            this.selectCity(jobApplication.city);
        }, 1000);

        jobApplication.experiences.forEach(e => {
            e.currentlyWorksThere = e.endDate == null;
            e.startDate = new Date(e.startDate);
            e.endDate = e.endDate != null ? new Date(e.endDate) : null;
        });

        jobApplication.educations.forEach(e => {
            e.startDate = new Date(e.startDate);
            e.endDate = new Date(e.endDate);
        });

        jobApplication.qualifications.forEach(q => {
            q.startDate = new Date(q.startDate);
            q.endDate = new Date(q.endDate);
        });

        this.professionalExperienceDataSource.data = jobApplication.experiences;
        this.educationDataSource.data = jobApplication.educations;
        this.qualificationsDataSource.data = jobApplication.qualifications;
        this.languagesDataSource.data = jobApplication.languages;
        this.interestAreasDataSource.data = jobApplication.interestAreas[0].interestAreas;

        this.professionalExperienceForm.patchValue({
            hasProfessionalExperience: this.professionalExperienceDataSource.data?.length > 0,
            workedCompaniesCount: jobApplication.workedCompaniesCount
        });

        this.educationForm.patchValue({
            hasEducation: this.educationDataSource.data?.length > 0
        })

        this.qualificationsForm.patchValue({
            hasQualification: this.qualificationsDataSource.data?.length > 0
        });

        this.languagesForm.patchValue({
            hasLanguage: this.languagesDataSource.data?.length > 0
        });

        this.interestAreasForm.patchValue({
            isInternship: jobApplication.interestAreas[0].isInternship,
            additionalInformation: jobApplication.additionalInformation
        });
    }

    updateValidators(form: UntypedFormGroup, field: string, required: boolean) {
        if (required) {
            form.get(field).setValidators([Validators.required]);
        }
        else {
            form.patchValue({ [field]: null });
            form.get(field).clearValidators();
        }

        form.get(field).updateValueAndValidity({ emitEvent: true });
    }

    get pageLink() {
        return window.location.href;
    }

    getEducationLevel(code: string) {
        return this.educationLevels.find(l => l.code == code)?.description;
    }

    getQualificationLevel(code: string) {
        return this.qualificationLevels.find(l => l.code == code)?.description;
    }

    getLanguageLevel(code: string) {
        return this.languageLevels.find(l => l.code == code)?.description;
    }
}
