<div class="grid-container">
  <p fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>{{ departmentIcon }}</mat-icon>
    <label class="title">{{ department }}</label>
  </p>
  <div class="grid row">
    <div *ngFor="let card of cards | async" class="grid-item">
      <a [routerLink]="card.route" [queryParams]="card.routeParams">
        <mat-card class="dashboard-card dashboard-{{card.index}}" fxLayout="column">
          <div fxLayout="column">
            <label class="mat-body-2">{{ card.title }}</label>
            <label class="mat-caption">{{ card.caption }}</label>
          </div>
          <h2 class="size" class="content">{{ card.count }}
            <label class="mat-caption suffix">{{ card.suffix }}</label>
          </h2>
          <mat-icon class="main-icon">{{ card.icon }}</mat-icon>
        </mat-card>
      </a>
    </div>
  </div>
</div>