<h1 mat-dialog-title>
    Selecione o período
</h1>

<div mat-dialog-content>    
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field>
            <mat-label>De</mat-label>
            <mat-select [(value)]="startDate">
                <mat-option *ngFor="let period of periods" [value]="period.startDate">
                    {{period.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>a</mat-label>
            <mat-select [(value)]="endDate">
                <mat-option *ngFor="let period of filteredPeriods" [value]="period.endDate">
                    {{period.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" [disabled]="data?.disableClose ?? false">Cancelar</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Confirmar</button>
</div>