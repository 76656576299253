import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  siteKey = environment.siteKey;
  showCaptcha: boolean = true;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.showCaptcha = localStorage.getItem('last-auth-status') == 'F';

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      reCaptcha: new UntypedFormControl(this.showCaptcha ? null : '*', [Validators.required])
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.login(this.loginForm.value).subscribe({
      next: () => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
        localStorage.setItem('last-auth-status', 'S');
      },
      error: error => {
        this.notificationService.error(error, Number);
        this.loading = false;
        this.loginForm.patchValue({ reCaptcha: null });
        localStorage.setItem('last-auth-status', 'F');

        this.showCaptcha = false;

        setTimeout(() => {
          this.showCaptcha = true;
        });
      }
    });
  }
}