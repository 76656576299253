import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-sales-goals-redirect',
    templateUrl: './sales-goals-redirect.component.html',
    styleUrls: ['./sales-goals-redirect.component.scss']
})

export class SalesGoalsRedirectComponent {

    constructor(private dialogRef: MatDialogRef<SalesGoalsRedirectComponent>) { }

    close() {
        this.dialogRef.close();
    }
}