import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MaterialModule } from '../view-models/material.module';
import { PeriodDialogComponent } from './period-dialog/period-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { SinglePeriodDialogComponent } from './single-period-dialog/single-period-dialog.component';
import { ReopenDialogComponent } from './reopen-dialog/reopen-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EmptyResultsComponent } from './empty-results/empty-results.component';
import { CpfCnpjPipe } from './cnpj.pipe';
import { CropImageComponent } from './crop-image/crop-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DisabledFeatureComponent } from './disabled-feature/disabled-featurecomponent';
import { PhonePipe } from './phone-number.pipe';
import { DragAndDropDirective } from './drag-and-drop.directive';



@NgModule({
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent,
    PeriodDialogComponent,
    StatusChipComponent,
    SinglePeriodDialogComponent,
    ReopenDialogComponent,
    EmptyResultsComponent,
    CpfCnpjPipe,
    CropImageComponent,
    DisabledFeatureComponent,
    PhonePipe,
    DragAndDropDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatIconModule,
    ImageCropperModule
  ],
  exports: [StatusChipComponent, EmptyResultsComponent, CpfCnpjPipe, CropImageComponent, DisabledFeatureComponent, PhonePipe, DragAndDropDirective]
})
export class HelpersModule { }
