import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { Cargo } from 'src/app/models/cargo';
import { CargoItem } from 'src/app/models/cargo-item';
import { CargoScheduleItem } from 'src/app/models/cargo-schedule-item';
import { AdminService } from 'src/app/services/admin.service';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EditScheduleCargoItemComponent } from './edit-schedule-cargo-item/edit-schedule-cargo-item.component';
import { PalletsInfoComponent } from './pallets-info/pallets-info.component';

enum TokenStatus {
  Validating,
  Valid,
  Invalid,
  AfterSent
}

@Component({
  selector: 'app-schedule-cargo',
  templateUrl: './schedule-cargo.component.html',
  styleUrls: ['./schedule-cargo.component.scss']
})
export class ScheduleCargoComponent implements OnInit {
  weekendsDatesFilter = (d: moment.Moment): boolean => {
    const day = d?.weekday();
    // Prevent Saturday and Sunday from being selected.
    return day !== 5 && day !== 6;
  }

  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  token = null;
  form: UntypedFormGroup;
  loading = false;
  cargo: Cargo;
  minDate: Date;
  maxDate: Date;
  displayedColumns: string[] = ['select', 'item', 'quantity', 'weight', 'isBlocked', 'edit'];
  selection = new SelectionModel<CargoItem>(true, []);
  sent = false;
  selectedTab = 0;

  constructor(private service: LogisticsService, private adminService: AdminService, private route: ActivatedRoute, private router: Router, private notificationService: NotificationService, private dialog: MatDialog) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      type: new UntypedFormControl('P', [Validators.required]),
      date: new UntypedFormControl('', [Validators.required]),
      shippingCompany: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      shippingCompanyCode: new UntypedFormControl('', [Validators.required, Validators.minLength(14)]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      driverName: new UntypedFormControl(),
      licencePlate: new UntypedFormControl(),
      truckLicencePlate: new UntypedFormControl(),
      comments: new UntypedFormControl(),
    });

    const token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    let validateRequest = this.service.validateScheduleToken(token).pipe(first());
    let maxDaysRequest = this.adminService.getParameterByName({ name: 'Log-MaxScheduleDays' });

    forkJoin([validateRequest, maxDaysRequest]).subscribe({
      next: (data) => {
        this.token = token;
        this.tokenStatus = TokenStatus.Valid;
        this.cargo = data[0];
        let days = data[1].value;
        this.maxDate = new Date(moment().add(days, 'days').calendar());
        this.showCommentsDialog();
      },
      error: () => {
        this.tokenStatus = TokenStatus.Invalid;
      }
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.value }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.cargo.groupedItems.filter(c => !c.isBlocked).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.cargo.groupedItems.filter(c => !c.isBlocked).forEach(i => this.selection.select(i));
  }

  getSelectedWeight() {
    return this.selection.selected.reduce((a, b) => a + b.totalWeight, 0);
  }

  getSelectedQuantity() {
    return this.selection.selected.reduce((a, b) => a + b.quantity, 0);
  }

  showCommentsDialog() {
    if (this.cargo.comments) {
      this.notificationService.info(this.cargo.comments);
    }
  }

  showPalletsInfo() {
    this.dialog.open(PalletsInfoComponent, { panelClass: 'dialog-extra-large' });
  }

  showEditDialog(item: CargoItem) {
    const dialogRef = this.dialog.open(EditScheduleCargoItemComponent, { data: { item } });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result != null) {
          let weight = item.totalWeight / item.quantity;
          item.quantity = result.quantity;
          item.totalWeight = item.quantity * weight;
        }
      }
    )
  }

  onSubmit() {
    if (this.form.invalid) {
      this.notificationService.warning('Existem campos não preenchidos.')
      return;
    }

    if (this.selectedTab == 0 && this.selection.selected.length == 0) {
      this.selectedTab = 1;
      return;
    }
    else if (this.selection.selected.length == 0) {
      this.notificationService.error('Nenhum item foi selecionado.');
      return;
    }

    if (this.selection.selected.filter(i => i.isBlocked)?.length > 0) {
      this.notificationService.error('Itens bloqueados não podem ser selecionados.');
      return;
    }

    this.loading = true;

    let scheduleItems = this.selection.selected.map(i => new CargoScheduleItem(i.itemID, i.quantity));

    let schedule = {
      token: this.token,
      date: this.f.date.toJSON(),
      type: 'P',
      shippingCompany: this.f.shippingCompany,
      shippingCompanyCode: this.f.shippingCompanyCode,
      phoneNumber: this.f.phoneNumber,
      driverName: this.f.driverName,
      licencePlate: this.f.licencePlate,
      truckLicencePlate: this.f.truckLicencePlate,
      comments: this.f.comments,
      items: scheduleItems
    };

    this.service.proposeSchedule(schedule).subscribe({
      next: () => {
        this.notificationService.info('Agendamento enviado com sucesso.');
        this.tokenStatus = TokenStatus.AfterSent;
        this.loading = false;
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
      }
    })
  }
}
