<h1 mat-dialog-title>Idioma</h1>
<mat-dialog-content [formGroup]="form">
    <mat-form-field class="full-container">
        <mat-label>Idioma</mat-label>
        <mat-select formControlName="language" placeholder="Selecione" [disabled]="editMode">
            <mat-option *ngFor="let language of languages" [value]="language">
                {{ language.description }}
            </mat-option>
        </mat-select>
        <mat-error>Idioma inválido</mat-error>
    </mat-form-field>
    <mat-form-field class="full-container">
        <mat-label>Nível de Proficiência</mat-label>
        <mat-select formControlName="level" placeholder="Selecione">
            <mat-option *ngFor="let level of languageLevels" [value]="level.code">
                {{ level.description }}
            </mat-option>
        </mat-select>
        <mat-error>Nível inválido</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>