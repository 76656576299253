import { BudgetDashboardEntry } from "./budget-dashboard-entry";

export class FinanceDashboardData {
    budgetedExpenses: number;
    paidExpenses: number;
    budgetedInvestments: number;
    paidInvestments: number;
    openCostCentersCount: number;
    overflowingAccountsCount: number;
    expensesPercentualDifference: number;
    investmentsPercentualDifference: number;
    percentualDifferenceYoY: number;
    paidExpensesLY: number;
    budgetVariationPerGroup: BudgetDashboardEntry[];
    top5CostCentersWithHighestVariation: BudgetDashboardEntry[];

    constructor() {
        this.budgetedExpenses = 0;
        this.paidExpenses = 0;
        this.budgetedInvestments = 0;
        this.paidInvestments = 0;
        this.openCostCentersCount = 0;
        this.overflowingAccountsCount = 0;
        this.expensesPercentualDifference = 0;
        this.investmentsPercentualDifference = 0;
        this.percentualDifferenceYoY = 0;
        this.paidExpensesLY = 0;
        this.budgetVariationPerGroup = [];
        this.top5CostCentersWithHighestVariation = [];
    }
}