import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CargoItem } from 'src/app/models/cargo-item';

@Component({
  selector: 'app-edit-schedule-cargo-item',
  templateUrl: './edit-schedule-cargo-item.component.html',
  styleUrls: ['./edit-schedule-cargo-item.component.scss']
})
export class EditScheduleCargoItemComponent implements OnInit {

  item: CargoItem;
  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditScheduleCargoItemComponent>) { }

  ngOnInit() {
    this.item = this.data.item;
    this.form = new UntypedFormGroup(
      {
        quantity: new UntypedFormControl(
          this.item.quantity,
          [
            Validators.required,
            Validators.min(0),
            Validators.max(this.item.originalQuantity)
          ])
      }
    )
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

}
