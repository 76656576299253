import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterestArea } from 'src/app/models/interest-area';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-add-interest',
    templateUrl: './add-interest.component.html',
    styleUrls: ['./add-interest.component.scss']
})

export class AddInterestComponent implements OnInit {
    interestAreas: InterestArea[];
    interestArea?: InterestArea;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AddInterestComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.interestAreas = this.data?.interestAreas ?? [];
    }

    save() {
        if (this.interestArea == null) {
            this.notificationService.warning('A área de interesse deve ser selecionada')
            return;
        }

        this.dialogRef.close(this.interestArea);
    }

    close() {
        this.dialogRef.close();
    }
}
