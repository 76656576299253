import {Injectable} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();  

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
      this.itemsPerPageLabel = "Itens por página";
      this.nextPageLabel = "Próxima página";
      this.previousPageLabel = "Página anterior";
      this.lastPageLabel = "Última página";
      this.firstPageLabel = "Primeira página"
      this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    
    length = Math.max(length, 0);
    return `Pág. ${page + 1} de ${Math.ceil(length / pageSize)}`;
  }
}