<div class="actions">
    <button mat-icon-button (click)="goFullScreen()" matTooltip="Visualizar em tela cheia">
        <mat-icon>fullscreen</mat-icon>
    </button>
    <mat-form-field appearance="default">
        <mat-select class="search-input" [(ngModel)]="salesman" (ngModelChange)="changeFilter()"
            placeholder="SELECIONAR...">
            <mat-option [value]="rep" *ngFor="let rep of salesmen">{{ rep?.description }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<powerbi-report [embedConfig]="reportConfig" cssClassName="report-container" phasedEmbedding="false"
    [eventHandlers]="eventHandlersMap">
</powerbi-report>