import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Period } from 'src/app/models/period';
import { EntryService } from 'src/app/services/entry.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-period-dialog',
  templateUrl: './period-dialog.component.html',
  styleUrls: ['./period-dialog.component.scss']
})
export class PeriodDialogComponent implements OnInit {

  periods: Period[];
  startDate: Date;
  endDate: Date;
  year: number;
  savePeriod: boolean;

  constructor(public dialogRef: MatDialogRef<PeriodDialogComponent>, private settingsService: SettingsService, private notificationService: NotificationService,
    private entryService: EntryService, @Inject(MAT_DIALOG_DATA) public data) {
    this.savePeriod = this.data?.savePeriod ?? true;
    this.year = Number.parseInt(data?.year ?? this.settingsService.getSetting('year'));
  }

  ngOnInit() {
    let d1 = this.settingsService.getParameter('period-startDate');
    let d2 = this.settingsService.getParameter('period-endDate');
    let enterprise = this.settingsService.getParameter('enterprise');

    let params = new HttpParams().set('enterpriseId', enterprise);

    if (this.data?.multiYear == null) {
      params = params.append('year', this.year);
    }

    this.entryService.getPeriods(params).subscribe({
      next: data => {
        this.periods = data;

        switch (this.data?.multiYear) {
          case YearsRange.Before:
            this.periods = this.periods.filter(p => new Date(p.startDate).getFullYear() >= (this.year - 2) && new Date(p.endDate).getFullYear() <= (this.year - 1));
            this.startDate = this.periods[0].startDate;
            this.endDate = this.periods[this.periods.length - 1].endDate;
            break;
          case YearsRange.After:
            this.periods = this.periods.filter(p => new Date(p.startDate).getFullYear() >= (this.year + 1) && new Date(p.endDate).getFullYear() <= (this.year + 2));
            this.startDate = this.periods[0].startDate;
            this.endDate = this.periods[this.periods.length - 1].endDate;
            break;
          default:
            this.startDate = this.periods.find(p => p.startDate == d1)?.startDate ?? this.periods[0].startDate;
            this.endDate = this.periods.find(p => p.endDate == d2)?.endDate ?? this.periods[this.periods.length - 1].endDate;
            break
        }
      }
    });
  }

  get filteredPeriods(): Period[] {
    return this.periods?.filter(p => p.startDate >= this.startDate);
  }

  onConfirm(): void {
    if (this.startDate != null && this.endDate != null) {
      let customPeriod = new Period(this.startDate, this.endDate);

      // Close the dialog, return period
      this.dialogRef.close(customPeriod);

      if (this.savePeriod) {
        this.settingsService.setParameter('period-startDate', this.startDate);
        this.settingsService.setParameter('period-endDate', this.endDate);
      }
    }
    else {
      this.notificationService.warning("Defina um período.");
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}

export enum YearsRange {
  Before,
  After
}
