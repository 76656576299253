<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon>workspace_premium</mat-icon>
        <mat-card-title>PPR</mat-card-title>
    </div>
    <small>O que deseja visualizar?</small>
    <div class="buttons-row">
        <button mat-raised-button color="primary" routerLink="/accounting/outcome-distribution-ranges/" class="block-button" (click)="close()">
            <mat-icon>timeline</mat-icon>
            <br>
            <label>Faixas</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/accounting/goal-achievements/" class="block-button" (click)="close()">
            <mat-icon>emoji_events</mat-icon>
            <br>
            <label>Atingimento</label>
        </button>
        <button mat-raised-button color="primary" routerLink="/accounting/profits-sharing-report/" class="block-button" (click)="close()">
            <mat-icon>bar_chart</mat-icon>
            <br>
            <label>Relatório</label>
        </button>
    </div>
</div>