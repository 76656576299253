<div *ngIf="!showSendUpdateTokenOption">
    <h1 mat-dialog-title fxLayout="column">
        <span>Insira seu CPF</span>
        <span class="mat-caption">Pesquisaremos por um currículo com este CPF em nossa base de dados</span>
    </h1>
    <mat-dialog-content [formGroup]="form">
        <mat-form-field class="full-container">
            <mat-label>CPF</mat-label>
            <input matInput placeholder="Insira seu CPF" formControlName="cpf" mask="000.000.000-00">
            <mat-error>CPF inválido</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="search()">Buscar</button>
    </mat-dialog-actions>
</div>

<div *ngIf="showSendUpdateTokenOption" [formGroup]="form">
    <h1 mat-dialog-title fxLayout="column">
        <span>Encontramos um currículo!</span>
        <span class="mat-caption">Encontramos um currículo com este CPF na nossa base de dados.</span>
        <span class="mat-caption">Caso queira editá-lo, clique no botão "Envie-me o link" para receber um
            link, no e-mail cadastrado, que irá direcioná-lo para a edição do currículo.</span>
    </h1><br>
    <ngx-recaptcha2 *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false" size="normal" hl="pt"
        theme="light" type="image" formControlName="reCaptcha">
    </ngx-recaptcha2>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="sendUpdateToken()">Envie-me o link</button>
        <button mat-button color="primary" (click)="changeCPF()">Buscar outro CPF</button>
    </mat-dialog-actions>
</div>