import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Period } from '../models/period';
import Translations from 'src/assets/json/EnumTranslations.json';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private static settingsValues: {} = {
    'year': [],
    'department': Object.keys(Translations.Departments),
  };

  private enterpriseSubject: BehaviorSubject<number>;
  public enterprise: Observable<number>;

  constructor() {
    this.enterpriseSubject = new BehaviorSubject<number>(null);
    this.enterprise = this.enterpriseSubject.asObservable();

    for (let year = 2015; year <= (new Date().getFullYear() + 1); year++) {
      SettingsService.settingsValues['year'].push(year.toString());
    }
  }

  public getSettingsValues(setting?) {
    return setting != null ? SettingsService.settingsValues[setting] : SettingsService.settingsValues;
  }

  private sessionParameters = {
    'enterprise': '',
    'startDate': '',
    'endDate': '',
    'period-startDate': '',
    'period-endDate': '',
    'budgetEmployee': '',
  }

  private settingsObject = {
    'year': '',
  }; // store settings of a session if localStorage is not available

  public initSettingsObject() {
    // create settings object to store settings for session if localStorage is not available
    // settingsObject serves also as a fallback before the user sets any personal settings

    this.sessionParameters['startDate'] = this.getDefaultPeriod().startDate.toDateString();
    this.sessionParameters['endDate'] = this.getDefaultPeriod().endDate.toDateString();
    this.sessionParameters['period-startDate'] = this.getDefaultPeriod().startDate.toDateString();
    this.sessionParameters['period-endDate'] = this.getDefaultPeriod().endDate.toDateString();
    this.sessionParameters['enterprise'] = '1';
    this.sessionParameters['budgetEmployee'] = '0';
    this.settingsObject['year'] = new Date().getFullYear().toString();
  }

  public getSetting(key: string): any {
    if (!this.isValidKey(key)) {
      console.log('settings-service.getSetting() key is invalid: ' + key);
      return null;
    }

    if ((typeof (Storage) !== 'undefined') && localStorage.getItem(key)) {
      return localStorage.getItem(key);
    } else {
      return this.settingsObject[key];
    }
  }

  public getParameter(key: string): any {
    if ((typeof (Storage) !== 'undefined') && localStorage.getItem(key)) {
      return localStorage.getItem(key);
    } else {
      return this.sessionParameters[key];
    }
  }

  public setSetting(key: string, value: any) {

    if (!this.isValidSetting(key, value)) {
      console.log('settings-service: {' + key + ': ' + value + '} setting was incorrect');
      return;
    }

    if ((typeof (Storage) !== 'undefined')) {
      localStorage.setItem(key, value);
    } else {
      this.settingsObject[key] = value;
    }
  }

  public setParameter(key: string, value: any) {
    if ((typeof (Storage) !== 'undefined')) {
      if (key == 'enterprise') {
        this.enterpriseSubject.next(value);
      }
      localStorage.setItem(key, value);
    } else {
      this.sessionParameters[key] = value;
    }
  }

  public isValidSetting(key: string, value: any) {
    if (SettingsService.settingsValues.hasOwnProperty(key) &&
      SettingsService.settingsValues[key].includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  public isValidKey(key: string) {
    if (SettingsService.settingsValues.hasOwnProperty(key)) {
      return true;
    } else {
      return false;
    }
  }

  private getDefaultPeriod() {
    let year = this.getSetting("year");
    return new Period(new Date(year, 0, 1), new Date(year, 0, 31));
  }
}