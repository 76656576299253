import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  showCaptcha: boolean = true;
  siteKey = environment.siteKey;
  form: UntypedFormGroup;
  loading = false;

  constructor(private formBuilder: FormBuilder, private service: UserService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      reCaptcha: [null, [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.forgotPassword(this.f.email.value, this.f.reCaptcha.value).subscribe({
      next: () => {
        this.notificationService.info("Um e-mail com instruções para recuperar sua conta foi enviado para " + this.f.email.value);
        this.loading = false;
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
        this.form.patchValue({ reCaptcha: null });

        this.showCaptcha = false;
        
        setTimeout(() => {
          this.showCaptcha = true;
        });
      }
    });
  }

}
