import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CPF_CNPJ' })
export class CpfCnpjPipe implements PipeTransform {
    transform(value: string | number): string {
        let registrationNumber = value + '';

        if (registrationNumber.length >= 12) {
            registrationNumber = registrationNumber
                .padStart(14, '0')
                .replace(/[^0-9]/, '')
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        else {
            registrationNumber = registrationNumber
                .padStart(11, '0')
                .replace(/[^0-9]/, '')
                .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }

        return registrationNumber;
    }

    public static format(value: string | number): string {
        let registrationNumber = value + '';

        if (registrationNumber.length >= 12) {
            registrationNumber = registrationNumber
                .padStart(14, '0')
                .replace(/[^0-9]/, '')
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        else {
            registrationNumber = registrationNumber
                .padStart(11, '0')
                .replace(/[^0-9]/, '')
                .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }

        return registrationNumber;
    }
}