import { Component, OnInit, ViewChild } from '@angular/core';
import { Role } from 'src/app/models/role';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AdminService } from '../../services/admin.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddRoleComponent } from './add-role/add-role.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/helpers/confirm-dialog/confirm-dialog.component';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  dataSource = new MatTableDataSource<Role>();
  displayedColumns: string[] = ['description', 'actions'];  

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private service: AdminService, private notificationService: NotificationService, private dialog: MatDialog) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.getRoles();
  }

  getRoles = () => {    
    this.service.getRoles().subscribe({
      next: roles => {
        this.dataSource.data = roles;        
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  applyFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  showAddDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AddRoleComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      result => {
        if (result != null) {          
          this.service.postRole(result).subscribe({
            next: data => {
              this.notificationService.sucess("Regra " + data.description + " adicionada.");
              this.getRoles();
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    )
  }

  showEditDialog(role?: Role) {
    const dialogRef = this.dialog.open(EditRoleComponent, { panelClass: "dialog-large", data: { role } });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result == 'done') {
          this.getRoles();
        }
      }
    )
  }

  showDeleteDialog(role: Role) {
    const message = `Deseja realmente deletar a regra <b>${role.description}</b>?`;
    const dialogData = new ConfirmDialogModel("Deletar", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.service.deleteRole({ ID: role.id }).subscribe({
            next: () => {
              this.getRoles();
              this.notificationService.sucess("Regra deletada.");
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    )
  }

  getTotalEntries() {
    return (this.dataSource.filteredData || this.dataSource.data).length;
  }
}

export enum Roles {
  Admin = 'Admin',
  BudgetAdmin = 'Budget Admin',
  BudgetUser = 'Budget User',
  BudgetViewer = 'Budget Viewer',
  LogisticsAdmin = 'Logistics Admin',
  LogisticsUser = 'Logistics User',
  EntryAdmin = 'Entry Admin',
  SalesAdmin = 'Sales Admin',
  SalesUser = 'Sales User',
  AccountingAdmin = 'Accounting Admin',
  AccountingUser = 'Accounting User',
  PowerBIViewer = 'PowerBI Viewer',
  HumanResourcesAdmin = 'Human Resources Admin'
}
