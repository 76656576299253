<h1 mat-dialog-title>Qualificação</h1>
<mat-dialog-content [formGroup]="form">
    <mat-form-field class="half-container">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="level" placeholder="Selecione">
            <mat-option *ngFor="let level of qualificationLevels" [value]="level.code">
                {{ level.description }}
            </mat-option>
        </mat-select>
        <mat-error>Tipo inválido</mat-error>
    </mat-form-field>
    <mat-form-field class="half-container">
        <mat-label>Instituição</mat-label>
        <input matInput placeholder="Insira a instituição" formControlName="institution" maxlength="70">
        <mat-hint align="end">{{ form.value.institution?.length ?? 0 }} / 70</mat-hint>
        <mat-error>Instituição inválida</mat-error>
    </mat-form-field>
    <mat-form-field class="full-container">
        <mat-label>Descrição</mat-label>
        <input matInput placeholder="Insira a descrição" formControlName="description" maxlength="70">
        <mat-hint align="end">{{ form.value.description?.length ?? 0 }} / 70</mat-hint>
        <mat-error>Descrição inválida</mat-error>
    </mat-form-field>
    <mat-form-field class="date-field">
        <mat-label>Data de início</mat-label>
        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
            placeholder="mm/aaaa" maxlength="7" readonly>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" (monthSelected)="setMonthAndYear($event, 'startDate', startDatePicker)"
            #startDatePicker></mat-datepicker>
        <mat-error>Data inválida</mat-error>
    </mat-form-field>
    <mat-form-field class="date-field">
        <mat-label>Data de fim (ou previsto)</mat-label>
        <input matInput [min]="form.value.startDate" [matDatepicker]="endDatePicker"
            formControlName="endDate" placeholder="mm/aaaa" maxlength="7" readonly>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker startView="multi-year" (monthSelected)="setMonthAndYear($event, 'endDate', endDatePicker)"
            #endDatePicker></mat-datepicker>
        <mat-error>Data inválida</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>