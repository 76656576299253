<title mat-dialog-title style="text-align: center;">Termo de Consentimento</title>
<mat-dialog-content class="dialog-content">
    <p class="consent-container">
        <span class="w-500 consent-title">TERMO DE CONSENTIMENTO PARA O TRATAMENTO DE DADOS PESSOAIS NO
            RECEBIMENTO DE CURRÍCULOS</span>
        <br><br>
        <span>
            <small>
                Em conformidade com a Lei nº 13.709/18 - Lei Geral de Proteção de Dados Pessoais (LGPD), com
                a
                finalidade de proteger os direitos de privacidade, este documento ou aceite tem o objetivo
                de
                registrar o CONSENTIMENTO LIVRE, para que seus dados pessoais sejam utilizados para a
                finalidade
                única e exclusiva de realizar as ações relacionadas ao recebimento de currículo, sua
                avaliação e
                seleção, e para eventual recrutamento para participação em processos de admissão para compor
                o
                quadro de empregados da COOPERATIVA VINÍCOLA GARIBALDI. De acordo com os princípios gerais
                da Lei de
                Proteção de Dados Pessoais (LGPD), e às demais normas regulamentares aplicáveis, a
                COOPERATIVA
                VINÍCOLA GARIBALDI não poderá utilizar, compartilhar, comercializar, ou atribuir qualquer
                outra
                finalidade aos dados pessoais coletados, que seja diferente da finalidade aqui especificada
                sob pena
                de arcar com eventuais danos e sanções previstas em lei.<br><br>
                A COOPERATIVA VINÍCOLA GARIBALDI manterá o currículo do CANDIDATO armazenado em seu banco de
                dados
                enquanto a vaga estiver em andamento. Caso não seja selecionado, o CANDIDATO <span class="w-500"> possui
                    a opção de
                    assinalar abaixo se concorda com a manutenção do seu currículo na base de dados da
                    COOPERATIVA
                    VINÍCOLA GARIBALDI pelo período de 12 (doze) meses, contados da assinatura do
                    presente.</span>
                <br><br>
                Após este período deverá ocorrer o término do tratamento de dados pessoais realizados pela
                empresa,
                neste momento serão eliminados em sua totalidade de seu banco de dados.
            </small>
        </span>
    </p>
    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: -24px" class="actions">
        <p>
            <mat-checkbox [(ngModel)]="accepted" color="primary" (click)="$event.stopPropagation()">Concordo em
                manter meus dados armazenados por 12 (doze) meses</mat-checkbox>
        </p>
        <button mat-raised-button color="primary" [disabled]="!accepted" (click)="finish()">Finalizar</button>
    </div>
</mat-dialog-content>