<div class="grid-container">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <mat-icon>{{ departmentIcon }}</mat-icon>
      <label class="title">{{ department }}</label>
    </div>
    <div>
      <mat-chip selected (click)="showPeriodDialog()" matTooltip="Clique para alterar">
        {{ period?.getStartDateMonth() }}</mat-chip>
      <small style="color: #3f51b5">- - - - -</small>
      <mat-chip selected (click)="showPeriodDialog()" matTooltip="Clique para alterar">
        {{ period?.getEndDateMonth() }}</mat-chip>
    </div>
  </div>
  <br>
  <div class="column">
    <div class="wrap row charts">
      <div class="dashboard-card">
        <div echarts class="pie-chart" [options]="annualExpenses"></div>
      </div>
      <div class="dashboard-card">
        <div echarts class="pie-chart" [options]="annualInvestments"></div>
      </div>
      <div class="dashboard-card">
        <label class="mat-caption chart-caption">{{ (this.financeData.percentualDifferenceYoY * 100).toFixed(2)
          }}%</label>
        <div echarts class="pie-chart" [options]="budgetVariation"></div>
      </div>
      <div class="wrap row cards">
        <div *ngFor="let card of cards | async" class="grid-item">
          <div class="dashboard-card dashboard-{{card.index}}" fxLayout="column" [routerLink]="card.route"
            [queryParams]="card.routeParams">
            <div fxLayout="column">
              <label class="mat-body-2">{{ card.title }}</label>
              <label class="mat-caption">{{ card.caption }}</label>
            </div>
            <svg width="130" height="130" viewBox="0 0 48 48" text-anchor='middle'>
              <path d="M23.0287 43.4604L7.02871 34.5715C6.39378 34.2188 6 33.5495 6 32.8232V15.1768C6 14.4505 6.39378 13.7812 7.02872 13.4285L23.0287 4.5396C23.6328 4.20402 
                24.3672 4.20402 24.9713 4.5396L40.9713 13.4285C41.6062 13.7812 42 14.4505 42 15.1768V32.8232C42 33.5495 41.6062 34.2188 40.9713 34.5715L24.9713 43.4604C24.3672 
                43.796 23.6328 43.796 23.0287 43.4604Z" stroke="#A0C0D3" stroke-width="1" fill="#264A5E"></path>
              <text x="50%" y="27" fill="white" stroke="white" stroke-width="0.5" font-size="14">{{ card.count }}</text>
              <text x="50%" y="34" fill="white" height="10" font-size="4">{{ card.suffix }}</text>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap row charts" *ngIf="financeData.budgetVariationPerGroup?.length || financeData.top5CostCentersWithHighestVariation?.length > 0">
      <div class="dashboard-card">
        <div echarts class="pie-chart" [options]="budgetVariationPerGroup"></div>
      </div>
      <div class="dashboard-card">
        <div echarts class="pie-chart" [options]="budgetExpensesPerGroup"></div>
      </div>
      <div class="dashboard-card">
        <label class="mat-caption chart-caption-large">5 maiores</label>
        <div echarts class="large-chart" [options]="top5CostCentersVariation"></div>
      </div>
    </div>
  </div>
</div>