import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { Parameter } from '../models/parameter';
import { SystemJob } from '../models/system-job';
import { LongLivedToken } from '../models/long-lived-token';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { 
  }

  getRoles() {
    return this.http.get<Role[]>(`${environment.API}roles`).pipe();
  }

  getParameters() {
    return this.http.get<Parameter[]>(`${environment.API}parameters`).pipe();
  }

  getParameterByName(params?) {
    return this.http.get<Parameter>(`${environment.API}parameters/name`, { params }).pipe();
  }
  
  validatePassword(params) {
    return this.http.post<any>(`${environment.API}parameters/verify-password`, params).pipe();
  }

  putRole(id: number, role) {
    return this.http.put<any>(`${environment.API}roles/${id}`, role).pipe();
  }

  putParameter(id: number, parameter) {
    return this.http.put<any>(`${environment.API}parameters/${id}`, parameter).pipe();
  }

  postRole(role) {
    return this.http.post<Role>(`${environment.API}roles`, role).pipe();
  }

  deleteRole(params) {
    return this.http.delete<Role>(`${environment.API}roles`, { params }).pipe();
  }

  getSystemJobs(params) {
    return this.http.get<SystemJob[]>(`${environment.API}systemJobs`, { params }).pipe();
  }

  updateSystemJob(id: number, body) {    
    return this.http.put<any>(`${environment.API}systemJobs/${id}`, body).pipe();
  }

  executeSystemJob(body) {    
    return this.http.post<any>(`${environment.API}systemJobs/run`, body).pipe();
  }

  getLongLivedTokens(params) {
    return this.http.get<LongLivedToken[]>(`${environment.API}longLivedTokens`, { params }).pipe();
  }

  createLongLivedToken(body) {
    return this.http.post(`${environment.API}longLivedTokens/generate`, body).pipe();
  }

  revokeLongLivedToken(body) {
    return this.http.post(`${environment.API}longLivedTokens/revoke`, body).pipe();
  }

  validateLongLivedToken(body) {
    return this.http.post(`${environment.API}longLivedTokens/validate`, body).pipe();
  }
}
