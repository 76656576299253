import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Period } from 'src/app/models/period';
import { EntryService } from 'src/app/services/entry.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
    selector: 'app-single-period-dialog',
    templateUrl: './single-period-dialog.component.html',
    styleUrls: ['./single-period-dialog.component.scss']
})
export class SinglePeriodDialogComponent implements OnInit {

    periods: Period[];
    selectedPeriod: Period;
    isCurrentMonthSelected: boolean;
    context: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SinglePeriodDialogComponent>, private settingsService: SettingsService,
        private notificationService: NotificationService, private entryService: EntryService) { this.context = data; }

    ngOnInit() {
        let enterpriseId = this.settingsService.getParameter('enterprise');
        let params = new HttpParams().append('enterpriseId', enterpriseId);

        if (this.context?.year != null) {
            params = params.append('year', this.context.year);
        }

        this.entryService.getPeriods(params).subscribe({
            next: periods => {
                this.periods = periods;
                this.selectedPeriod = periods.find(p => p.id == this.context?.periodId) ?? periods[0];
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    setPeriodAsCurrentMonth(checked: boolean) {
        if (checked) {
            let firstDayOfMonth = Date.parse(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toString());
            this.selectedPeriod = this.periods?.find(p => Date.parse(p.startDate.toString()) == firstDayOfMonth);
            this.isCurrentMonthSelected = true;
        }
    }

    selectPeriod() {
        this.isCurrentMonthSelected = false;
    }

    onConfirm(): void {
        if (this.selectedPeriod == null) {
            this.notificationService.warning("Selecione um período.");
        }

        this.dialogRef.close(this.selectedPeriod);
    }

    onDismiss() {
        this.dialogRef.close();
    }

}
