<div class="container-content">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="mat-headline">
      <span>
        Cargas
        <mat-chip>{{getTotalEntries()}}</mat-chip>
      </span>
    </h1>
    <button mat-button color="primary" (click)="showSchedulesReport()">
      O que está programado<mat-icon>help_outline</mat-icon>
    </button>
  </div>

  <div class="mat-elevation-z8">
    <mat-card>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <mat-card-title>Registros</mat-card-title>
          <label class="mat-small">Abaixo estão listadas as cargas.</label>
          <br>
          <mat-form-field style="width: 300px;">
            <mat-label>Pesquisa</mat-label>
            <input matInput placeholder="Pesquise na tabela" (keyup)="applyFilter($event.target.value)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="center end">
          <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
          </mat-paginator>
          <div class="filter" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="8px">
            <button mat-icon-button (click)="getCargos()" matTooltip="Atualizar">
              <mat-icon>refresh</mat-icon>
            </button>
            <mat-form-field>
              <mat-label>Filtro</mat-label>
              <mat-select [(value)]="activeFilter" (selectionChange)="getCargos()">
                <mat-option *ngFor="let filter of filters" [value]="filter">{{ filter }}</mat-option>
              </mat-select>
            </mat-form-field>
            <wbr>
            <mat-form-field>
              <mat-label>Escolha um período</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker"
                [disabled]="activeFilter == Filter.Outdated">
                <input matStartDate formControlName="start" placeholder="Data Inicial">
                <input matEndDate formControlName="end" placeholder="Data Final">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancelar</button>
                  <button mat-raised-button color="primary" matDateRangePickerApply
                    (click)="applyDate()">Aplicar</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-card-content>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data geração</th>
            <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="schedule">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Agendamento </th>
            <td mat-cell *matCellDef="let element"> {{element.cargoSchedule | date: 'dd/MM/yyyy HH:mm'}}

            </td>
          </ng-container>

          <ng-container matColumnDef="totalWeight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso total </th>
            <td mat-cell *matCellDef="let element">
              <label *ngIf="element.scheduledWeight <= 0">{{element.totalWeight | number}}</label>
              <label *ngIf="element.scheduledWeight > 0">{{element.scheduledWeight | number}}</label>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Situação </th>
            <td mat-cell *matCellDef="let element">
              <app-status-chip [text]="element.status"></app-status-chip>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" align="right">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showScheduleDialog(row)"
                  *ngIf="canScheduleStatuses.includes(row.status)">
                  <mat-icon>event</mat-icon>
                  <span>Solicitar agendamento</span>
                </button>
                <button mat-menu-item (click)="showCargoDetails(row)">
                  <mat-icon>settings</mat-icon>
                  <span>Detalhes</span>
                </button>
                <button mat-menu-item (click)="restart(row)" *ngIf="row.status == 'Enviado'">
                  <mat-icon>restart_alt</mat-icon>
                  <span>Atualizar Carga</span>
                </button>
                <button mat-menu-item (click)="cancel(row)" *ngIf="!readOnlyStatuses.includes(row.status)">
                  <mat-icon>event_busy</mat-icon>
                  <span>Inutilizar</span>
                </button>
                <button mat-menu-item (click)="finalize(row)" *ngIf="!readOnlyStatuses.includes(row.status)">
                  <mat-icon>task</mat-icon>
                  <span>Finalizar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <app-empty-results *ngIf="dataSource.data?.length <= 0 || dataSource.filteredData?.length <= 0"></app-empty-results>
</div>