<div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 mat-dialog-title>{{role.description}}</h1>
    <button mat-button aria-label="aplicar" (click)="save()" matTooltip="Aplica as alterações.">
      Concluído
      <mat-icon>done</mat-icon>
    </button>
  </div>
  <mat-dialog-content [formGroup]="form">  
    <mat-form-field>
      <mat-label>Descrição</mat-label>
      <input type="text" matInput formControlName="description" cdkFocusInitial>
    </mat-form-field>
  </mat-dialog-content>
