<div fxLayout="row" fxLayoutAlign="stretch center" class="background">
    <div fxFlex="60" fxHide.sm="true" fxHide.xs="true">
        <img fxFlexFill src="/assets/img/login.jpeg" />
        <img class="logo-image" src="/assets/img/brand-logo.png">
    </div>
    <div fxFlex="10"></div>
    <div class="form">
        <h1 class="mat-headline">Vitisys Web</h1>
        <h3 class="mat-subheading-1">Entre para continuar</h3>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="email">
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Senha</mat-label>
                    <input type="password" matInput formControlName="password">
                </mat-form-field>
            </p>
            <ngx-recaptcha2 *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false" size="normal"
                hl="pt" theme="light" type="image" formControlName="reCaptcha">
            </ngx-recaptcha2>
            <p>
                <a mat-stroked-button color="primary" routerLink="/forgot-password">Esqueceu a senha?</a>
            </p>

            <div class="button">
                <button type="submit" mat-raised-button color="primary" [disabled]="loading">Login</button>
            </div>

        </form>
        <h6 class="mat-small">Versão 3.0</h6>
    </div>
</div>