import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MustMatchValidator } from 'src/app/helpers/must-match.validator';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

enum TokenStatus {
  Validating,
  Valid,
  Invalid
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  token = null;
  form: UntypedFormGroup;
  loading = false;

  constructor(private service: UserService, private notificationService: NotificationService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: MustMatchValidator("password", "confirmPassword") });

    const token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.service.validateResetToken(token)
      .pipe(first())
      .subscribe({
        next: () => {
          this.token = token;
          this.tokenStatus = TokenStatus.Valid;
        },
        error: () => {
          this.tokenStatus = TokenStatus.Invalid;
        }
      });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.service.resetPassword(this.token, this.f.password.value, this.f.confirmPassword.value).subscribe(
      {
        next: () => {
          this.notificationService.sucess("A senha foi alterada.");
          this.router.navigate(['../login'], { relativeTo: this.route });
          this.loading = false;
        },
        error: error => {
          this.notificationService.error(error);
          this.loading = false;
        }

      }
    );
  }

}
