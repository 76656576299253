import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Extensions } from 'src/app/helpers/extensions';
import { JobApplicationExperience } from 'src/app/models/job-application-experience';
import { JobOccupation } from 'src/app/models/job-occupation';
import { NotificationService } from 'src/app/services/notification.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-add-professional-experience',
    templateUrl: './add-professional-experience.component.html',
    styleUrls: ['./add-professional-experience.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS
    }]
})

export class AddProfessionalExperienceComponent implements OnInit {
    form: UntypedFormGroup;
    maxDate: Date = new Date();
    jobOccupations: JobOccupation[] = [];
    filteredOccupations: JobOccupation[] = [];

    @ViewChild('jobOccupationInput') jobOccupationInput: ElementRef<HTMLInputElement>;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AddProfessionalExperienceComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.jobOccupations = this.data?.jobOccupations ?? [];

        this.form = new UntypedFormGroup({
            employer: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            jobOccupation: new UntypedFormControl(null, [Validators.required]),
            description: new UntypedFormControl(null, [Validators.required, Validators.maxLength(300)]),
            startDate: new UntypedFormControl(null, Validators.required),
            endDate: new UntypedFormControl(null, [Validators.required]),
            currentlyWorksThere: new UntypedFormControl(false)
        });

        let jobExperience = this.data?.jobExperience as JobApplicationExperience;

        if (jobExperience != null) {
            this.form.patchValue({
                employer: jobExperience.employer, description: jobExperience.description,
                startDate: jobExperience.startDate, endDate: jobExperience.endDate,
                currentlyWorksThere: jobExperience.currentlyWorksThere
            });

            this.updateValidators('endDate', !this.form.value.currentlyWorksThere);

            var selectedJobOccupation = this.jobOccupations.find(j => j.id == jobExperience.jobOccupation.id);

            setTimeout(() => this.selectJobOccupation(selectedJobOccupation), 100);
        }

        this.form.controls.currentlyWorksThere.valueChanges.subscribe(currentlyWorksThere => {
            if (currentlyWorksThere) {
                this.form.patchValue({ endDate: null });
            }

            this.updateValidators('endDate', !this.form.value.currentlyWorksThere);
        });
    }

    filterJobOccupations(searchTerm?: string) {
        var trimmedSearchTerm = searchTerm?.trim()?.toLocaleLowerCase();

        if (trimmedSearchTerm?.length <= 0) {
            this.filteredOccupations = this.jobOccupations.slice(0, 20);
            return;
        }

        this.filteredOccupations = this.jobOccupations.filter(c => c.description?.toLocaleLowerCase().includes(trimmedSearchTerm) ||
            c.id?.toString().toLocaleLowerCase().includes(trimmedSearchTerm)).slice(0, 20);
    }

    selectJobOccupation(jobOccupation?: JobOccupation) {
        this.form.patchValue({ jobOccupation: jobOccupation });

        if (this.jobOccupationInput != null) {
            this.jobOccupationInput.nativeElement.value = jobOccupation?.description ?? '';
        }
    }

    save() {
        if (this.form.invalid || (this.form.value.currentlyWorksThere == false && this.form.value.endDate == null)) {
            this.notificationService.warning('Há campos obrigatórios não preenchidos')
            return;
        }

        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }

    updateValidators(field: string, required: boolean) {
        if (required) {
            this.form.get(field).setValidators([Validators.required]);
        }
        else {
            this.form.patchValue({ [field]: null });
            this.form.get(field).clearValidators();
        }

        this.form.get(field).updateValueAndValidity({ emitEvent: true });
    }

    setMonthAndYear(normalizedMonthAndYear: Moment, field: string, datepicker: MatDatepicker<Moment>) {
        this.form.patchValue({ [field]: new Date(normalizedMonthAndYear.year(), normalizedMonthAndYear.month(), 1) })
        datepicker.close();
    }
}
