import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-reopen-dialog',
    templateUrl: './reopen-dialog.component.html',
    styleUrls: ['./reopen-dialog.component.scss']
})
export class ReopenDialogComponent implements OnInit {

    title: string;
    message: string;
    parameter: string;
    password: string;
    show: boolean;

    constructor(public dialogRef: MatDialogRef<ReopenDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ReopenDialogModel,
        private notificationService: NotificationService, private adminService: AdminService) {
        this.title = data.title;
        this.message = data.message;
        this.parameter = data.parameter;
    }

    ngOnInit() { }

    onConfirm() {
        // Checks if typed password matches the one in the database
        this.adminService.validatePassword({ name: this.parameter, password: this.password }).subscribe({
            next: () => {
                this.dialogRef.close(true);
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    onDismiss() {
        this.dialogRef.close(false);
    }
}

export class ReopenDialogModel {
    constructor(public title: string, public message: string, public parameter: string) {
        this.title = title;
        this.message = message;
        this.parameter = parameter;
    }
}