import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Enterprise } from '../models/enterprise';
import { Employee } from '../models/employee';
import { Supplier } from '../models/supplier';
import { Period } from '../models/period';
import { Item } from '../models/item';
import { Customer } from '../models/customer';
import { Salesman } from '../models/salesman';
import { ItemConversion } from '../models/item-conversion';
import { ItemBinding } from '../models/item-binding';
import { SalesmanAccessReport } from '../models/salesman-access-report';
import { BusinessType } from '../models/business-type';
import { HCMEmployee } from '../models/hcm-employee';
import { ShippingCost } from '../models/shipping-cost';
import { FreightRate } from '../models/freight-rate';

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  constructor(private http: HttpClient) { }

  getSuppliers(params?) {
    return this.http.get<Supplier[]>(`${environment.API}suppliers`, { params }).pipe();
  }

  getSupplier(params) {
    return this.http.get<Supplier>(`${environment.API}suppliers/id`, { params }).pipe();
  }

  getItems(params?) {
    return this.http.get<Item[]>(`${environment.API}items`, { params }).pipe();
  }  

  getItemBindingByID(params) {
    return this.http.get<ItemBinding>(`${environment.API}items/bindings/id`, { params }).pipe();
  }

  getItemBindings(params?) {
    return this.http.get<ItemBinding[]>(`${environment.API}items/bindings`, { params }).pipe();
  }

  postItemBinding(body) {
    return this.http.post<any>(`${environment.API}items/binding`, body).pipe();
  }

  deleteItemBinding(params) {
    return this.http.delete<any>(`${environment.API}items/binding`, { params }).pipe();
  }

  getItemConversionByID(params) {
    return this.http.get<ItemConversion>(`${environment.API}items/conversions/id`, { params }).pipe();
  }

  getItemConversions(params?) {
    return this.http.get<ItemConversion[]>(`${environment.API}items/conversions`, { params }).pipe();
  }

  postItemConversion(body) {
    return this.http.post<any>(`${environment.API}items/conversion`, body).pipe();
  }

  putItemConversion(body) {
    return this.http.put<any>(`${environment.API}items/conversion`, body).pipe();
  }

  deleteItemConversion(params) {
    return this.http.delete<any>(`${environment.API}items/conversion`, { params }).pipe();
  }

  getEnterprises(params?) {
    return this.http.get<Enterprise[]>(`${environment.API}enterprises`, { params }).pipe();
  }

  putEnterprise(id: number, enterprise) {
    return this.http.put<any>(`${environment.API}enterprises/${id}`, enterprise).pipe();
  }

  getEmployees(params?) {
    return this.http.get<Employee[]>(`${environment.API}employees`, { params }).pipe();
  }

  getFirstEmployee(params?) {
    return this.http.get<Employee>(`${environment.API}employees/first`, { params }).pipe();
  }

  getHCMEmployees(params) {
    return this.http.get<HCMEmployee[]>(`${environment.API}HCMemployees`, { params }).pipe();
  }

  getEmployee(params?) {
    return this.http.get<Employee>(`${environment.API}employees/id`, { params }).pipe();
  }
  
  getCustomers(params?) {
    return this.http.get<Customer[]>(`${environment.API}customers`, { params }).pipe();
  }

  putEmployee(id: number, employee) {
    return this.http.put<any>(`${environment.API}employees/${id}`, employee).pipe();
  }

  putItem(id: number, item) {
    return this.http.put<any>(`${environment.API}items/${id}`, item).pipe();
  }

  getEmployeeByUser(params) {
    return this.http.get<Employee>(`${environment.API}employees/user/`, { params }).pipe();
  }  

  getPeriods(params?) {
    return this.http.get<Period[]>(`${environment.API}periods`, { params }).pipe();
  }

  getSalesmen(params?) {
    return this.http.get<Salesman[]>(`${environment.API}salesmen`, { params }).pipe();
  }

  putSalesman(id: number, salesman) {    
    return this.http.put<any>(`${environment.API}salesmen/${id}`, salesman).pipe();
  }

  getSalesmenAccessReport() {
    return this.http.get<SalesmanAccessReport[]>(`${environment.API}salesmen/access-report`).pipe();
  }

  getBusinessTypes(params?) {
    return this.http.get<BusinessType[]>(`${environment.API}customers/business-types`, { params }).pipe();
  }

  getShippingCosts(params?) {
    return this.http.get<ShippingCost[]>(`${environment.API}shippingCosts`, { params }).pipe();
  }

  putShippingCost(id: number, shippingCost) {    
    return this.http.put<any>(`${environment.API}shippingCosts/${id}`, shippingCost).pipe();
  }

  getFreightRates(params?) {
    return this.http.get<FreightRate[]>(`${environment.API}shippingCosts/freight-rates`, { params }).pipe();
  }

  putFreightRate(id: number, freightRate) {    
    return this.http.put<any>(`${environment.API}shippingCosts/freight-rates/${id}`, freightRate).pipe();
  }
}
