import * as moment from "moment";
import Translations from 'src/assets/json/EnumTranslations.json';

export class Period {
    id: number;
    enterprise: string;
    description: string;
    startDate: Date;
    endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    getDescription = () => {
        moment.locale('pt');
        return `De ${moment(this.startDate).format('l')} a ${moment(this.endDate).format('l')}`;
    }

    getStartDateMonth() {
        return `${Translations.Months[moment(this.startDate).month() + 1].toLocaleUpperCase()} ${moment(this.startDate).year()}`;
    }

    getEndDateMonth() {
        return `${Translations.Months[moment(this.endDate).month() + 1].toLocaleUpperCase()} ${moment(this.endDate).year()}`;
    }

    getYear() {
        return moment(this.startDate).year();
    }
}