import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Authentication } from '../models/authentication';
import { Role } from '../models/role';
import { User } from '../models/user';

const baseUrl = `${environment.API}users`;

@Injectable({ providedIn: 'root' })
export class UserService {

    private authenticationSubject: BehaviorSubject<Authentication>;
    public authentication: Observable<Authentication>;

    constructor(private router: Router, private http: HttpClient) {
        this.authenticationSubject = new BehaviorSubject<Authentication>(null);
        this.authentication = this.authenticationSubject.asObservable();
    }

    public get authenticationValue(): Authentication {
        return this.authenticationSubject.value;
    }

    login(body) {
        return this.http.post<any>(`${baseUrl}/authenticate`, body)
            .pipe(map(authentication => {
                this.authenticationSubject.next(authentication);
                this.storeRefreshToken();
                this.startRefreshTokenTimer();
                return authentication;
            }));
    }

    logout() {
        this.http.post<any>(`${baseUrl}/revoke-token`, { token: this.getRefreshToken() }).subscribe();
        this.stopRefreshTokenTimer();
        this.authenticationSubject.next(null);
        this.router.navigate(['/login']);
    }

    refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, { token: this.getRefreshToken() })
            .pipe(map((authentication) => {
                this.authenticationSubject.next(authentication);
                this.storeRefreshToken();
                this.startRefreshTokenTimer();
                return authentication;
            }));
    }

    forgotPassword(email: string, reCaptcha: string) {
        let origin = location.origin;
        return this.http.post(`${baseUrl}/forgot-password`, { email, reCaptcha, origin });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    changePassword(id: number, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/change-password`, { id, password, confirmPassword });
    }

    getUser(params) {
        return this.http.get<User>(`${environment.API}users/id`, { params }).pipe();
    }

    getUsers() {
        return this.http.get<User[]>(`${environment.API}users`).pipe();
    }

    putUser(id: number, user) {
        return this.http.put<any>(`${environment.API}users/${id}`, user).pipe();
    }

    postUser(user) {
        return this.http.post<User>(`${environment.API}users`, user).pipe();
    }

    deleteUser(params) {
        return this.http.delete(`${environment.API}users`, { params }).pipe();
    }

    getRelatedRoles(params) {
        return this.http.get<Role[]>(`${environment.API}users/Roles`, { params }).pipe();
    }

    uploadImage(image) {
        return this.http.post<File>(`${environment.API}users/upload-image`, image).pipe();
    }

    clearTokens(params) {
        return this.http.post<any>(`${environment.API}users/clear-tokens`, params).pipe()
    }

    updateProfile(params) {
        return this.http.post<any>(`${environment.API}users/update-profile`, params).pipe()
    }

    releaseSession(params) {
        return this.http.post<any>(`${environment.API}users/release-session`, params).pipe()
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.authenticationValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private storeRefreshToken() {
        localStorage.setItem('rft-vt', this.authenticationValue.refreshToken);
    }

    private getRefreshToken() {
        return localStorage.getItem('rft-vt');
    }
}