<div class="container">
    <label class="mat-body-2">Ajuste da imagem</label>
    <div fxLayout="row" fxLayoutAlign="start center">
        <image-cropper [imageChangedEvent]="image" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)"></image-cropper>
        <div fxLayout="column" fxLayoutAlign="center center" class="p-100">
            <img class="preview-md" [src]="croppedImage">
            <small>100px</small>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" class="p-70">
            <img class="preview-sm" [src]="croppedImage">
            <small>70px</small>
        </div>
    </div>
    <br>
    <button mat-button (click)="cancel()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="apply()">Aplicar</button>
</div>