import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SettingsService } from 'src/app/services/settings.service';
import { Cargo } from 'src/app/models/cargo';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { forkJoin } from 'rxjs';
import { Extensions } from 'src/app/helpers/extensions';
import { Status } from 'src/app/logistics/cargos/cargos.component';
import { PalletsBalance } from 'src/app/models/pallet-balance';
import Translations from 'src/assets/json/EnumTranslations.json';
import { Observable } from 'rxjs';
import { DashboardItem } from 'src/app/view-models/dashboard-item';

@Component({
  selector: 'app-dashboard-logistics',
  templateUrl: './dashboard-logistics.component.html',
  styleUrls: ['./dashboard-logistics.component.scss','../dashboards.scss']
})
export class DashboardLogisticsComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards: Observable<DashboardItem[]>;
  department = Translations.Departments.Logistics.Description;
  departmentIcon = 'local_shipping';
  logisticsData: LogisticsData = new LogisticsData();

  constructor(private breakpointObserver: BreakpointObserver, private settingsService: SettingsService, private logisticsService: LogisticsService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    let enterprise = this.settingsService.getParameter('enterprise');
    let year = this.settingsService.getSetting('year');
    let startDate = Extensions.formatDate(`${year}-01-01`);
    let endDate = Extensions.formatDate(`${year}-12-31`, true);

    let pendingCargosRequest = this.logisticsService.getCargos({ startDate: startDate, endDate: endDate, enterpriseID: enterprise, statusID: Status.Normal }); // Set params
    let outdatedCargosRequest = this.logisticsService.getCargos({ enterpriseID: enterprise, outdatedOnly: true });
    let cargosWithNoResponseRequest = this.logisticsService.getCargos({ startDate: startDate, endDate: endDate, enterpriseID: enterprise, statusID: Status.Sent });
    let palletsOutRequest = this.logisticsService.getReceiptsBalance({ negativeOnly: true });

    forkJoin([pendingCargosRequest, outdatedCargosRequest, cargosWithNoResponseRequest, palletsOutRequest]).subscribe({
      next: response => {
        this.logisticsData.PendingCargos = response[0];
        this.logisticsData.OutdatedCargos = response[1];
        this.logisticsData.WithNoResponse = response[2];
        this.logisticsData.PalletsOut = response[3].filter(l => l.balance < 0);

        this.getCardsData();
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  getCardsData() {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(() => {
        return [
          {
            index: 0,
            title: 'Cargas Pendentes',
            caption: 'Cargas onde a proposta de coleta ainda não foi enviada.',
            icon: 'pending_actions',
            count: this.logisticsData.PendingCargos?.length,
            suffix: 'Cargas',
            route: '/logistics', routeParams: this.routeParams('PendingCargos')
          },
          {
            index: 1,
            title: 'Cargas Vencidas',
            caption: 'Cargas não coletadas e com a data do agendamento atrasada.',
            icon: 'free_cancellation',
            count: this.logisticsData.OutdatedCargos?.length,
            suffix: 'Cargas',
            route: '/logistics', routeParams: this.routeParams('OutdatedCargos')
          },
          {
            index: 2,
            title: 'Agendas sem resposta',
            caption: 'Cargas com a proposta de coleta enviada, mas ainda sem agendamento.',
            icon: 'comments_disabled',
            count: this.logisticsData.WithNoResponse?.length,
            suffix: 'Cargas',
            route: '/logistics', routeParams: this.routeParams('WithNoResponse')
          },
          {
            index: 3,
            title: 'Pallets fora',
            caption: 'Pallets que ainda não retornaram.',
            icon: 'outbox',
            count: this.logisticsData.PalletsOut?.map(p => p.balance).reduce((a, b) => a + b, 0) * -1,
            suffix: 'Pallets',
            route: '/logistics/pallets', routeParams: this.routeParams('PalletsOut')
          }
        ];
      })
    );
  }

  minDate(cargos: Cargo[]) {
    if (cargos?.length < 1) {
      return undefined;
    }

    return cargos.map(c => c.date).reduce(function (a, b) { return a < b ? a : b; });
  }

  maxDate(cargos: Cargo[]) {
    if (cargos?.length < 1) {
      return undefined;
    }

    return cargos.map(c => c.date).reduce(function (a, b) { return a > b ? a : b; });
  }

  routeParams(id) {
    let params = {

      PendingCargos: {
        filter: 'Pending',
        startDate: this.minDate(this.logisticsData.PendingCargos),
        endDate: this.maxDate(this.logisticsData.PendingCargos)
      },
      OutdatedCargos: {
        filter: 'Outdated',
        startDate: this.minDate(this.logisticsData.OutdatedCargos),
        endDate: this.maxDate(this.logisticsData.OutdatedCargos)
      },
      WithNoResponse: {
        filter: 'WithNoResponse',
        startDate: this.minDate(this.logisticsData.WithNoResponse),
        endDate: this.maxDate(this.logisticsData.WithNoResponse)
      },
      PalletsOut: {}
    }

    return params[id];
  }
}

export class LogisticsData {
  PendingCargos: Cargo[];
  OutdatedCargos: Cargo[];
  WithNoResponse: Cargo[];
  PalletsOut: PalletsBalance[]
}
