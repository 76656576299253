import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SettingsService } from 'src/app/services/settings.service';
import { NotificationService } from 'src/app/services/notification.service';
import Translations from 'src/assets/json/EnumTranslations.json';
import { EChartsOption } from 'echarts';
import { AccountingService } from 'src/app/services/accounting.service';
import { Extensions } from 'src/app/helpers/extensions';
import { FinanceDashboardData } from 'src/app/models/finance-dashboard-data';
import { DashboardItem } from 'src/app/view-models/dashboard-item';
import { Observable } from 'rxjs';
import { Period } from 'src/app/models/period';
import { PeriodDialogComponent } from 'src/app/helpers/period-dialog/period-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-finance',
  templateUrl: './dashboard-finance.component.html',
  styleUrls: ['./dashboard-finance.component.scss']
})
export class DashboardFinanceComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards: Observable<DashboardItem[]>;
  department = Translations.Departments.Finance.Description;
  departmentIcon = 'attach_money';
  financeData: FinanceDashboardData = new FinanceDashboardData();
  annualExpenses: EChartsOption;
  annualInvestments: EChartsOption;
  budgetVariation: EChartsOption;
  budgetVariationPerGroup: EChartsOption;
  budgetExpensesPerGroup: EChartsOption;
  top5CostCentersVariation: EChartsOption;
  enterpriseID: number;
  period: Period;

  constructor(private breakpointObserver: BreakpointObserver, private settingsService: SettingsService, private accountingService: AccountingService,
    private notificationService: NotificationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.enterpriseID = this.settingsService.getParameter('enterprise');
    let year = this.settingsService.getSetting('year');
    let startDate = Extensions.formatDate(`${year}-01-01`);
    let endDate = Extensions.formatDate(`${year}-12-31`, true);
    this.period = new Period(new Date(startDate), new Date(endDate));

    this.getDashboardData();
  }

  getDashboardData() {
    let params = {
      startDate: Extensions.formatDate(this.period.startDate), endDate: Extensions.formatDate(this.period.endDate, true),
      enterpriseID: this.enterpriseID
    };

    this.accountingService.getDashboardData(params).subscribe({
      next: data => {
        this.financeData = data;
        this.getCardsData();
        this.getChartsData();
      },
      error: error => {
        this.financeData = new FinanceDashboardData();
        this.getCardsData();
        this.getChartsData();
        this.notificationService.error(error);
      }
    });
  }

  showPeriodDialog() {
    const dialogRef = this.dialog.open(PeriodDialogComponent, { panelClass: "dialog-large", data: { savePeriod: false } });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.period = result;
        this.getDashboardData();
      }
    });
  }

  getCardsData() {
    this.cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(() => {
        return [
          {
            index: 0,
            title: 'Orçamentos Abertos',
            caption: 'Orçamentos que ainda não foram fechados.',
            icon: 'pending_actions',
            count: this.financeData.openCostCentersCount,
            suffix: 'Orçamentos',
            route: 'accounting', routeParams: null
          },
          {
            index: 1,
            title: 'Overflow do Previsto',
            caption: 'Contas onde o realizado ultrapassou o previsto.',
            icon: 'warning_amber',
            count: this.financeData.overflowingAccountsCount,
            suffix: 'Contas',
            route: 'accounting', routeParams: null
          }
        ];
      })
    );
  }

  getChartsData() {
    this.annualExpenses = {
      title: { text: 'Despesas', left: 'center', top: 10, textStyle: { color: '#5D6D7E' } },
      legend: { data: ['Previsto', 'Realizado'], align: 'left', top: 45, textStyle: { color: '#5D6D7E' } },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">R$ ${data.value.toLocaleString("pt-BR")}</font><br>
          <div style="display: ${data.name == 'Previsto' ? 'none' : 'block'}"><b>Previsto x Realizado: </b><font size="2">${data.seriesName}</font></div>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      label: {
        alignTo: 'edge', formatter: function (data) { return `{name|${data.name}}\n{amount|R$ ${Extensions.formatMoney(data.value)}}` }, minMargin: 5,
        edgeDistance: 10, lineHeight: 15, rich: {
          amount: { fontSize: 10, color: '#808b96' },
          name: { color: '#5D6D7E' }
        }
      },
      series: [{
        name: `${(this.financeData.expensesPercentualDifference * 100).toFixed(2)}%`,
        type: 'pie', radius: [50, 90], color: ['#E14833', '#F37B6A'],
        data: [
          { value: this.financeData.budgetedExpenses, name: 'Previsto' },
          { value: this.financeData.paidExpenses, name: 'Realizado' },
        ]
      }]
    };

    this.annualInvestments = {
      title: { text: 'Investimentos', left: 'center', top: 10, textStyle: { color: '#5D6D7E' } },
      legend: { data: ['Previsto', 'Realizado'], align: 'left', top: 45, textStyle: { color: '#5D6D7E' } },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">R$ ${data.value.toLocaleString("pt-BR")}</font><br>
          <div style="display: ${data.name == 'Previsto' ? 'none' : 'block'}"><b>Previsto x Realizado: </b><font size="2">${data.seriesName}</font></div>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      label: {
        alignTo: 'edge', formatter: function (data) { return `{name|${data.name}}\n{amount|R$ ${Extensions.formatMoney(data.value)}}` }, minMargin: 5,
        edgeDistance: 10, lineHeight: 15, rich: {
          amount: { fontSize: 10, color: '#808b96' },
          name: { color: '#5D6D7E' }
        }
      },
      series: [{
        name: `${(this.financeData.investmentsPercentualDifference * 100).toFixed(2)}%`,
        type: 'pie', radius: [50, 90], color: ['#0EAD5B', '#5ED88A'],
        data: [
          { value: this.financeData.budgetedInvestments, name: 'Previsto' },
          { value: this.financeData.paidInvestments, name: 'Realizado' },
        ]
      }]
    };

    this.budgetVariation = {
      title: { text: 'Variação do Orçamento', left: 'center', top: 10, textStyle: { color: '#5D6D7E' } },
      legend: { data: ['Último ano', 'Este ano', 'Diferença'] },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">R$ ${data.value.toLocaleString("pt-BR")}</font><br>
          <div style="display: ${data.name == 'Este ano' ? 'block' : 'none'}"><b>Variação do orçamento: </b><font size="2">${data.seriesName}</font></div>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      xAxis: {
        data: [
          { value: `${new Date(this.period.startDate).getFullYear() - 1},${this.financeData.paidExpensesLY}` },
          { value: `${new Date(this.period.startDate).getFullYear()},${this.financeData.budgetedExpenses}` }
        ],
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          formatter: (values: string) => `${Extensions.formatMoney(values.split(',')[1])} (${values.split(',')[0]})`
        }
      },
      yAxis: {
        show: false
      },
      grid: {
        height: 200,
        top: 100
      },
      series: [{
        name: `${(this.financeData.percentualDifferenceYoY * 100).toFixed(2)}%`,
        type: 'bar', barWidth: 100, showBackground: true,
        color: ['#54ACFD', '#1F6DB6'],
        data: [
          { name: 'Ano anterior', value: this.financeData.paidExpensesLY },
          { name: 'Este ano', value: this.financeData.budgetedExpenses }
        ]
      }]
    };

    this.budgetVariationPerGroup = {
      title: { text: 'Variação por Grupo', left: 'center', top: 5, textStyle: { color: '#5D6D7E' } },
      legend: { data: this.financeData.budgetVariationPerGroup.map(c => c.description) },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">${data.value.toFixed(2)}%</font>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      xAxis: {
        data: this.financeData.budgetVariationPerGroup.map(c => ({ value: c.description })),
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          fontSize: 11,
          interval: 0,
        }
      },
      yAxis: {
        show: true,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: string) => `${Number.parseFloat(value).toFixed(0)}%`
        }
      },
      grid: {
        height: 200,
        top: 100,
        left: '20%'
      },
      series: [{
        type: 'bar',
        barWidth: 80,
        color: '#54ACFD',
        data: this.financeData.budgetVariationPerGroup.map(c => ({ name: c.description, value: c.variation }))
      }]
    };

    this.budgetExpensesPerGroup = {
      title: { text: 'Despesas por Grupo', left: 'center', top: 10, textStyle: { color: '#5D6D7E' } },
      legend: { data: this.financeData.budgetVariationPerGroup.map(c => c.description), align: 'left', top: 45, textStyle: { color: '#5D6D7E' } },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">R$ ${data.value.toLocaleString("pt-BR")}</font><br>
          <font size=2>Percentual: ${data.percent}%</font>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      label: {
        alignTo: 'edge', formatter: function (data) { return `{name|${data.name}}\n{amount|R$ ${Extensions.formatMoney(data.value)}}` }, minMargin: 5,
        edgeDistance: 10, lineHeight: 15, rich: {
          amount: { fontSize: 10, color: '#808b96' },
          name: { color: '#5D6D7E' }
        }
      },
      series: [{
        type: 'pie', radius: [50, 90], 
        color: ['#E14833', '#F37B6A', '#B5322D'],
        data: this.financeData.budgetVariationPerGroup.map(e => ({ value: e.amount, name: e.description }))
      }]
    };

    this.top5CostCentersVariation = {
      title: { text: 'Centros de Custo\ncom maior variação', left: 'center', top: 5, textStyle: { color: '#5D6D7E' } },
      legend: { data: this.financeData.top5CostCentersWithHighestVariation.map(c => c.description) },
      tooltip: {
        trigger: 'item',
        formatter: function (data) {
          return `<span style="padding-right: 3px; margin-right: 4px; background-color: ${data.color}"></span>
          <b>${data.name}:</b> <font size="2">${data.value.toFixed(2)}%</font>`
        }, backgroundColor: '#1e1e2fce', borderWidth: 0, textStyle: { color: '#f5f5f5e6' }, borderRadius: 0
      },
      xAxis: {
        show: true,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: string) => `${Number.parseFloat(value).toFixed(0)}%`
        }
      },
      yAxis: {
        type: 'category',
        inverse: true,
        data: this.financeData.top5CostCentersWithHighestVariation.map(c => ({ value: c.description })),
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          fontSize: 11,
          formatter: function (value) {
            return value.length > 25 ? value.substring(0, 25) + '...' : value;
        },
        }
      },
      grid: {
        height: 200,
        top: 100,
        containLabel: true
      },
      series: [{
        type: 'bar',
        color: '#54ACFD',
        data: this.financeData.top5CostCentersWithHighestVariation.map(c => ({ name: c.description, value: c.variation }))
      }]
    };
  }
}