import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification, NotificationType } from 'src/app/view-models/notification';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  title: string;
  message: string;
  icon: string;
  iconColor: string;

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public notification: Notification) {

    const titles = {
      [NotificationType.Error]: 'Oops! Ocorreu um erro',
      [NotificationType.Info]: 'Informação',
      [NotificationType.Warning]: 'Aviso'
    }

    const icons = {
      [NotificationType.Error]: 'error',
      [NotificationType.Info]: 'info',
      [NotificationType.Warning]: 'warning'
    }

    const colors = {
      [NotificationType.Error]: 'warn',
      [NotificationType.Info]: 'primary',
      [NotificationType.Warning]: 'accent'
    }

    this.title = titles[notification.type];
    this.message = notification.message;
    this.icon = icons[notification.type];    
    this.iconColor = colors[notification.type];
  }

  ngOnInit() {
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
