import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CropImageComponent } from "src/app/helpers/crop-image/crop-image.component";
import { Extensions } from "src/app/helpers/extensions";
import { Authentication } from "src/app/models/authentication";
import { User } from "src/app/models/user";
import { NotificationService } from "src/app/services/notification.service";
import { SettingsService } from "src/app/services/settings.service";
import { UserService } from "src/app/services/user.service";
import Translations from 'src/assets/json/EnumTranslations.json';

@Component({
    selector: 'app-update-profile',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.scss']
})

export class UpdateProfileComponent implements OnInit {
    loggedUser: Authentication;
    user: User;
    department: any;
    departments: any[];
    step: number = 0;

    constructor(private dialogRef: MatDialogRef<UpdateProfileComponent>, private notificationService: NotificationService, private userService: UserService,
        private settingsService: SettingsService, private dialog: MatDialog) { }

    ngOnInit() {
        this.loggedUser = this.userService.authenticationValue;
        this.departments = Object.keys(Translations.Departments).map(key => Translations.Departments[key]).filter(i => i.Internal);
    }

    changeProfilePicture(e) {
        let file: File = e.target.files[0];

        if (file && !['image/jpeg', 'image/png'].includes(file.type)) {
            this.notificationService.error('Tipo de arquivo não compatível.');
            return;
        }

        const dialogRef = this.dialog.open(CropImageComponent, { panelClass: "dialog-large", disableClose: true, data: { image: e } });

        dialogRef.afterClosed().subscribe(picture => {
            if (picture) {
                this.userService.uploadImage(picture).subscribe({
                    next: () => {
                        this.notificationService.sucess('Imagem atualizada.');
                        this.getUserPicture();
                    },
                    error: error => {
                        this.notificationService.error(error);
                    }
                });
            }
        });
    }

    getUserPicture() {
        this.userService.getUser({ id: this.loggedUser.id }).subscribe({
            next: user => {
                this.user = user;
                this.user.image = this.user?.image?.length > 0 ? Extensions.convertImage(this.user.image) : null;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    nextStep(step: number) {
        this.step = step;
    }

    save() {
        if (!this.department) {
            this.notificationService.warning('Selecione o departamento.');
            return;
        }

        this.userService.updateProfile({ department: this.department.ID }).subscribe({
            next: () => {
                this.userService.authenticationValue.isProfileComplete = true;
                this.userService.authenticationValue.department = this.department.ID;
                this.nextStep(3);
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    close() {
        this.dialogRef.close({ department: this.department.ID });
    }
}