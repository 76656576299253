import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JobOccupation } from "../models/job-occupation";
import { environment } from "src/environments/environment";
import { Language } from "../models/language";
import { InterestArea } from "../models/interest-area";
import { JobApplication } from "../models/job-application";
import { VerifyExistingJobApplication } from "../models/verify-existing-job-application";
import { HumanResourcesDashboardData } from "../models/human-resources-dashboard-data";
import { HCMOccurrence } from "../models/hcm-occurrence";
import { TimeKeepingOccurrence } from "../models/time-keeping-occurrence";
import { HCMOccurrenceClockRecord } from "../models/hcm-occurrence-clock-record";
import { HCMOccurrenceClockEvent } from "../models/hcm-occurrence-clock-event";

@Injectable({
    providedIn: 'root'
})
export class HumanResourcesService {

    constructor(private http: HttpClient) { }

    getJobApplications(params?) {
        return this.http.get<JobApplication[]>(`${environment.API}jobApplications`, { params }).pipe();
    }

    getJobApplicationByID(params?) {
        return this.http.get<JobApplication>(`${environment.API}jobApplications/id`, { params }).pipe();
    }

    getJobApplicationByToken(params?) {
        return this.http.get<JobApplication>(`${environment.API}jobApplications/token`, { params }).pipe();
    }

    createJobApplication(body) {
        return this.http.post<any>(`${environment.API}jobApplications`, body).pipe();
    }

    updateJobApplication(body) {
        return this.http.put<any>(`${environment.API}jobApplications`, body).pipe();
    }

    deleteJobApplication(params) {
        return this.http.delete<any>(`${environment.API}jobApplications`, { params }).pipe();
    }

    verifyExistingJobApplication(params) {
        return this.http.get<VerifyExistingJobApplication>(`${environment.API}jobApplications/exists`, { params }).pipe();
    }

    sendJobApplicationUpdateToken(body) {
        return this.http.post<any>(`${environment.API}jobApplications/send-token`, body).pipe();
    }

    getJobOccupations(params?) {
        return this.http.get<JobOccupation[]>(`${environment.API}jobApplications/occupations`, { params }).pipe();
    }

    getLanguages(params?) {
        return this.http.get<Language[]>(`${environment.API}jobApplications/languages`, { params }).pipe();
    }

    getInterestAreas(params?) {
        return this.http.get<InterestArea[]>(`${environment.API}interestAreas`, { params }).pipe();
    }

    createInterestArea(body) {
        return this.http.post<any>(`${environment.API}interestAreas`, body).pipe();
    }

    updateInterestArea(interestAreaID: number, body) {
        return this.http.put<any>(`${environment.API}interestAreas/${interestAreaID}`, body).pipe();
    }

    deleteInterestArea(params) {
        return this.http.delete<any>(`${environment.API}interestAreas`, { params }).pipe();
    }

    getDashboardData(params?) {
        return this.http.get<HumanResourcesDashboardData>(`${environment.API}jobApplications/dashboard`, { params }).pipe();
    }

    getHCMOccurrences(params) {
        return this.http.get<HCMOccurrence[]>(`${environment.API}TimeKeepingOccurrences/hcm-occurrences`, { params }).pipe();
    }

    getHCMOccurrenceClockRecords(params) {
        return this.http.get<HCMOccurrenceClockRecord[]>(`${environment.API}TimeKeepingOccurrences/hcm-occurrences/clock-records`, { params }).pipe();
    }

    getHCMOccurrenceClockEvents(params) {
        return this.http.get<HCMOccurrenceClockEvent[]>(`${environment.API}TimeKeepingOccurrences/hcm-occurrences/clock-events`, { params }).pipe();
    }

    getTimeKeepingOccurrences(params) {
        return this.http.get<TimeKeepingOccurrence[]>(`${environment.API}TimeKeepingOccurrences`, { params }).pipe();
    }

    generateTimeKeepingOccurrences(body) {
        return this.http.post<any>(`${environment.API}TimeKeepingOccurrences/generate`, body).pipe();
    }

    createTimeKeepingOccurrence(body) {
        return this.http.post<any>(`${environment.API}TimeKeepingOccurrences`, body).pipe();
    }

    deleteManualTimeKeepingOccurrence(params) {
        return this.http.delete<any>(`${environment.API}TimeKeepingOccurrences/manual`, { params }).pipe();
    }

    deleteTimeKeepingOccurrence(params) {
        return this.http.delete<any>(`${environment.API}TimeKeepingOccurrences`, { params }).pipe();
    }

    getIgnoredOccurrences(params) {
        return this.http.get<any>(`${environment.API}ignoredOccurrences`, { params }).pipe();
    }

    generateIgnoredOccurrences(body) {
        return this.http.post<any>(`${environment.API}ignoredOccurrences/generate`, body).pipe();
    }

    deleteIgnoredOccurrence(params) {
        return this.http.delete<any>(`${environment.API}ignoredOccurrences`, { params }).pipe();
    }
}