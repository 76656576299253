import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit {
    accepted: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TermsComponent>) { }

    ngOnInit() { }

    finish() {
        this.dialogRef.close();
    }
}
