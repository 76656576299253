import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { JobApplicationEducation } from 'src/app/models/job-application-education';
import { NotificationService } from 'src/app/services/notification.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-add-education',
    templateUrl: './add-education.component.html',
    styleUrls: ['./add-education.component.scss'],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS
    }]
})

export class AddEducationComponent implements OnInit {
    form: UntypedFormGroup;
    educationLevels: any[];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<AddEducationComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.educationLevels = this.data?.educationLevels ?? [];

        this.form = new UntypedFormGroup({
            institution: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            level: new UntypedFormControl(null, [Validators.required]),
            degree: new UntypedFormControl(null, [Validators.required, Validators.maxLength(70)]),
            startDate: new UntypedFormControl(null, Validators.required),
            endDate: new UntypedFormControl(null, [Validators.required])
        });

        let education = this.data?.education as JobApplicationEducation;

        if (education != null) {
            this.form.patchValue({
                institution: education.institution, level: education.level, degree: education.degree,
                startDate: education.startDate, endDate: education.endDate,
            });

            let educationLevel = this.educationLevels.find(l => l.code == education.level);

            if (educationLevel != null && educationLevel.preAssignedCourse != null) {
                this.form.patchValue({ degree: educationLevel.preAssignedCourse });
                this.form.controls.degree.disable();
            }
        }

        this.form.controls.level.valueChanges.subscribe(level => {
            let educationLevel = this.educationLevels.find(l => l.code == level);

            if (educationLevel != null) {
                if (educationLevel.preAssignedCourse != null) {
                    this.form.patchValue({ degree: educationLevel.preAssignedCourse });
                    this.form.controls.degree.disable();
                }
                else {
                    this.form.patchValue({ degree: null });
                    this.form.controls.degree.enable();
                }
            }
        });
    }

    save() {
        if (this.form.invalid) {
            this.notificationService.warning('Há campos obrigatórios não preenchidos')
            return;
        }

        this.form.controls.degree.enable();
        this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }

    setMonthAndYear(normalizedMonthAndYear: Moment, field: string, datepicker: MatDatepicker<Moment>) {
        this.form.patchValue({ [field]: new Date(normalizedMonthAndYear.year(), normalizedMonthAndYear.month(), 1) })
        datepicker.close();
    }
}
