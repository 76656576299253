import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Translations from 'src/assets/json/EnumTranslations.json';
import { UserTypes } from '../admin/users/add-user/add-user.component';
import { UserService } from '../services/user.service';
import { UpdateProfileComponent } from '../settings/update-profile/update-profile.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  department: string;

  constructor(private userService: UserService, private dialog: MatDialog) { }

  ngOnInit() {
    let user = this.userService.authenticationValue;

    if (user.type == UserTypes.Employee) {
      if (!user.isProfileComplete) {
        const dialogRef = this.dialog.open(UpdateProfileComponent, { panelClass: "dialog-large", disableClose: true });

        dialogRef.afterClosed().subscribe(result => {
          this.department = result.department;
        });
      }
      else {
        this.department = user.department;
      }
    }
    else if (user.type == UserTypes.Salesman) {
      this.department = Translations.Departments.Salesman.ID;

      if (!user.isProfileComplete) {
        this.userService.updateProfile({ department: this.department }).subscribe({
          next: () => { },
          error: () => { }
        });
      }
    }
  }

  get Translations(): typeof Translations {
    return Translations;
  }

}
