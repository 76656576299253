import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: []
})
export class StatusChipComponent {

  @Input()
  get text(): string { return this._text; }
  set text(text: string) {
    this._text = (text && text.trim()) || '<no text set>';
  }
  private _text = '';

  get color(): string { return this.colors[this.text]; }

  private colors = {
    ['Normal']: 'other',
    ['Enviado']: 'other',
    ['Pré-Agendado']: 'info',
    ['Agendado']: 'success',
    ['Fechado']: 'warning',
    ['Inutilizado']: 'danger',
    ['Conectado']: 'success',
    ['Desconectado']: 'danger',
    ['Aberto']: 'success',
    ['Excluído']: 'danger',
    ['Ativo']: 'success',
    ['Expirado']: 'danger',
    ['Revogado']: 'warning',
    ['Bloqueado']: 'warning'
  }
}
