<mat-dialog-content>
  <h1 class="mat-headline">Programação</h1>
  <mat-divider></mat-divider>
  <h4 class="mat-small" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-icon>pending_actions</mat-icon>
    <label>Os itens abaixo representam os agendamentos ativos para o período selecionado.</label>
  </h4>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxlayout="row" fxLayoutGap="5px">
      <mat-form-field>
        <mat-label>Escolha um período</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Data inicial">
          <input matEndDate formControlName="end" placeholder="Data final">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancelar</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyDate()">Aplicar</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tipo de Relatório</mat-label>
        <mat-select [value]="report" #selectedReport>
          <mat-option *ngFor="let rep of reports" [value]="rep">{{ rep }}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button (click)="getReportData(selectedReport.value)">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <button mat-icon-button matTooltip="Exportar" [matMenuTriggerFor]="menu" [disabled]="dataSource.data.length <= 0">
      <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="exportToPDF()">
        <mat-icon>picture_as_pdf</mat-icon>
        <span>PDF</span>
      </button>
      <button mat-menu-item (click)="exportToExcel()">
        <mat-icon>description</mat-icon>
        <span>Excel</span>
      </button>
    </mat-menu>
  </div>

  <table id="tb-itens" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <label *ngIf="column == 'date'">Data da Geração</label>
        <label *ngIf="column == 'item'">Item</label>
        <label *ngIf="column == 'cargo'">Carga</label>
        <label *ngIf="column == 'quantity'">Quantidade</label>
        <label *ngIf="column == 'schedule'">Agendamento</label>
      </th>
      <td mat-cell *matCellDef="let element">
        <label *ngIf="column == 'date'">{{ element.date | date:"dd/MM/yyyy" }}</label>
        <label *ngIf="column == 'item'">{{ element.item }}</label>
        <label *ngIf="column == 'cargo'">{{ element.description }}</label>
        <label *ngIf="column == 'quantity'">{{ element.quantity | number }}</label>
        <label *ngIf="column == 'schedule'">{{ element.cargoSchedule | date:"dd/MM/yyyy" }}</label>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <label *ngIf="column == 'item'">TOTAL</label>
        <label *ngIf="column == 'quantity'">{{ getTotalQuantity() | number }}</label>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"
      [ngStyle]="{'display': report == ReportTypes.Items ? '' : 'none'}"></tr>
  </table>

</mat-dialog-content>