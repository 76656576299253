import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { Role } from 'src/app/models/role';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

  role: Role;
  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditRoleComponent>, private service: AdminService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.role = this.data.role;
    this.form = this.formBuilder.group({ description: [this.role.description, Validators.required] });
  }

  save() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.role.description = this.form.value.description;
    this.service.putRole(this.role.id, this.role)
      .subscribe({
        next: () => {
          this.dialogRef.close("done");
        },
        error: error => {
          this.dialogRef.close(error);
        }
      });
  }
}
