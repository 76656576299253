import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Authentication } from 'src/app/models/authentication';
import { Cargo } from 'src/app/models/cargo';
import { CargoItem } from 'src/app/models/cargo-item';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-send-cargo-schedule',
  templateUrl: './send-cargo-schedule.component.html',
  styleUrls: ['./send-cargo-schedule.component.scss']
})
export class SendCargoScheduleComponent implements OnInit {

  loggedUser: Authentication;
  displayedColumns: string[] = ['status', 'item', 'quantity', 'totalWeight', 'edit'];
  dataSource = new MatTableDataSource<CargoItem>();
  cargo: Cargo;
  form: UntypedFormGroup;
  loading: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SendCargoScheduleComponent>, private service: LogisticsService,
    private notificationService: NotificationService, private userService: UserService) {
    this.cargo = this.data.cargo;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      comments: new UntypedFormControl(),
      palletsCount: new UntypedFormControl()
    })
    this.dataSource.data = this.cargo.groupedItems;

    this.service.getSchedule({ cargoID: this.cargo.id }).subscribe({
      next: schedule => {
        this.form.patchValue({ email: schedule?.recipient, palletsCount: schedule?.palletsCount });
      },
      error: error => {
        this.notificationService.error(error);
      }
    });

    this.userService.authentication.subscribe({
      next: data => {
        this.loggedUser = data;
      },
      error: error => {
        this.loggedUser.name = error;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getIcon(item: CargoItem) {
    return item.isBlocked ? 'block' : 'check_circle';
  }

  getButtonLabel(item: CargoItem) {
    return item.isBlocked ? 'Liberar' : 'Bloquear';
  }

  editItem(item: CargoItem) {
    item.isBlocked = !item.isBlocked;
    this.service.putCargoItems(this.cargo.id, item.itemID, item.isBlocked).subscribe({
      next: () => {
        this.cargo.totalWeightWithoutBlocked = this.cargo.groupedItems.filter(i => !i.isBlocked).map(i => i.totalWeight).reduce((a, b) => a + b, 0);
      },
      error: error => {
        this.notificationService.error(error);
      }
    })
  }

  send() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.service.sendScheduleRequest(this.cargo.id, this.form.value.email, this.loggedUser.email, this.form.value.comments, this.form.value.palletsCount)
      .subscribe({
        next: () => {
          this.dialogRef.close("done");
          this.loading = false;
        },
        error: error => {
          this.notificationService.error(error);
          this.loading = false;
        }
      })
  }

}
