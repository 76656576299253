import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Directive({
  selector: '[DragAndDropDirective]'
})
export class DragAndDropDirective {
  constructor(private notificationService: NotificationService) { }

  @HostBinding('class.animate-shake') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 1) {
      this.notificationService.error('Apenas um arquivo pode ser enviado por vez.')
    }
    else if (files.length > 0) {
      this.fileDropped.emit({ target: { files: files } });
    }
  }
}