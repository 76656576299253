import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CostCenter } from '../models/cost-center';
import { FinancialAccount } from '../models/financial-account';
import { Budget } from '../models/budget';
import { Account } from '../models/account';
import { BudgetEntry } from '../models/budget-entry';
import { BookEntry } from '../models/book-entry';
import { BudgetEntryValues } from '../models/budget-entry-values';
import { BudgetReport } from '../models/budget-report';
import { AdjustmentProject } from '../models/adjustment-project';
import { InterestRatioBaseData } from '../models/interest-ratio-base-data';
import { FinanceDashboardData } from '../models/finance-dashboard-data';
import { AdjustmentEntry } from '../models/adjustment-entry';
import { FundopemReport } from '../models/fundopem-report';
import { SalarySimulation } from '../models/salary-simulation';
import { BudgetSeachResult } from '../models/budget-search-result';
import { OutcomeDistributionRange } from '../models/outcome-distribution-range';
import { GoalAchievement } from '../models/goal-achievement';
import { ProfitsSharingReport } from '../models/profits-sharing-report';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor(private http: HttpClient) { }

  //Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  getCostCenters() {
    return this.http.get<CostCenter[]>(`${environment.API}costCenters`).pipe();
  }

  getRelatedFinancialAccountParents(params) {
    return this.http.get<FinancialAccount[]>(`${environment.API}costCenters/FinancialAccountParents`, { params }).pipe();
  }

  getRelatedFinancialAccounts(params) {
    return this.http.get<FinancialAccount[]>(`${environment.API}costCenters/FinancialAccounts`, { params }).pipe();
  }

  getAccountsByCostCenter(params) {
    return this.http.get<Account[]>(`${environment.API}costCenters/Accounts`, { params }).pipe();
  }

  putCostCenter(id: number, costCenter) {
    return this.http.put<any>(`${environment.API}costCenters/${id}`, costCenter).pipe();
  }

  getFinancialAccount(params) {
    return this.http.get<FinancialAccount>(`${environment.API}financialAccounts/id`, { params }).pipe();
  }

  getFinancialAccounts() {
    return this.http.get<FinancialAccount[]>(`${environment.API}financialAccounts`).pipe();
  }

  getFinancialAccountsByLevel(params) {
    return this.http.get<FinancialAccount[]>(`${environment.API}financialAccounts/levels`, { params }).pipe();
  }

  getFinancialAccountsByParent(params) {
    return this.http.get<FinancialAccount[]>(`${environment.API}financialAccounts/parents`, { params }).pipe();
  }

  getRelatedAccounts(params) {
    return this.http.get<Account[]>(`${environment.API}financialAccounts/Accounts`, { params }).pipe();
  }

  putFinancialAccount(id: number, financialAccount) {
    return this.http.put<any>(`${environment.API}financialAccounts/${id}`, financialAccount).pipe();
  }

  deleteFinancialAccount(params) {
    return this.http.delete<FinancialAccount>(`${environment.API}financialAccounts`, { params }).pipe();
  }

  getAccounts() {
    return this.http.get<Account[]>(`${environment.API}Accounts`).pipe();
  }

  getBudgets(params) {
    return this.http.get<Budget[]>(`${environment.API}budgets`, { params }).pipe();
  }

  getBudgetEntries(params) {
    return this.http.get<BudgetEntry[]>(`${environment.API}budgets/entries`, { params }).pipe();
  }

  getBudgetEntry(params) {
    return this.http.get<BudgetEntry>(`${environment.API}budgets/entries/id`, { params }).pipe();
  }

  getBudgetEntryValues(params) {
    return this.http.get<BudgetEntryValues>(`${environment.API}budgets/values`, { params }).pipe();
  }

  postBudgetEntry(budgetEntry) {
    return this.http.post<BudgetEntry>(`${environment.API}budgets`, budgetEntry).pipe();
  }

  putBudgetEntry(id: number, budgetEntry) {
    return this.http.put<any>(`${environment.API}budgets/${id}`, budgetEntry).pipe();
  }

  deleteBudgetEntry(params) {
    return this.http.delete<BudgetEntry>(`${environment.API}budgets`, { params }).pipe();
  }

  getBookEntries(params) {
    return this.http.get<BookEntry[]>(`${environment.API}bookEntries`, { params }).pipe();
  }

  closeCostCenter(params) {
    return this.http.post<BudgetEntry>(`${environment.API}costCenters/finalize`, params).pipe();
  }

  getBudgetReport(params) {
    return this.http.get<BudgetReport[]>(`${environment.API}budgets/data-report`, { params }).pipe();
  }

  getAdjustmentProjects(params) {
    return this.http.get<AdjustmentProject[]>(`${environment.API}adjustmentProjects`, { params }).pipe();
  }

  getAdjustmentProject(params) {
    return this.http.get<AdjustmentProject>(`${environment.API}adjustmentProjects/id`, { params }).pipe();
  }

  postAdjustmentProject(project) {
    return this.http.post<AdjustmentProject>(`${environment.API}adjustmentProjects`, project).pipe();
  }

  getAdjustmentEntry(params) {
    return this.http.get<AdjustmentEntry>(`${environment.API}adjustmentProjects/entries`, { params }).pipe();
  }

  postAdjustmentEntry(entry) {
    return this.http.post<AdjustmentEntry>(`${environment.API}adjustmentProjects/entries`, entry).pipe();
  }

  getInterestRatioBaseData(params) {
    return this.http.get<InterestRatioBaseData[]>(`${environment.API}adjustmentProjects/base-data`, { params }).pipe();
  }

  postIPCA(ipca) {
    return this.http.post<any>(`${environment.API}adjustmentProjects/accruals`, ipca).pipe();
  }

  getFundopemReport(params) {
    return this.http.get<FundopemReport[]>(`${environment.API}adjustmentProjects/report`, { params }).pipe();
  }

  getDashboardData(params) {
    return this.http.get<FinanceDashboardData>(`${environment.API}budgets/dashboard`, { params }).pipe();
  }

  askForSalarySimulationData(body) {
    return this.http.post<SalarySimulation[]>(`${environment.API}budgets/salary-simulation`, body).pipe();
  }

  createSalarySimulationEntries(body) {
    return this.http.post<any>(`${environment.API}budgets/salary-entries`, body).pipe();
  }

  deleteSalarySimulationEntries(params) {
    return this.http.delete<any>(`${environment.API}budgets/salary-entries`, { params }).pipe();
  }

  searchBudgetEntries(params) {
    return this.http.get<BudgetSeachResult[]>(`${environment.API}budgets/search`, { params }).pipe();
  }

  getOutcomeDistributionRanges(params) {
    return this.http.get<OutcomeDistributionRange[]>(`${environment.API}outcomeDistributionRanges`, { params }).pipe();
  }

  createOutcomeDistributionRange(body) {
    return this.http.post<any>(`${environment.API}outcomeDistributionRanges`, body).pipe();
  }

  updateOutcomeDistributionRange(id: number, body) {
    return this.http.put<any>(`${environment.API}outcomeDistributionRanges/${id}`, body).pipe();
  }

  deleteOutcomeDistributionRange(params) {
    return this.http.delete<any>(`${environment.API}outcomeDistributionRanges`, { params }).pipe();
  }

  getGoalAchievements(params) {
    return this.http.get<GoalAchievement[]>(`${environment.API}goalAchievements`, { params }).pipe();
  }

  createGoalAchievement(body) {
    return this.http.post<any>(`${environment.API}goalAchievements`, body).pipe();
  }

  updateGoalAchievement(id: number, body) {
    return this.http.put<any>(`${environment.API}goalAchievements/${id}`, body).pipe();
  }

  getProfitsSharingReport(params) {
    return this.http.get<ProfitsSharingReport[]>(`${environment.API}profitsSharingReport`, { params }).pipe();
  }
}
