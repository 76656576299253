import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { from, groupBy, mergeMap, toArray } from 'rxjs';
import { SummarizedPalletization } from 'src/app/models/summarized-palletization';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-pallets-info',
  templateUrl: './pallets-info.component.html',
  styleUrls: ['./pallets-info.component.scss']
})
export class PalletsInfoComponent implements OnInit {
  displayedColumns = ['description', 'quantity'];
  dataSource = new MatTableDataSource<Row>();

  constructor(private dialogRef: MatDialogRef<PalletsInfoComponent>, private logisticsService: LogisticsService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.logisticsService.getSummarizedPalletization().subscribe({
      next: summarizedPalletization => {
        this.updateDataSource(summarizedPalletization);
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  updateDataSource(summarizedPalletization: SummarizedPalletization[]) {
    let dataSource = [];

    from(summarizedPalletization).pipe(groupBy(i => i.category), mergeMap(group => group.pipe(toArray())))
      .subscribe(parents => {
        dataSource.push({ description: parents[0].category, parent: parents[0].category } as Row);

        from(parents).pipe(groupBy(i => i.family), mergeMap(group => group.pipe(toArray())))
          .subscribe(children => dataSource.push({
            description: children[0].family,
            parent: children[0].category,
            quantity: children[0].quantity
          } as Row));
      });

    this.dataSource.data = dataSource;
  }

  close() {
    this.dialogRef.close();
  }
}

export interface Row {
  description: any;
  parent?: any;
  quantity?: number;
}
