import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/helpers/confirm-dialog/confirm-dialog.component';
import { Extensions } from 'src/app/helpers/extensions';
import { Cargo } from 'src/app/models/cargo';
import { LogisticsService } from 'src/app/services/logistics.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SettingsService } from 'src/app/services/settings.service';
import { CargoScheduleReportComponent } from './cargo-schedule-report/cargo-schedule-report.component';
import { SendCargoScheduleComponent } from './send-cargo-schedule/send-cargo-schedule.component';

@Component({
  selector: 'app-cargos',
  templateUrl: './cargos.component.html',
  styleUrls: ['./cargos.component.scss']
})
export class CargosComponent implements OnInit {

  dataSource = new MatTableDataSource<Cargo>();
  displayedColumns: string[] = ['description', 'date', 'schedule', 'totalWeight', 'status', 'actions'];
  canScheduleStatuses = ['Normal', 'Enviado'];
  readOnlyStatuses = ['Fechado', 'Inutilizado'];
  activeFilter = CargoFilter.All;
  filters: any[] = Object.keys(CargoFilter).map(key => CargoFilter[key]);

  range = new UntypedFormGroup({
    start: new UntypedFormControl(this.settingsService.getParameter('startDate')),
    end: new UntypedFormControl(this.settingsService.getParameter('endDate'))
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private service: LogisticsService, private notificationService: NotificationService, private settingsService: SettingsService, private dialog: MatDialog,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const filter = this.route.snapshot.queryParams['filter'];
    const startDate = this.route.snapshot.queryParams['startDate'];
    const endDate = this.route.snapshot.queryParams['endDate'];

    if (filter) {
      this.activeFilter = CargoFilter[filter];
    }

    if (startDate && endDate) {
      this.range.patchValue({ start: startDate, end: endDate });
    }

    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.getCargos();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'schedule':
          const scheduleDate = new Date(item.cargoSchedule);
          return scheduleDate;
        default:
          return item[property];
      }
    }
  }

  getCargos = () => {
    let params;
    let enterprise = this.settingsService.getParameter('enterprise');

    switch (this.activeFilter) {
      case CargoFilter.All:
        params = { startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise };
        break;
      case CargoFilter.Pending:
        params = { startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise, statusID: Status.Normal };
        break;
      case CargoFilter.Outdated:
        params = { enterpriseID: enterprise, outdatedOnly: true };
        break;
      case CargoFilter.Scheduled:
        params = { startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise, isSchedule: true };
        break;
      case CargoFilter.WithNoResponse:
        params = { startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise, statusID: Status.Sent };
        break;
      case CargoFilter.Closed:
        params = { startDate: Extensions.formatDate(this.range.value.start), endDate: Extensions.formatDate(this.range.value.end, true), enterpriseID: enterprise, isFinalized: true };
        break;
    }

    this.service.getCargos(params).subscribe({
      next: cargos => {
        this.dataSource.data = cargos;
      },
      error: error => {
        this.notificationService.error(error);
      }
    })
  }

  applyFilter = (value: string) => {
    this.dataSource.filter = value.trim().replace(/[^A-Za-z0-9]/, "").toLocaleLowerCase();
  }

  applyDate() {
    let d1 = Extensions.formatDate(this.range.value.start);
    let d2 = Extensions.formatDate(this.range.value.end, true);
    this.settingsService.setParameter('startDate', d1);
    this.settingsService.setParameter('endDate', d2);
    this.getCargos();
  }

  showSchedulesReport() {
    this.dialog.open(CargoScheduleReportComponent, { panelClass: "dialog-extra-large" });
  }

  showCargoDetails(cargo?: Cargo) {
    this.router.navigate(['logistics/cargos', cargo.id]);
  }

  showScheduleDialog(cargo?: Cargo) {
    const dialogRef = this.dialog.open(SendCargoScheduleComponent, { panelClass: "dialog-extra-large", data: { cargo } });

    dialogRef.afterClosed()
      .subscribe(
        result => {
          if (result == 'done') {
            this.notificationService.sucess('Solicitação enviada com sucesso.');
            this.getCargos();
          }
        });
  }

  restart(cargo?: Cargo) {
    const message = `Deseja realmente retornar a carga para a situação <b>Normal</b>?<br>
    Esta ação irá inutilizar o link de agendamento da carga.`;
    const dialogData = new ConfirmDialogModel("Reinicializar a carga", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let modifiedCargo = { id: cargo.id, statusID: Status.Normal, invalidateLink: true };
        this.service.putCargo(cargo.id, modifiedCargo).subscribe({
          next: () => {
            this.notificationService.sucess('Carga reinicializada.');
            this.getCargos();
          },
          error: error => this.notificationService.error(error)
        });
      }
    });
  }

  cancel(cargo?: Cargo) {
    const message = `Deseja realmente inutilizar a carga?`;
    const dialogData = new ConfirmDialogModel("Inutilizar", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let modifiedCargo = { id: cargo.id, statusID: Status.Cancelled };
        this.service.putCargo(cargo.id, modifiedCargo).subscribe({
          next: () => {
            this.notificationService.sucess('Carga inutilizada.');
            this.getCargos();
          },
          error: error => this.notificationService.error(error)
        });
      }
    });
  }

  finalize(cargo?: Cargo) {
    const message = `Deseja realmente finalizar a carga?`;
    const dialogData = new ConfirmDialogModel("Finalizar", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let modifiedCargo = { id: cargo.id, statusID: Status.Closed };
        this.service.putCargo(cargo.id, modifiedCargo).subscribe({
          next: () => {
            this.notificationService.sucess('Carga finalizada.');
            this.getCargos();
          },
          error: error => this.notificationService.error(error)
        });
      }
    });
  }

  getTotalEntries() {
    return (this.dataSource.filteredData || this.dataSource.data).length;
  }

  get Filter(): typeof CargoFilter {
    return CargoFilter;
  }
}

export enum Status {
  Normal = 1,
  Cancelled = 3,
  Open = 7,
  Closed = 8,
  Sent = 10,
  PreScheduled = 11,
  Scheduled = 12
}

export enum CargoFilter {
  All = 'Todas',
  Pending = 'Pendentes',
  Scheduled = 'Agendadas',
  Outdated = 'Vencidas',
  WithNoResponse = 'Sem resposta',
  Closed = 'Finalizadas'
}
