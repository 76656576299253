import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './view-models/material.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { HomeComponent } from './home/home.component';
import { registerLocaleData } from '@angular/common';
import { refreshTokenProvider, settingsProvider } from './helpers/app.initializer';
import { HeaderComponent } from './header/header.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { LoginComponent } from './public/login/login.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { ScheduleCargoComponent } from './public/schedule-cargo/schedule-cargo.component';
import { EditScheduleCargoItemComponent } from './public/schedule-cargo/edit-schedule-cargo-item/edit-schedule-cargo-item.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { HttpRequestInterceptor } from './helpers/http-request.interceptor';
import { NgxMaskModule } from 'ngx-mask';
import ptBr from '@angular/common/locales/pt';
import { DashboardLogisticsComponent } from './home/dashboard-logistics/dashboard-logistics.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardITComponent } from './home/dashboard-it/dashboard-it.component';
import { DashboardFinanceComponent } from './home/dashboard-finance/dashboard-finance.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomMatPaginatorIntl } from './custom-materials/custom-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxEchartsModule } from 'ngx-echarts';
import { DashboardSalesComponent } from './home/dashboard-sales/dashboard-sales.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { DashboardSalesmenComponent } from './home/dashboard-salesmen/dashboard-salesmen.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HelpersModule } from './helpers/helpers.module';
import { PalletsInfoComponent } from './public/schedule-cargo/pallets-info/pallets-info.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { JobApplicationComponent } from './public/job-application/job-application.component';
import { AddProfessionalExperienceComponent } from './public/job-application/add-professional-experience/add-professional-experience.component';
import { TermsComponent } from './public/job-application/terms/terms.component';
import { AddEducationComponent } from './public/job-application/add-education/add-education.component';
import { AddQualificationComponent } from './public/job-application/add-qualification/add-qualification.component';
import { AddLanguageComponent } from './public/job-application/add-language/add-language.component';
import { AddInterestComponent } from './public/job-application/add-interest/add-interest.component';
import { VerifyExistingJobApplicationComponent } from './public/job-application/verify-existing-job-application/verify-existing-job-application.component';
import { JobApplicationFinishedComponent } from './public/job-application/job-application-finished/job-application-finished.component';
import { DashboardHRComponent } from './home/dashboard-hr/dashboard-hr.component';
registerLocaleData(ptBr);

const VT_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    HeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ScheduleCargoComponent,
    EditScheduleCargoItemComponent,
    DashboardLogisticsComponent,
    DashboardITComponent,
    DashboardFinanceComponent,
    DashboardSalesComponent,
    DashboardSalesmenComponent,
    DashboardHRComponent,
    PalletsInfoComponent,
    JobApplicationComponent,
    AddProfessionalExperienceComponent,
    AddEducationComponent,
    AddQualificationComponent,
    AddLanguageComponent,
    AddInterestComponent,
    VerifyExistingJobApplicationComponent,
    JobApplicationFinishedComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    NgxEchartsModule.forRoot({echarts: () => import('echarts')}),
    PowerBIEmbedModule,
    ImageCropperModule,
    HelpersModule,    
    NgxCaptchaModule
  ],
  providers: [
    refreshTokenProvider,
    settingsProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_FORMATS, useValue: VT_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: VT_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
