<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
  <mat-form-field class="full-container">
    <input matInput [type]="show ? 'text' : 'password'" [(ngModel)]="password">
      <button mat-icon-button matSuffix (click)="show = !show;">
        <mat-icon>{{ show ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">OK</button>
</div>